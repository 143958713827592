import {
  instrument,
  quantity,
  limitPrice,
  tradeTags,
  distributionType,
  orders,
  tradeDateTime,
  hiddenFormInfo,
  regNmsApplicability,
  tradeThrough
} from './cross-principal-fill.form-fields';
import type { CrossPrincipalFillFieldContract } from './cross-principal-fill.form-common';
import { createMarketDataField } from '@app/forms/form-builder/fields/market-data-field/market-data-field.common';
import {
  FieldDefinition,
  FormContract,
  type InferFormValuesFromFormContract
} from '@oms/frontend-foundation';
import {
  createAdditionalAllFields,
  createEnrichedField
} from '@app/forms/form-builder/fields/additional-fields/additional-fields.common';

export const crossPrincipalFillContract = FormContract.create<CrossPrincipalFillFieldContract>()
  .fields({
    regNmsApplicability,
    hiddenFormInfo,
    instrument,
    quantity,
    limitPrice,
    tradeTags,
    distributionType,
    orders,
    tradeDateTime,
    tradeThrough
  })
  .schema((f) => ({
    fields: [
      createMarketDataField('market-data', {
        instrumentFieldName: 'instrument',
        trackableFieldMap: {
          bid: 'limitPrice',
          ask: 'limitPrice'
        }
      }),
      FieldDefinition.runway('runway', [f.instrument, f.quantity, f.limitPrice]),
      {
        name: 'investor-orders-section',
        component: 'section',
        fields: [[f.orders]],
        style: { minHeight: '200px' },
        sx: {
          backgroundColor: 'layout.level1',
          marginTop: 1,
          padding: 0
        }
      },
      {
        name: 'trade-through-section',
        component: 'section',
        fields: [[f.tradeThrough]],
        sx: {
          backgroundColor: 'layout.level1',
          marginTop: 1
        }
      },
      createAdditionalAllFields<CrossPrincipalFillFieldContract>({
        fixedFields: ['tradeTags', 'tradeDateTime'],
        fields: [
          [createEnrichedField(f.tradeTags, { colSpan: 3 })],
          [createEnrichedField(f.tradeDateTime, { colSpan: 1 })]
        ]
      })
    ]
  }))
  .template('simple', { showSubmitButton: false });

export type CrossPrincipalFillContractType = typeof crossPrincipalFillContract;

export type CrossPrincipalFormValues = InferFormValuesFromFormContract<CrossPrincipalFillContractType>;
