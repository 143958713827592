import type { ICellRendererParams } from '@ag-grid-community/core';
import { chargeGroupTotal } from '@app/common/types/charges/charges.utils';
import { ChargeGroup, type VisibleInvestorOrderInfoWithAllocationsFragment } from '@oms/generated/frontend';
import { AppDisplayGrid, type AppDisplayGridProps } from '@oms/frontend-foundation';
import { GridColValues, ResponsiveValue } from '@oms/shared-frontend/ui-design-system';

const gridProps: AppDisplayGridProps['gridProps'] = {
  rows: 1,
  columns: 1 as ResponsiveValue<GridColValues>,
  columnGap: 0,
  rowGap: 0
};

export const ChargeFeeTotalCellRenderer: React.FC<
  ICellRendererParams<VisibleInvestorOrderInfoWithAllocationsFragment, any>
> = (params) => {
  if (!params?.data?.compositeCharges?.length) return null;

  const items = chargeGroupTotal({ chargeGroup: ChargeGroup.Fee, order: params?.data });

  return <AppDisplayGrid items={items} gridProps={gridProps} labelSize="small" />;
};

export const ChargeMarketTotalCellRenderer: React.FC<
  ICellRendererParams<VisibleInvestorOrderInfoWithAllocationsFragment, any>
> = (params) => {
  if (!params?.data?.compositeCharges?.length) return null;

  const items = chargeGroupTotal({ chargeGroup: ChargeGroup.Market, order: params?.data });

  return <AppDisplayGrid items={items} gridProps={gridProps} labelSize="small" />;
};
