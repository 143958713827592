import type { ICellRendererParams } from '@ag-grid-community/core';
import { Box, Flex, Heading, Text } from '@oms/shared-frontend/ui-design-system';
import { useState } from 'react';
import { type OmsNotification, getDisplayTemplateName, getElapsedTime } from './notifications.utils';
import useInterval from 'beautiful-react-hooks/useInterval';

export const NotificationCellRenderer = (props: ICellRendererParams<OmsNotification, any>) => {
  const { content, name: templateName, isRead, createdAt } = props.data || {};
  const [elapsedTime, setElapsedTime] = useState(getElapsedTime(createdAt || ''));
  const displayName = getDisplayTemplateName(templateName || '');

  useInterval(() => {
    setElapsedTime(getElapsedTime(createdAt || ''));
  }, 60000);

  return (
    <Flex sx={{ position: 'absolute', inset: 0 }} align="center">
      <Box sx={{ width: 'full', px: 3 }}>
        <Heading type="baseB" sx={{ color: isRead ? 'text.tertiary' : 'text.primary', lineHeight: 'smallR' }}>
          {!isRead && (
            <Box
              style={{ width: '0.5em', height: '0.5em', display: 'inline-block', marginRight: '0.5em' }}
              sx={{ borderRadius: 'half', backgroundColor: 'button.isPrimary.default' }}
            />
          )}
          {content}
        </Heading>
        <Text type="baseR" sx={{ color: 'text.semiMinor' }}>
          <span>{elapsedTime}</span> &middot; <span>{displayName}</span>
        </Text>
      </Box>
    </Flex>
  );
};
