import type { Trade, TradePatch, InstrumentDetailsSimpleFragment, TradeRepairRequestFragment, TradeInfoFragment, RepairCategory, IntegrationEventDetails } from '@oms/generated/frontend';
import type { AccountComboBoxValue } from '@app/forms/common/fields/account-field/account.field.queries';
import type { TradeModifySchemaInput } from './trade-modify.form-builder.config';

export enum TradeModifyEntryType {
  TradeModify = 'trade-modify',
  TradeRepair = 'trade-repair'
}

export type TradeModifyInput = TradeModifySchemaInput;

export type TradeModifyFormInfo =
  | {
    type: TradeModifyEntryType.TradeModify;
    trade: TradeInfoFragment;
    }
  | {
    type: TradeModifyEntryType.TradeRepair;
    tradeRepair: TradeRepairRequestFragment;
  };

export type TradeModifyFieldContract = {
  id: string;
  hiddenFormInfo: TradeModifyFormInfo;
  side: TradePatch['side'];
  instrument: InstrumentDetailsSimpleFragment;
  tradeQuantity: TradePatch['tradeQuantity'];
  tradePrice: TradePatch['tradePrice'];
  tradeDateTime: TradePatch['tradeDateTime'];
  tradeCounterpartyId: TradePatch['tradeCounterpartyId'];
  tradeContraAccountId: AccountComboBoxValue;
  settleType: TradePatch['settleType'];
  settleDate: TradePatch['settleDate'];
  tradeTags?: string[];
};

type TradePatchType = TradePatch & {
  id: string;
  tradeTagsId?: string[];
};

// TODO: check with BE when implemented
export type TradeModifyOutput =
  | {
    type: TradeModifyEntryType.TradeModify;
    output: TradePatchType;
    }
  | {
    type: TradeModifyEntryType.TradeRepair;
    output: TradePatchType
    repairTradeId: string;
    repairCategory: RepairCategory;
  };

export type RepairTradeEventPayload = {
  errors: {
    errorCode: string;
    origin: string;
    payload: string;
  }[];
  integrationEventDetails: IntegrationEventDetails;
  originalCommand: {
    trade: Trade;
  }
};
