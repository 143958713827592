import { FORM_EVENT_TYPE, FormBuilder } from '@oms/frontend-foundation';
import type {
  CrossPrincipalFillInput,
  CrossPrincipalFillOutput,
  CrossPrincipalFillRow
} from './cross-principal-fill.form-common';
import {
  crossPrincipalFillContract,
  type CrossPrincipalFillContractType,
  type CrossPrincipalFormValues
} from './cross-principal-fill.form-contract';
import {
  TradeDateTimeType,
  type InvestorOrder,
  OrderSide,
  type VisibleInvestorOrder,
  type CrossOrderInfoInput
} from '@oms/generated/frontend';
import { convertToNumber } from '@oms/shared/util';
import { CrossPrincipalFillService } from '@app/data-access/services/trading/cross-principal-fill/cross-principal-fill.service';
import { MarketDataService } from '@app/data-access/services/marketdata/marketdata.service';
import { first, type Subscription, withLatestFrom } from 'rxjs';
import { handleSanitizedValuesChange, handleSubmit } from './cross-principal-fill.event-handler';

const getInvestorOrder = async (service: CrossPrincipalFillService, orderId: string) => {
  const response = await service.getById(orderId);

  if (response.isFailure()) {
    console.error(response.errors);
    return;
  }

  if (response.isSuccess()) {
    return response.value.data.visibleInvestorOrder as VisibleInvestorOrder;
  }
};

const getBuyAndSellOrders = async (service: CrossPrincipalFillService, order: VisibleInvestorOrder) => {
  const buyOrdersPromise = service.getOrders(order, OrderSide.Buy);
  const sellOrdersPromise = service.getOrders(order, OrderSide.Sell);

  const [buyOrdersSettledRes, sellOrdersSettledRes] = await Promise.allSettled([
    buyOrdersPromise,
    sellOrdersPromise
  ]);

  const buyOrdersRes = buyOrdersSettledRes.status === 'fulfilled' ? buyOrdersSettledRes.value : null;
  const sellOrdersRes = sellOrdersSettledRes.status === 'fulfilled' ? sellOrdersSettledRes.value : null;

  if (buyOrdersRes?.isFailure()) {
    console.error(buyOrdersRes.errors);
    return;
  }

  if (sellOrdersRes?.isFailure()) {
    console.error(sellOrdersRes.errors);
    return;
  }

  if (buyOrdersRes?.isSuccess() && sellOrdersRes?.isSuccess()) {
    return {
      buyOrders: buyOrdersRes.value.data.visibleInvestorOrders?.nodes as InvestorOrder[],
      sellOrders: sellOrdersRes.value.data.visibleInvestorOrders?.nodes as InvestorOrder[]
    };
  }
};

export const crossPrincipalFillBuilder = FormBuilder.create<
  CrossPrincipalFillInput,
  CrossPrincipalFillOutput
>('cross-principal-fill-form')
  .contract<CrossPrincipalFillContractType>(crossPrincipalFillContract)
  .type('cross-principal-fill')
  .sanitizer((s) =>
    s
      .input(async function sanitize(input, ctx) {
        if (!input.id) return;

        const service = ctx.container.resolve(CrossPrincipalFillService);
        const order = await getInvestorOrder(service, input.id);

        if (!order) return;
        if (!order.instrument) return;

        const orders = await getBuyAndSellOrders(service, order);

        if (orders) {
          const initialFormValues: CrossPrincipalFormValues = {
            instrument: order.instrument
              ? {
                  id: order.instrument.id
                }
              : undefined,
            hiddenFormInfo: {
              order,
              buyOrders: orders.buyOrders,
              sellOrders: orders.sellOrders
            }
          };

          return initialFormValues;
        }
      })
      .output(function sanitize(formValues) {
        if (!formValues.instrument?.id) return;

        const output: CrossPrincipalFillOutput = {
          crossTrade: {
            instrumentId: formValues.instrument.id,
            limitPrice: convertToNumber(formValues.limitPrice),
            quantity: convertToNumber(formValues.quantity),
            tradeTagsId: formValues.tradeTags?.map((tag) => tag.id),
            tradeDateTimeType: formValues.tradeDateTime ? TradeDateTimeType.Past : TradeDateTimeType.Now,
            tradeDateTime: formValues?.tradeDateTime ?? undefined,
            buyOrdersInfo: getCrossOrderInfoInput(formValues.orders?.buyOrders),
            sellOrdersInfo: getCrossOrderInfoInput(formValues.orders?.sellOrders),
            allowSweep: formValues.tradeThrough?.allowSweep
          }
        };

        return output;
      })
  )
  .change(async (event, ctx) => {
    switch (event.type) {
      case FORM_EVENT_TYPE.SANITIZED_VALUES_CHANGED: {
        return handleSanitizedValuesChange(event, ctx).catch(console.error);
        break;
      }
      case FORM_EVENT_TYPE.SUBMIT: {
        return await handleSubmit(event, ctx);
      }
    }
  })
  .effect(({ formApi, container }) => {
    let subscription: Subscription | undefined = undefined;
    const state = formApi.getState();

    if (!state.values.instrument) return () => {};

    const marketDataService = container.resolve(MarketDataService);

    marketDataService
      .getInstrumentById(state.values.instrument.id)
      .then((instrument) => {
        const displayCode = instrument.mappings.displayCode;
        if (!displayCode) {
          console.error('Instrument display code not found');
          return;
        }

        const marketData$ = marketDataService.observe(displayCode).pipe(first());

        // on initial load we need to populate the limitPrice field with the midPrice
        subscription = formApi
          .get$({ values: true })
          .pipe(withLatestFrom(marketData$))
          .subscribe(([{ values }, { data }]) => {
            const { orders } = values;
            const state = formApi.getState();

            if (orders?.buyOrders && orders?.sellOrders && !state.visited?.quantity) {
              if (!state.modified?.limitPrice) {
                formApi.change('limitPrice', data?.level1.midPrice);
              }
            }
          });
      })
      .catch(console.error);

    return () => {
      subscription?.unsubscribe();
    };
  });

export type CrossPrincipalFillBuilderType = typeof crossPrincipalFillBuilder;

export default crossPrincipalFillBuilder;

function getCrossOrderInfoInput(rows: CrossPrincipalFillRow[] = []): CrossOrderInfoInput[] {
  return (
    rows.map((row) => ({
      investorOrderId: row.order.id,
      exclude: !!row.state.isExcluded,
      ...(row.state.isManualQuantity && { allocatedQuantity: convertToNumber(row.state.fillQuantity) })
    })) || []
  );
}
