import type { CrossPrincipalFillFieldContract } from './cross-principal-fill.form-common';
import { DistributionType, TagTypeEnum } from '@oms/generated/frontend';
import { createDistributionTypeField } from '@app/forms/common/fields/distribution-field/distribution-field.common';
import { createQuantityField } from '@app/forms/common/fields/quantity-field/quantity-field.common';
import { createPriceField } from '@app/forms/common/fields/price-field/price-field.common';
import { createTagsField } from '@app/forms/common/fields/tags-field/tags.common';
import { makeCommonRequiredProps } from '@app/forms/common/fields/shared-field-props';
import { FieldContract } from '@oms/frontend-foundation';
import { type AdditionalFormFieldUnion } from '@app/forms/form-builder/mappers/form.mappers';
import { createSymbolField } from '@app/forms/common/fields/symbol-field/symbol.field.common';

const fc = FieldContract.create<CrossPrincipalFillFieldContract, AdditionalFormFieldUnion>();

export const hiddenFormInfo = fc.field('hiddenFormInfo', 'hidden-field');
export const regNmsApplicability = fc.field('regNmsApplicability', 'hidden-field');

export const instrument = createSymbolField(fc, 'instrument', {
  isRequired: true,
  isPrimaryField: true,
  isDisabled: true,
  wrapperSx: { flex: 1 }
});

export const quantity = createQuantityField(fc, 'quantity', {
  ...makeCommonRequiredProps({
    indicatorStyle: 'none'
  }),
  label: 'Fill Quantity',
  isPrimaryField: true,
  wrapperSx: { flex: 1 }
});

export const limitPrice = createPriceField(fc, 'limitPrice', {
  label: 'Price',
  isPrimaryField: true,
  ...makeCommonRequiredProps({
    indicatorStyle: 'none'
  }),
  wrapperSx: { flex: 1 },
  format: 'price-trade'
});

export const tradeTags = createTagsField(fc, 'tradeTags', {
  label: 'Trade Tags',
  queryProps: {
    filters: [TagTypeEnum.Trade]
  }
});

export const distributionType = createDistributionTypeField(fc, 'distributionType', {
  ...makeCommonRequiredProps({
    indicatorStyle: 'none'
  }),
  initialValue: DistributionType.EvenDistribution
});

export const tradeDateTime = fc.field('tradeDateTime', 'native-date-picker').options({
  label: 'Trade date time',
  timePicker: true
});

export const orders = fc.field('orders', 'cross-principal-fill').options({});

export const tradeThrough = fc.field('tradeThrough', 'trade-through-obligation').options({
  initialValue: { allowSweep: true }
});
