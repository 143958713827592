import {
  type SnapshotDocActors,
  type SnapshotComputedDocType,
  type SnapshotType,
  TEMPORARY_SNAPSHOT_ID
} from '@app/data-access/offline/collections/snapshots/snapshots.collection';
import { OfflineDb } from '@app/data-access/offline/offline-database';
import { inject, singleton, delay } from 'tsyringe';
import { UUID } from '@oms/shared/util';
import { Actor, type ActorSnapshotDefinition, PROCESS_ID } from '@valstro/workspace';
import { type Observable, map } from 'rxjs';
import omit from 'lodash/omit';
import { getCompositeId } from '@app/data-access/offline/collections/grids.collection';
import { OfflineActionService } from '@app/actions/services/offline.action.service';
import type { AppSnapshotDefinition } from '@app/app-config/workspace.config';

@singleton()
export class SnapshotsService {
  private _cachedLeaderActor: Actor | null = null;
  constructor(@inject(delay(() => OfflineDb)) private db: OfflineDb) {}

  public getAll() {
    return this.db.db.collections.snapshots.find().exec();
  }

  public all$(type: SnapshotType): Observable<SnapshotComputedDocType[]> {
    return this.db.db.collections.snapshots
      .find({
        selector: { type },
        sort: [{ createdAt: 'desc' }]
      })
      .$.pipe(
        map((docs) => {
          const docsSortedByLastLoadedAtDesc = docs.sort((a, b) => {
            return new Date(b.lastLoadedAt).getTime() - new Date(a.lastLoadedAt).getTime();
          });
          const isActiveId = docsSortedByLastLoadedAtDesc[0]?.id;
          return docsSortedByLastLoadedAtDesc.map((doc) => {
            const snapshot: SnapshotComputedDocType = {
              ...doc.toMutableJSON(),
              isActive: doc.id === isActiveId
            };
            return snapshot;
          });
        })
      );
  }

  public async save(type: SnapshotType, name: string) {
    const createdAt = new Date().toISOString();
    const { snapshot, actors } = await this.takeSnapshot();
    const id = UUID();
    await this.db.db.collections.snapshots.insert({
      id,
      type,
      name,
      createdAt,
      snapshot,
      actors,
      lastLoadedAt: createdAt
    });
    await this.postSave(id);
  }

  public async saveOver(id: string) {
    const doc = await this.db.db.collections.snapshots.findOne(id).exec();
    if (!doc) {
      throw new Error(`Snapshot with id ${id} not found`);
    }
    const lastLoadedAt = new Date().toISOString();
    const { snapshot, actors } = await this.takeSnapshot();
    await doc.incrementalUpdate({
      $set: {
        snapshot,
        actors,
        lastLoadedAt
      }
    });
    await this.postSave(id);
  }

  public async load(snapshot: SnapshotComputedDocType) {
    const leaderActor = await this.getLeaderActor();
    const nextLastLoadedAt = new Date().toISOString();
    await this.prepareLoad(snapshot.id);
    await this.db.db.collections.snapshots.upsert({
      ...omit(snapshot, 'isActive'),
      lastLoadedAt: nextLastLoadedAt
    });
    await leaderActor.applySnapshot(snapshot.snapshot);
  }

  public async getCurrent(): Promise<SnapshotComputedDocType | null> {
    const snapshot = await this.db.db.collections.snapshots
      .findOne({
        sort: [{ lastLoadedAt: 'desc' }]
      })
      .exec();
    if (!snapshot) return null;
    return {
      ...snapshot.toMutableJSON(),
      isActive: true
    };
  }

  public async delete(snapshotIds: string[]) {
    await this.db.db.collections.snapshots.bulkRemove(snapshotIds);
  }

  public findByMatchingActors(id: string, type?: string) {
    return this.db.db.collections.snapshots
      .find({
        selector: {
          $and: [
            {
              actors: {
                $elemMatch: type ? { id, type } : { id }
              }
            }
          ]
        }
      })
      .exec();
  }

  private async takeSnapshot() {
    const leaderActor = await this.getLeaderActor();
    const snapshot = (await leaderActor.takeSnapshot()) as AppSnapshotDefinition;
    const actors = this.extractFlatActorList(snapshot);
    return {
      snapshot,
      actors
    };
  }

  private extractFlatActorList(def: ActorSnapshotDefinition, parentId?: string): Array<SnapshotDocActors> {
    const actors: SnapshotDocActors[] = [];
    actors.push({ id: def.id, type: def.type, name: def.name, parentId });
    for (const child of def.children) {
      actors.push(...this.extractFlatActorList(child, def.id));
    }
    return actors;
  }

  private async getLeaderActor() {
    if (!this._cachedLeaderActor) {
      this._cachedLeaderActor = await Actor.get(PROCESS_ID.LEADER);
    }
    return this._cachedLeaderActor;
  }

  private async prepareLoad(snapshotId: string) {
    await this.prepareGrids(snapshotId);
    await this.prepareActions(snapshotId);
  }

  private async postSave(snapshotId: string) {
    await this.pruneActions(snapshotId);
    await this.pruneGrids(snapshotId);
  }

  private async pruneActions(snapshotId: string) {
    // delete all grids with the matching snapshotId just taken
    const actionsWithMatchingSnapshotId = await this.db.collections.actions
      .find({
        selector: {
          gridStateId: {
            $regex: snapshotId
          }
        }
      })
      .exec();
    const actionIds = actionsWithMatchingSnapshotId.map((action) => action.id);
    await this.db.collections.actions.bulkRemove(actionIds);

    const allTemporaryActions = await this.db.collections.actions
      .find({ selector: { gridStateId: { $regex: TEMPORARY_SNAPSHOT_ID } } })
      .exec();

    // and duplicate them with the new snapshotId
    const newSnapshotActions = allTemporaryActions.map((action) => ({
      ...action.toMutableJSON(),
      gridStateId: getCompositeId(action.gridStateId.split('_')[1], snapshotId)
    }));

    await this.db.collections.actions.bulkUpsert(
      newSnapshotActions.map((a) => ({ ...a, id: OfflineActionService.idOf(a) }))
    );
  }

  private async pruneGrids(snapshotId: string) {
    // delete all grids with the matching snapshotId just taken
    const gridsWithMatchingSnapshotId = await this.db.collections.grids
      .find({ selector: { snapshotId } })
      .exec();
    const gridIds = gridsWithMatchingSnapshotId.map((grid) => grid.id);
    await this.db.collections.grids.bulkRemove(gridIds);

    // get all temporary grids
    const allTemoraryGrids = await this.db.collections.grids
      .find({ selector: { snapshotId: TEMPORARY_SNAPSHOT_ID } })
      .exec();

    // and duplicate them with the new snapshotId
    const newSnapshotGrids = allTemoraryGrids.map((grid) => ({
      ...grid.toMutableJSON(),
      id: getCompositeId(grid.gridId, snapshotId),
      snapshotId
    }));

    await this.db.collections.grids.bulkUpsert(newSnapshotGrids);
  }

  private async prepareGrids(snapshotId: string) {
    // get & remove temporary grids
    const allTemoraryGrids = await this.db.collections.grids
      .find({ selector: { snapshotId: TEMPORARY_SNAPSHOT_ID } })
      .exec();
    const allTemoraryGridIds = allTemoraryGrids.map((grid) => grid.id);
    await this.db.collections.grids.bulkRemove(allTemoraryGridIds);
    await this.db.collections.grids.cleanup();

    // get all snapshot grids
    const gridsWithMatchingSnapshotId = await this.db.collections.grids
      .find({ selector: { snapshotId } })
      .exec();

    // and duplicate them to temporary grids
    const newTempGrids = gridsWithMatchingSnapshotId.map((grid) => ({
      ...grid.toMutableJSON(),
      id: getCompositeId(grid.gridId, TEMPORARY_SNAPSHOT_ID),
      snapshotId: TEMPORARY_SNAPSHOT_ID
    }));

    await this.db.collections.grids.bulkUpsert(newTempGrids);
  }

  private async prepareActions(snapshotId: string) {
    const allTemporaryActions = await this.db.collections.actions
      .find({ selector: { gridStateId: { $regex: TEMPORARY_SNAPSHOT_ID } } })
      .exec();
    const allTemporaryActionIds = allTemporaryActions.map((action) => action.id);
    await this.db.collections.actions.bulkRemove(allTemporaryActionIds);
    await this.db.collections.actions.cleanup();

    const actionsWithMatchingSnapshotIds = await this.db.collections.actions
      .find({ selector: { gridStateId: { $regex: snapshotId } } })
      .exec();

    const newTempActions = actionsWithMatchingSnapshotIds
      .map((action) => ({
        ...action.toMutableJSON(),
        gridStateId: getCompositeId(action.gridStateId.split('_')[1], TEMPORARY_SNAPSHOT_ID)
      }))
      .map((a) => ({ ...a, id: OfflineActionService.idOf(a) }));

    await this.db.collections.actions.bulkUpsert(newTempActions);
  }
}
