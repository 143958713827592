import {
  type ColumnBuilderCallback,
  type ColumnLibrary,
  PROGRESS_RENDERER,
  CellBadgeClassEnum
} from '@oms/frontend-vgrid';
import {
  sharedLimitPriceCol,
  sharedSideCol,
  sharedOrderTypeCol,
  sharedAveragePriceCol,
  sharedSettleCurrencyCol,
  sharedInvestorAccountCol,
  sharedSettleTypeCol,
  sharedSettleDateCol,
  sharedExpiryDateTimeCol,
  sharedOrderTifCol,
  sharedOrderTifDurationCol,
  sharedUpdatedTimestampCol,
  sharedCreatedTimestampCol,
  sharedOrderVisibilityReasonCol,
  sharedOrderTagsCol,
  sharedOwnerCol,
  sharedOwnerIdCol,
  sharedRepresentativeCodeCol,
  sharedCustomerNotesCol,
  sharedTradeCurrencyCol,
  sharedOrderStatusWithoutMapperCol,
  sharedTradingOrderCapacityCol
} from '@app/common/grids/columns/order-cols';
import { instrumentCols } from '@app/common/grids/columns/instrument-cols';
import {
  sharedDefaultCol,
  sharedIdCol,
  sharedQuantityCol,
  sharedTextCol,
  sharedUserCol
} from '@app/common/grids/columns/generic-cols';
import {
  TradingOrderStatus,
  type VisibleTradingOrderInfoFragment as TOFragment,
  TradingOrderCategory
} from '@oms/generated/frontend';
import { t } from '@oms/codegen/translations';
import { mapTradingOrderStatus } from '@app/common/mappers/map-trading-order-status';
import { mapTradingOrderCategory } from '@app/common/mappers/map-trading-order-category';

export type TOFragmentWithAllocationDetails = TOFragment & {
  mappedQuantity?: number;
};

const defaultCol: ColumnBuilderCallback<TOFragment> = (c) =>
  sharedDefaultCol<TOFragment>(c).enablePivot().enableRowGroup();

const defaultColWithFloatingFilter: ColumnBuilderCallback<TOFragment> = (c) => defaultCol(c).floatingFilter();

const idCol: ColumnBuilderCallback<TOFragment> = (c) => sharedIdCol<TOFragment>(c, 'id').hide();

const sideCol: ColumnBuilderCallback<TOFragment> = (c) => sharedSideCol<TOFragment>(c);

const locateCol: ColumnBuilderCallback<TOFragment> = (c) => sharedTextCol(c, 'locate').header('Locate');

const limitPriceCol: ColumnBuilderCallback<TOFragment> = (c) => sharedLimitPriceCol(c);

const orderTypeCol: ColumnBuilderCallback<TOFragment> = (c) => sharedOrderTypeCol(c);

const orderCapacityCol: ColumnBuilderCallback<TOFragment> = (c) => sharedTradingOrderCapacityCol(c);

const totalQuantityCol: ColumnBuilderCallback<TOFragment> = (c) =>
  sharedQuantityCol(c, 'quantity').colId('quantity').header('Total Quantity').shortHeader('Total Qty');

const mappedQuantityCol: ColumnBuilderCallback<TOFragmentWithAllocationDetails> = (c) =>
  sharedQuantityCol(c, 'mappedQuantity')
.colId('mappedQuantity')
.header('Mapped Quantity')
.shortHeader('Mapped Qty');

const leavesQuantityCol: ColumnBuilderCallback<TOFragment> = (c) =>
  sharedQuantityCol(c, 'leavesQuantity')
    .colId('leavesQuantity')
    .header('Leaves Quantity')
    .shortHeader('Leaves Qty');

const executedQuantityCol: ColumnBuilderCallback<TOFragment> = (c) =>
  sharedQuantityCol(c, 'executedQuantity')
    .colId('executedQuantity')
    .header('Executed Quantity')
    .shortHeader('Executed Qty')
    .cell((c) => c.renderer(PROGRESS_RENDERER));

const averagePriceCol: ColumnBuilderCallback<TOFragment> = (c) =>
  sharedAveragePriceCol<TOFragment>(c, 'averagePrice');

const settleCurrencyCol: ColumnBuilderCallback<TOFragment> = (c) => sharedSettleCurrencyCol(c);

const venueCol: ColumnBuilderCallback<TOFragment> = (c) =>
  sharedTextCol(c, 'destinationName').header(t('app.orders.orderMonitor.routeTo')).minWidth(90);

const targetExchangeCol: ColumnBuilderCallback<TOFragment> = (c) =>
  sharedTextCol(c, 'targetExchange.id')
    .header(t('app.orders.orderMonitor.targetExchange'))
    .shortHeader(t('app.orders.orderMonitor.targetExchange', { ns: 'short' }))

const investorAccountCol: ColumnBuilderCallback<TOFragment> = (c) =>
  sharedInvestorAccountCol<TOFragment>(c, 'tradingAccount.name');

const settleTypeCol: ColumnBuilderCallback<TOFragment> = (c) => sharedSettleTypeCol(c);

const settleDateCol: ColumnBuilderCallback<TOFragment> = (c) => sharedSettleDateCol(c);

const expiryDateTimeCol: ColumnBuilderCallback<TOFragment> = (c) => sharedExpiryDateTimeCol(c);

const timeInForceCol: ColumnBuilderCallback<TOFragment> = (c) => sharedOrderTifCol(c);
const tifDurationCol: ColumnBuilderCallback<TOFragment> = (c) => sharedOrderTifDurationCol(c)

const enteredByCol: ColumnBuilderCallback<TOFragment> = (c) =>
  sharedUserCol(c, 'enteredBy').header('Entered By').hide();

const statusCol: ColumnBuilderCallback<TOFragment> = (c) =>
  sharedOrderStatusWithoutMapperCol(c)
    .filter('agSetColumnFilter')
    .filterParams<TradingOrderStatus>({
      values: Object.values(TradingOrderStatus),
      valueFormatter: ({ value }) => mapTradingOrderStatus(value)
    })
    .cell((c) => c.badge(CellBadgeClassEnum.Capital, (data) => mapTradingOrderStatus(data?.status)));

const categoryCol: ColumnBuilderCallback<TOFragment> = (c) =>
  sharedOrderStatusWithoutMapperCol(c, 'category')
    .header(t('app.orders.orderMonitor.category'))
    .shortHeader(t('app.orders.orderMonitor.category', { ns: 'short' }))
    .filter('agSetColumnFilter')
    .filterParams<TradingOrderCategory>({
      values: Object.values(TradingOrderCategory),
      valueFormatter: ({ value }) => mapTradingOrderCategory(value)
    })
    .cell((c) => c.badge(CellBadgeClassEnum.Capital, (data) => mapTradingOrderCategory(data?.category)));

const updatedTimestampCol: ColumnBuilderCallback<TOFragment> = (c) =>
  sharedUpdatedTimestampCol(c).sort('desc');

const createdTimestampCol: ColumnBuilderCallback<TOFragment> = (c) =>
  sharedCreatedTimestampCol(c).sort('desc');

const orderVisibilityReasonCol: ColumnBuilderCallback<TOFragment> = (c) => sharedOrderVisibilityReasonCol(c);

const orderTagsCol: ColumnBuilderCallback<TOFragment> = (c) => sharedOrderTagsCol(c);

const ownerCol: ColumnBuilderCallback<TOFragment> = (c) => sharedOwnerCol<TOFragment>(c);

const ownerIdCol: ColumnBuilderCallback<TOFragment> = (c) => sharedOwnerIdCol<TOFragment>(c);

const representativeCodeCol: ColumnBuilderCallback<TOFragment> = (c) =>
  sharedRepresentativeCodeCol<TOFragment>(c).field('representativeCode.code');

const customerNotesCol: ColumnBuilderCallback<TOFragment> = (c) => sharedCustomerNotesCol(c);

const venueNotesCol: ColumnBuilderCallback<TOFragment> = (c) =>
  sharedTextCol(c, 'venueNotes')
    .header(t('app.orders.orderMonitor.venueNotes'))
    .shortHeader(t('app.orders.orderMonitor.venueNotes', { ns: 'short' }))
    .hide();

const tradeCurrencyCol: ColumnBuilderCallback<TOFragment> = (c) => sharedTradeCurrencyCol(c);

export const tradingOrderMonitorColumnLibrary: ColumnLibrary<TOFragment> = {
  defaultColumn: defaultColWithFloatingFilter,
  columns: [
    idCol,
    ...instrumentCols<TOFragment>({
      displayCode: (cb) =>
        cb
          .cell((c) => c.renderer('agGroupCellRenderer'))
          .filter(false)
          .sortable(false)
    }),
    sideCol,
    locateCol,
    limitPriceCol,
    orderTypeCol,
    orderCapacityCol,
    totalQuantityCol,
    leavesQuantityCol,
    executedQuantityCol,
    settleCurrencyCol,
    settleTypeCol,
    settleDateCol,
    investorAccountCol,
    venueCol,
    targetExchangeCol,
    categoryCol,
    timeInForceCol,
    tifDurationCol,
    expiryDateTimeCol,
    enteredByCol,
    statusCol,
    orderTagsCol,
    averagePriceCol,
    createdTimestampCol,
    updatedTimestampCol,
    orderVisibilityReasonCol,
    ownerCol,
    ownerIdCol,
    tradeCurrencyCol,
    customerNotesCol,
    venueNotesCol,
    representativeCodeCol
  ]
};

export const investorOrderTradingOrderMonitorColumnLibrary = {
  defaultColumn: defaultCol,
  columns: [
    idCol,
    ...instrumentCols<TOFragment>({
      displayCode: (cb) => cb.cell((c) => c.renderer('agGroupCellRenderer'))
    }).map((col) => col),
    sideCol,
    locateCol,
    limitPriceCol,
    orderTypeCol,
    mappedQuantityCol,
    totalQuantityCol,
    leavesQuantityCol,
    executedQuantityCol,
    settleCurrencyCol,
    settleTypeCol,
    settleDateCol,
    investorAccountCol,
    venueCol,
    categoryCol,
    timeInForceCol,
    enteredByCol,
    statusCol,
    averagePriceCol,
    createdTimestampCol,
    updatedTimestampCol,
    orderVisibilityReasonCol
  ]
};
