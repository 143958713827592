import { openRouteOrder } from '@app/generated/sdk';
import {
  InvestorOrderStatus,
  type VisibleInvestorOrderInfoWithAllocationsFragment as IOFragment
} from '@oms/generated/frontend';
import { type ActionDefFactory, type ActionComponentConfig, type ActionContext } from '@oms/frontend-vgrid';
import { PROCESS_ID } from '@valstro/workspace';

const isRouteInvestorOrderVisible = (rowData?: IOFragment) =>
  rowData !== undefined &&
  rowData?.status === InvestorOrderStatus.Active &&
  rowData?.openQuantity !== null &&
  rowData?.openQuantity !== undefined &&
  rowData?.openQuantity > 0 &&
  rowData?.__typename === 'VisibleInvestorOrder';

const isActionEnabled = (data: IOFragment[]) => {
  if (data.length !== 1) {
    return false;
  }

  const rowData = data[0];

  return isRouteInvestorOrderVisible(rowData);
};

export const openRouteInvestorOrderOnChange = (e: ActionContext<IOFragment>) => {
  const { lifecycle, data } = e;
  e.notify({ isDisabled: !isActionEnabled(data) });

  const selectedRow = data?.[0];
  const id = selectedRow?.id;
  const symbol = selectedRow?.instrument?.mappings?.displayCode;

  if (lifecycle === 'change' && id) {
    openRouteOrder(PROCESS_ID.LEADER, {
      title: `Route Order ${symbol}`,
      windowOptions: {
        isPinnable: false
      },
      form: {
        formId: `route-order-${id}`,
        input: {
          mode: {
            type: 'route',
            investorOrderId: id
          }
        }
      }
    }).catch((error) => {
      console.error('openRouteOrder error', error);
    });
  }
};

export const openRouteInvestorOrderAction: ActionDefFactory<IOFragment> = (builder) =>
  builder
    .name('open_route_investor_order')
    .toolbar((t) =>
      t
        .id('open_route_investor_order_button')
        .component('action-button')
        .location('HorizontalToolbarRight')
        .props({
          content: 'Route Order',
          isDisabled: true
        })
    )
    .menu((m) => m.name('Route Order').visible(({ rowData }) => isRouteInvestorOrderVisible(rowData)))
    .onChange<ActionComponentConfig<IOFragment>>(openRouteInvestorOrderOnChange);
