import { useEffect, useState } from 'react';
import { WebSocketSubject } from 'rxjs/webSocket';
import { retry, timer } from 'rxjs';
import { Logger } from '@oms/shared/util';
import type { Level2IntegrationEvent } from '@app/data-access/services/trading/montage/montage.types';
import type {
  Level2SipDataEvent
} from '@app/data-access/services/marketdata/level2-websocket.types';
import type { Optional } from '@oms/shared/util-types';
import type { SipData } from '@oms/shared/regnms';
import { RegNmsApplicability } from '@oms/generated/frontend';

const logger = Logger.labeled('useCommonL2Connection');

// ------- SIP Quotes Level2 Hook ------- //

export function useSipQuotesLevel2(instrumentId: string, regNmsApplicability: RegNmsApplicability) {
  const [level2Data, setLevel2Data] = useState<Optional<SipData>>(undefined);

  useEffect(() => {
    if (regNmsApplicability === RegNmsApplicability.Applicable) {
      const SIPQUOTES_URL = 'ws://localhost:8080/data/sipquotes';
      const socket$ = new WebSocketSubject<SipData | string>({
        url: SIPQUOTES_URL,
        openObserver: {
          next: () => {},
          complete: () => {},
          error: () => {}
        },
        closeObserver: {
          next: () => {},
          complete: () => {},
          error: () => {}
        }
      });

      const subscription = socket$
        .pipe(
          retry({
            delay: (_error, retryIndex) => timer(Math.pow(2, retryIndex) * 1000)
          })
        )
        .subscribe(
          (event) => {
            if (isLevel2IntegrationEvent(event)) {
              setLevel2Data(event);
            }
          },
          (error) => logger.error(`🔴 Error: ${error}`)
        );

      socket$.next(instrumentId);

      return () => {
        subscription.unsubscribe();
        socket$.complete();
      };
    }
  }, [instrumentId, regNmsApplicability]);

  return level2Data;
}

function isLevel2IntegrationEvent(input: unknown): input is Level2IntegrationEvent | Level2SipDataEvent {
  if (typeof input !== 'object' || input === null) return false;

  if ('bids' in input && 'asks' in input) {
    return Array.isArray(input.bids) && Array.isArray(input.asks);
  }

  return false;
}
