import { FieldContract } from '@oms/frontend-foundation';
import { TagTypeEnum } from '@oms/generated/frontend';
import type { AdditionalFormFieldUnion } from '@app/forms/form-builder/mappers/form.mappers';
import { createSymbolField } from '@app/forms/common/fields/symbol-field/symbol.field.common';
import { createPriceField } from '@app/forms/common/fields/price-field/price-field.common';
import { createQuantityField } from '@app/forms/common/fields/quantity-field/quantity-field.common';
import { createAccountField } from '@app/forms/common/fields/account-field/account.field.common';
import { createSettlementFields } from '@app/forms/common/fields/settlement-field/settlement.field';
import { makeCommonRequiredProps } from '@app/forms/common/fields/shared-field-props';
import { createSideField } from '@app/forms/common/fields/side-field/side-field.common';
import { createTagsField } from '@app/forms/common/fields/tags-field/tags.common';

import type { TradeModifyFieldContract } from './trade-modify.form-common';
import { createTradeCounterpartyField } from '@app/forms/common/fields/trade-counterparty-field/trade-counterparty-field.common';

const fc = FieldContract.create<TradeModifyFieldContract, AdditionalFormFieldUnion>();

export const id = fc.field('id', 'hidden-field');

export const hiddenFormInfo = fc.field('hiddenFormInfo', 'hidden-field');

// runway fields --------------------------------------

export const instrument = createSymbolField(fc, 'instrument', {
  ...makeCommonRequiredProps({
    indicatorStyle: 'none'
  }),
  actions: {
    resolveProps: ['tradeFieldsDisableAction']
  }
});

export const side = createSideField(fc, 'side', {
  ...makeCommonRequiredProps({
    indicatorStyle: 'none'
  }),
  actions: {
    resolveProps: ['tradeFieldsDisableAction']
  }
});

export const tradeQuantity = createQuantityField(fc, 'tradeQuantity', {
  ...makeCommonRequiredProps({
    indicatorStyle: 'none'
  })
});

export const tradePrice = createPriceField(fc, 'tradePrice', {
  ...makeCommonRequiredProps({
    indicatorStyle: 'none'
  }),
  placeholder: ''
});

export const tradeCounterpartyId = createTradeCounterpartyField(fc, 'tradeCounterpartyId', {
  label: 'Trade cpty'
});

// all fields --------------------------------------

export const tradeContraAccountId = createAccountField(fc, 'tradeContraAccountId', {
  isDisabled: true
});

export const settlement = createSettlementFields(fc, 'settleType', 'settleDate', {
  actions: {
    resolveProps: ['tradeFieldsDisableByRepairModeAction']
  }
}, {
    actions: {
      resolveProps: ['tradeFieldsDisableByRepairModeAction']
  }
});

export const tradeDateTime = fc.field('tradeDateTime', 'native-date-picker').options({
  ...makeCommonRequiredProps({
    indicatorStyle: 'none'
  }),
  label: 'Trade Date Time',
  timePicker: true
});

export const tradeTags = createTagsField(fc, 'tradeTags', {
  label: 'Trade Tags',
  queryProps: {
    filters: [TagTypeEnum.Trade]
  }
});
