//! IMPORTANT
// Only use this file for shared / global constants for fields
// Otherwise, co-locate them with the form that uses them

export const FORM_COMPONENT_TYPE = {
  EXAMPLE: 'example-field',
  PRINCIPAL_FILL: 'principal-fill',
  CROSS_PRINCIPAL_FILL: 'cross-principal-fill',
  ALL_FIELDS: 'all-fields',
  ADDITIONAL_FIELDS: 'additional-fields',
  ENRICHED_FIELD: 'enriched-field',
  ACTION_BUTTON_LAYOUT: 'action-button-layout',
  ACTION_COMMAND: 'action-command',
  MARKET_DATA_FIELD: 'market-data',
  FIXATDL_STRATEGY: 'fixatdl-strategy',
  MATCHED_INVESTOR_ORDERS_FIELD: 'matched-investor-orders-field',
  TRADE_THROUGH_OBLIGATION: 'trade-through-obligation',
  COVERAGE_USER_FIELD: 'coverage-user'
} as const;

export type AllOmsAppComponents = typeof FORM_COMPONENT_TYPE[keyof typeof FORM_COMPONENT_TYPE];

export const FORM_VALIDATOR_TYPE = {
  VALIDATE_INSTRUMENT_TYPEAHEAD: 'validateInstrumentTypeahead',
  VALIDATE_ACTION_BUTTON_LAYOUT: 'validateActionButtonLayout',
  AFTER_DATE: 'afterDate',
  FUTURE_GTD: 'futureGTD',
  ONLY_IF_REQUIRED: 'onlyIfRequired',
  OPTIONAL_REQUIRED: 'optionalRequired',
  VALIDATE_CROSS_PRINCIPAL_FILL_QUANTITY: 'validateCrossPrincipalFillQuantity',
  VALIDATE_DURATION_INPUT: 'validateDurationInput',
  VALIDATE_TIF_INPUT: 'validateTifInput'
} as const;

export const FORM_ACTION_TYPE = {
  DISABLE_WHEN: 'disableWhen'
} as const;

export const FORM_CONDITION_TYPE = {} as const;
