import type { OmsNotification } from './notifications.utils';
import { useVGrid, VGrid } from '@oms/frontend-vgrid';
import { notificationsColumnLibrary } from './notifications.all-alerts.columns';
import { allAlertsMockData } from './notifications.all-alerts.mock-data';

export const NOTIFICATIONS_ALL_ALERTS = 'notifications-all-alerts';

export const NotificationsAllAlertsGridWidget = () => {
  // TODO: Use a real datasource service

  const gridProps = useVGrid<OmsNotification>(
    NOTIFICATIONS_ALL_ALERTS,
    (b) =>
      b
        .columnLibrary(notificationsColumnLibrary)
        .datasource((d) => d.source(allAlertsMockData).rowId((e) => e.data.id)) // Using mock data for now
        .headerHeight(0) // Make the header height 0 to hide it.
        .rowHeight(60)
        .rowStateRules({
          takeAction: (params) => params.data?.isRead === false
        }),
    // .sideBar(), // show/hide sidebar during Dev
    // .context, .rowSelection, and .actions are not yet implemented
    []
  );

  return <VGrid {...gridProps} />;
};

export default NotificationsAllAlertsGridWidget;
