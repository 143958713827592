import { FormBuilder } from '@oms/frontend-foundation';
import { type MontageBumpPriceFormInput, type MontageBumpPriceFormOutput } from './bump-price.form-common';
import { montageBumpPriceContract, type MontageBumpPriceContractType } from './bump-price.form-contract';

export const montageBumpPriceBuilder = FormBuilder.create<
  MontageBumpPriceFormInput,
  MontageBumpPriceFormOutput
>('montage-bump-price-form')
  .contract<MontageBumpPriceContractType>(montageBumpPriceContract)
  .type('montage-bump-price')
  .sanitizer((s) =>
    s
      .input(function initializeFormValues(input) {
        return input;
      })
      .output(function sanitizeFormValuesToOutput(formValues) {
        if (!formValues.bumpPrice) return;

        return {
          bumpPrice: Number(formValues.bumpPrice)
        };
      })
  )
  .change(async () => {});

export type MontageBumpPriceBuilderType = typeof montageBumpPriceBuilder;

export default montageBumpPriceBuilder;
