import { FormContract, type InferFormValuesFromFormContract } from '@oms/frontend-foundation';
import {
  createAdditionalAllFields,
  createEnrichedField
} from '@app/forms/form-builder/fields/additional-fields/additional-fields.common';
import type { BulkRepairTradeFieldContract } from './bulk-repair-trade.form-common';
import { hiddenFormInfo, tradeCounterparty, tradeTags } from './bulk-repair-trade.form-fields';

export const bulkRepairTradeContract = FormContract.create<BulkRepairTradeFieldContract>()
  .fields({
    hiddenFormInfo,
    tradeCounterparty,
    tradeTags
  })
  .schema((f) => {
    return {
      fields: [
        createAdditionalAllFields<BulkRepairTradeFieldContract>(
          {
            fields: [
              [createEnrichedField(f.tradeCounterparty, { groupName: '' })],
              [
                createEnrichedField(f.tradeTags, {
                  groupName: '',
                  colSpan: 2
                })
              ]
            ],
            sx: {
              height: 'full'
            }
          },
          'add' // supply renderbehaviour here.
        )
      ]
    };
  })
  .template('simple', { showSubmitButton: true });

export type BulkRepairTradeFormContractType = typeof bulkRepairTradeContract;

export type BulkRepairTradeFormValues = InferFormValuesFromFormContract<BulkRepairTradeFormContractType>;
