import { openTradingOrderView } from '@app/generated/sdk';
import type { ActionContext, ActionDefFactory, ActionComponentConfig } from '@oms/frontend-vgrid';
import { type VisibleTradingOrderInfoFragment as TOFragment } from '@oms/generated/frontend';
import { PROCESS_ID } from '@valstro/workspace';
import { getOrderDialogTitle } from '@app/common/types/orders/orders.utils';

export const viewTradingOrderOnChange = async (
  ctx: ActionContext<TOFragment, ActionComponentConfig<TOFragment>>
) => {
  const { lifecycle, data } = ctx;

  const selectedRow = data[0];
  const isOnlyOneRowSelected = data.length === 1;

  ctx.notify({ isDisabled: !isOnlyOneRowSelected });

  if (lifecycle === 'change') {
    if (!selectedRow) {
      return;
    }
    const id = selectedRow.id;
    if (!id) {
      return;
    }

    await openTradingOrderView(PROCESS_ID.LEADER, {
      componentProps: {
        id
      },
      title: getOrderDialogTitle(selectedRow)
    });
  }
};

export const viewTradingOrderAction: ActionDefFactory<TOFragment> = (builder) =>
  builder
    .name('view_trading_order')
    .toolbar((t) =>
      t.component('action-button').id('view_trading_order_button').location('HorizontalToolbarRight').props({
        isDisabled: true,
        content: 'View'
      })
    )
    .menu((m) => m.name('View Order').visible(({ rowData }) => rowData?.id !== undefined))
    .onChange<ActionComponentConfig<TOFragment>>(viewTradingOrderOnChange);
