import React, { useMemo } from 'react';
import { useCurrentWidgetActorProps } from '@valstro/workspace-react';
import { type Field, FormRenderer } from '@oms/frontend-foundation';
import { container } from 'tsyringe';
import type { AllFieldsDialogProps } from './all-fields-form.dialog.config';

const templateProps = { showSubmitButton: false, sx: { backgroundColor: 'layout.level1' } } as const;

export const AllFieldsFormDialogComponent: React.FC = () => {
  const [{ fields, formId, formType, initialValues }] = useCurrentWidgetActorProps<AllFieldsDialogProps>();

  const schema = useMemo(
    () => ({
      // Annoyingly, the app Field is sufficently different from the Field type in the foundation that we need to cast it
      // even though they are the same
      fields: (fields as unknown as Field[]) || []
    }),
    [fields]
  );

  if (initialValues === undefined) {
    return null;
  }

  return (
    <FormRenderer
      initialValues={initialValues}
      schema={schema}
      formId={formId + '_all-fields'}
      formType={formType}
      container={container}
      template="simple"
      templateProps={templateProps}
    />
  );
};

export default AllFieldsFormDialogComponent;
