import { type Subscription, filter } from 'rxjs';
import { defer, finalize, from, of, repeat, retry, timer } from 'rxjs';
import type { FrontgateConnectionOptions } from '@fds/wm-typescript-mdg2-client';
import {
  ConsoleLogger,
  LogLevel,
  Mdg2Client,
  TokenAuthentication,
  FrontgateWSConnection
} from '@fds/wm-typescript-mdg2-client';
import { createLogger } from '@oms/shared/util';
import type { Level1IntegrationEvent } from '@oms/generated/frontend';
import { level1 } from './factset/factset.observables';
import { getEnvVar } from '../../../common/env/env.util';
import { MarketdataSubject, marketdata$ } from '@app/data-access/memory/marketdata.subject';
import { COMMON_ACTOR_TYPE, isTauri } from '@valstro/workspace';
import { getWindowDestroyedEvent$ } from '../../../common/workspace/workspace.rxjs';
import { AppWorkspace } from '@app/app-config/workspace.config';
import { MARKETDATA_EVENT_TYPE } from '@app/common/marketdata/marketdata.events';

const l = createLogger({
  label: 'MDaaSProviderService'
});

const factsetTokenUrl =
  isTauri() && getEnvVar('NODE_ENV') === 'production'
    ? `${getEnvVar('NX_NEST_API_SCHEME')}://${getEnvVar('NX_NEST_API_HOST')}${
        getEnvVar('NX_NEST_API_PORT') ? ':' + getEnvVar('NX_NEST_API_PORT') : ''
      }/api/token`
    : '/api/token';

type Ticker = string;
type FromCompId = string;
type FromProcessId = string;
type EarlySubQueueId = `${Ticker}:${FromProcessId}:${FromCompId}`;
type TickerSub = {
  subscription?: Subscription;
  jobsId?: number;
  from: Map<FromProcessId, Set<FromCompId>>;
  latestValue?: Level1IntegrationEvent;
};

export interface IMDaaSProviderService {
  initialize(): void;
  isConnected: boolean;
  dispose(): void;
}

export type FactsetConnectionMode = 'FETCH' | 'GET';

export class MDaaSProviderService implements IMDaaSProviderService {
  private _mdgClient: Mdg2Client;
  private _factsetMap = new Map<Ticker, TickerSub>();
  private _earlySubQueue = new Set<EarlySubQueueId>();
  private _marketdata$: MarketdataSubject;
  private _subscribeSub: Subscription | undefined;
  private _unsubscribeSub: Subscription | undefined;
  private _windowCloseSub: Subscription | undefined;
  private _factsetConnectionMode: FactsetConnectionMode = 'GET';
  private _factsetConnectionSub: Subscription | undefined;
  private _workspace: AppWorkspace;

  constructor(
    workspace: AppWorkspace,
    mdgClient: Mdg2Client,
    factsetConnectionMode: FactsetConnectionMode = getEnvVar(
      'NX_MARKET_DATA_FACTSET_MODE',
      'GET'
    ) as FactsetConnectionMode,
    _marketdata$: MarketdataSubject = marketdata$
  ) {
    this._workspace = workspace;
    this._mdgClient = mdgClient;
    this._factsetConnectionMode = factsetConnectionMode;

    if (getEnvVar('NODE_ENV') === 'development') {
      const logger = new ConsoleLogger('MDaaS');
      logger.setLevel(LogLevel.TRACE);
      mdgClient.setLogger(logger);
    }

    this._marketdata$ = _marketdata$;
  }

  public initialize(): void {
    this._manageSubscriptions();

    this._factsetConnectionSub = defer(() => {
      l.debug(
        `Is Factset client connected? ${this._mdgClient.isConnected} connecting? ${this._mdgClient.isConnecting}`
      );

      return !this._mdgClient.isConnected && !this._mdgClient.isConnecting
        ? from(this._connectToFactset())
        : of();
    })
      .pipe(
        repeat({
          delay: () => timer(10_000)
        }),
        retry({
          resetOnSuccess: true,
          delay: (err) => {
            l.error('Unable to connect to factset, retrying in 10s', err);
            return timer(10_000);
          }
        })
      )
      .subscribe(() => {
        this._drainQueue();
      });
  }

  get isConnected(): boolean {
    return this._mdgClient.isConnected;
  }

  public dispose() {
    l.log('Disposing MDaaSProviderService');
    if (this._factsetConnectionSub) {
      this._factsetConnectionSub.unsubscribe();
    }

    if (this._subscribeSub) {
      this._subscribeSub.unsubscribe();
    }

    if (this._unsubscribeSub) {
      this._unsubscribeSub.unsubscribe();
    }

    if (this._windowCloseSub) {
      this._windowCloseSub.unsubscribe();
    }

    if (this._mdgClient) {
      this._mdgClient.disconnect().catch((e) => {
        l.error('Unable to disconnect from factset', { e: e as Error });
      });
    }
  }

  private async _connectToFactset(): Promise<void> {
    if (this._mdgClient.isConnected) {
      return;
    }
    if (this._factsetConnectionMode === 'FETCH') {
      await this.configureFactsetConnectionByFetch();
    } else {
      await this.configureFactsetConnectionByGet();
    }

    await this._mdgClient.connect();
  }

  private async configureFactsetConnectionByGet(): Promise<void> {
    const response = await fetch(factsetTokenUrl, {
      method: 'GET',
      mode: 'no-cors'
    });
    const { headers } = response;
    const responseToken = headers.get('frontgate-authentication-token');
    const responseHost = headers.get('frontgate-host');

    if (response.status !== 200) {
      throw new Error(`Server responded with code ${response.status}`);
    } else if (!responseToken || !responseHost) {
      throw new Error(`Empty response`);
    } else {
      const options: FrontgateConnectionOptions = {
        host: responseHost,
        //logger: new ConsoleLogger('FrontgateConnection'),
        //loglevel: LogLevel.TRACE,
        shouldReconnectOnConnectionLoss: true
      };

      const auth = new TokenAuthentication(responseToken);
      const connection = new FrontgateWSConnection(auth, options);
      this._mdgClient.setConnection(connection);
    }
  }

  private async configureFactsetConnectionByFetch(): Promise<void> {
    const options: RequestInit = {
      cache: 'no-store',
      mode: 'cors'
    };

    await this._mdgClient.setConnectionFromFetchConfiguration(factsetTokenUrl, options);
  }

  private _manageSubscriptions(): void {
    this._subscribeSub = this._marketdata$
      .pipe(filter((e) => e.type === MARKETDATA_EVENT_TYPE.SUBSCRIBE))
      .subscribe((event) => {
        if (event.type === MARKETDATA_EVENT_TYPE.SUBSCRIBE) {
          for (const ticker of event.payload.tickers) {
            const fromCompId = event.payload.fromCompId;
            this._addFactsetSub(ticker, event.payload.fromProcessId, fromCompId);
          }
        }
      });

    this._unsubscribeSub = this._marketdata$.subscribe((event) => {
      if (event.type === MARKETDATA_EVENT_TYPE.UNSUBSCRIBE) {
        for (const ticker of event.payload.tickers) {
          this._onComponentClose(ticker, event.payload.fromCompId, event.payload.fromProcessId);
        }
      }
    });

    this._windowCloseSub = getWindowDestroyedEvent$({ workspace: this._workspace })
      .pipe(filter((e) => e.type === COMMON_ACTOR_TYPE.WINDOW))
      .subscribe((event) => {
        const closedProcessId = event.id;
        // Find tickers that have the process ID in their fromProcessIds set.
        const tickers = Array.from(this._factsetMap.entries());
        for (const [ticker, tickerSub] of tickers) {
          if (tickerSub.from.has(closedProcessId)) {
            this._onProcessClose(ticker, closedProcessId);
          }
        }
      });
  }

  private _drainQueue(): void {
    if (!this.isConnected) {
      setTimeout(() => this._drainQueue(), 500);
    }

    for (const earlySub of this._earlySubQueue) {
      const [ticker, processId, compId] = earlySub.split(':');
      this._addFactsetSub(ticker, processId, compId);
    }

    this._earlySubQueue.clear();
  }

  private _addFactsetSub(ticker: string, fromProcessId: string, fromCompId: string): void {
    if (!this.isConnected) {
      const queueId: EarlySubQueueId = `${ticker}:${fromProcessId}:${fromCompId}`;
      l.log(`Early sub. Queueing ${queueId}`);
      if (this._earlySubQueue.size === 0) {
        setTimeout(() => this._drainQueue(), 500);
      }
      this._earlySubQueue.add(queueId);
      return;
    }

    if (!this._factsetMap.has(ticker)) {
      this._factsetMap.set(ticker, {
        from: new Map<FromProcessId, Set<FromCompId>>()
      });
    }

    const tickerSub = this._factsetMap.get(ticker);

    if (tickerSub) {
      if (!tickerSub.from.has(fromProcessId)) {
        tickerSub.from.set(fromProcessId, new Set<FromCompId>());
      }

      tickerSub.from.get(fromProcessId)?.add(fromCompId);
    }

    // If we already have a subscription, immediately send the latest value.
    if (tickerSub && tickerSub.subscription && tickerSub.latestValue) {
      l.log(`Publishing latest value ticker for new sub requests ${ticker}`, {
        level1event: tickerSub.latestValue
      });

      this._marketdata$.next({
        type: MARKETDATA_EVENT_TYPE.LEVEL_ONE_UPDATE,
        payload: {
          ticker,
          data: tickerSub.latestValue
        }
      });

      return;
    }

    // If not, let's create a subscription.
    if (tickerSub && !tickerSub?.subscription) {
      const level1$ = level1({
        client: this._mdgClient,
        ticker,
        logRetries: false,
        logMissingFields: false,
        logFactsetResponse: false
      });

      tickerSub.subscription = level1$
        .pipe(
          finalize(() => {
            this._factsetMap.delete(ticker); // Delete optimistically to avoid quick unsub/resub issues from UI.
          })
        )
        .subscribe((e) => {
          tickerSub.latestValue = e;

          this._marketdata$.next({
            type: MARKETDATA_EVENT_TYPE.LEVEL_ONE_UPDATE,
            payload: { ticker, data: tickerSub.latestValue }
          });
        });
    }

    if (!tickerSub) {
      throw new Error('We have no tickerSub');
    }
  }

  private _onProcessClose(ticker: string, fromProcessId: string) {
    const tickerSub = this._factsetMap.get(ticker);

    // handle process id removal
    if (tickerSub) {
      tickerSub.from.delete(fromProcessId);
      if (tickerSub.from.size === 0) {
        tickerSub.subscription?.unsubscribe();
      }
    }
  }

  private _onComponentClose(ticker: string, fromCompId: string, fromProcessId: string) {
    const tickerSub = this._factsetMap.get(ticker);
    const processIdMap = tickerSub?.from.get(fromProcessId);

    if (processIdMap) {
      processIdMap.delete(fromCompId);
      if (processIdMap.size === 0) {
        this._onProcessClose(ticker, fromProcessId);
      }
    }
  }
}
