// TODO: In the future, this should be a generated file.

import {
  type ColumnBuilderCallback,
  type ColumnLibrary,
  PROGRESS_RENDERER,
  CellBadgeClassEnum,
  type MarketDataBuilder
} from '@oms/frontend-vgrid';
import {
  sharedLimitPriceCol,
  sharedSideCol,
  sharedOrderTypeCol,
  sharedAveragePriceCol,
  sharedSettleCurrencyCol,
  sharedInvestorAccountCol,
  sharedSettleTypeCol,
  sharedSettleDateCol,
  sharedExpiryDateTimeCol,
  sharedOrderTifCol,
  sharedCreatedTimestampCol,
  sharedOwnerIdCol,
  sharedRepresentativeCodeCol,
  sharedCustomerNotesCol,
  sharedTradeCurrencyCol,
  sharedOrderStatusWithoutMapperCol,
  sharedTradingOrderCapacityCol,
  sharedUpdatedTimestampCol,
} from '@app/common/grids/columns/order-cols';
import {
  sharedDefaultCol,
  sharedIdCol,
  sharedQuantityCol,
  sharedTextCol,
} from '@app/common/grids/columns/generic-cols';
import { TradingOrderStatus, VisibilityReason } from '@oms/generated/frontend';
import { t } from '@oms/codegen/translations';
import { mapTradingOrderStatus } from '@app/common/mappers/map-trading-order-status';
import type { TradingOrderRow } from './trading-order-monitor.contracts';
import { mapOrderVisibilityReason } from '@app/common/mappers/map-order-visibility-reason';
import { type InstrumentMapping, TradingOrderCategory } from '@oms/generated/frontend';
import type { ColumnBuilder } from '@oms/frontend-vgrid';
import isEmpty from 'lodash/isEmpty';
import type { AnyRecord } from '@oms/frontend-foundation';
import { mapTradingOrderCategory } from '@app/common/mappers/map-trading-order-category';

// ----------- GENERIC INSTRUMENT COLUMNS ----------------------------------------
// ----------- (placing here until generic / codegen is ready) -------------------

export type InstrumentField = Partial<
  | keyof InstrumentMapping
  | 'instrument'
  | 'instrumentCusip'
  | 'instrumentDisplayCode'
  | 'instrumentExchCode'
  | 'instrumentLongName'
>;

export type InstrumentFieldMap<T extends AnyRecord> = Partial<
  Record<InstrumentField, ColumnBuilderCallback<T>>
>;

export type InstrumentOverrideConfig<T extends AnyRecord> = InstrumentFieldMap<T> & {
  instrumentIdField?: string;
  instrumentDisplayCodeField?: string;
  instrumentLongNameField?: string;
  instrumentCusipField?: string;
  instrumentExchCodeField?: string;
  include?: InstrumentField[];
};

export const instrumentDisplayCode =
  (field: string) =>
  <T extends AnyRecord>(c: ColumnBuilder<T>) => {
    const builder = c
      .field(field)
      .header(t('app.common.symbol'))
      .width(110)
      .maxWidth(120)
      .minWidth(100)
      .filter('agTextColumnFilter');

    return builder;
  };

export const instrumentLongName =
  (field: string) =>
  <T extends AnyRecord>(c: ColumnBuilder<T>) =>
    c.field(field).header('Instrument Long Name').minWidth(100).hide();

export const cusip =
  (field: string) =>
  <T extends AnyRecord>(c: ColumnBuilder<T>) =>
    c.field(field).header('CUSIP').width(100).maxWidth(120).minWidth(90).hide();

export const instrumentId =
  (field: string) =>
  <T extends AnyRecord>(c: ColumnBuilder<T>) =>
    c.field(field).header('Instrument ID').width(100).maxWidth(120).minWidth(90).hide();

export const exchangeCode =
  (field: string) =>
  <T extends AnyRecord>(c: ColumnBuilder<T>) =>
    c.field(field).header('Exchange Code').width(200).maxWidth(200).minWidth(200).hide();

export const instrumentCols = <T extends AnyRecord = AnyRecord>(
  overrides: InstrumentOverrideConfig<T>
): ColumnBuilderCallback<T>[] => {
  const {
    instrumentIdField = 'instrument',
    instrumentDisplayCodeField = 'instrumentDisplayCode',
    instrumentExchCodeField = 'instrumentExchCode',
    instrumentCusipField = 'instrumentCusip',
    instrumentLongNameField = 'instrumentLongName',
    include
  } = overrides || {};
  const cols: InstrumentFieldMap<T> = {
    instrumentDisplayCode: instrumentDisplayCode(instrumentDisplayCodeField),
    instrumentLongName: instrumentLongName(instrumentLongNameField),
    instrumentCusip: cusip(instrumentCusipField),
    instrumentExchCode: exchangeCode(instrumentExchCodeField),
    instrument: instrumentId(instrumentIdField)
  };

  const overrideFn =
    (fn: ColumnBuilderCallback<T>, override: ColumnBuilderCallback<T>) => (cb: ColumnBuilder<T>) => {
      return override(fn(cb));
    };

  return Object.keys(cols)
    .filter((k) => {
      return isEmpty(include) || include?.includes(k as any);
    })
    .map((k) => {
      const builder: ColumnBuilderCallback<T> = cols[k as keyof typeof cols] as ColumnBuilderCallback<T>;
      if (!overrides) {
        return builder;
      }
      const overrideBuilder = overrides[k as keyof typeof overrides] as ColumnBuilderCallback<T> | undefined;
      return overrideBuilder ? overrideFn(builder, overrideBuilder) : builder;
    });
};

const defaultCol: ColumnBuilderCallback<TradingOrderRow> = (c) =>
  sharedDefaultCol<TradingOrderRow>(c).enablePivot().enableRowGroup();

const defaultColWithFloatingFilter: ColumnBuilderCallback<TradingOrderRow> = (c) =>
  defaultCol(c).floatingFilter();

const idCol: ColumnBuilderCallback<TradingOrderRow> = (c) => sharedIdCol<TradingOrderRow>(c, 'id').hide();

const sideCol: ColumnBuilderCallback<TradingOrderRow> = (c) =>
  sharedSideCol<TradingOrderRow>(c).field('side');

const locateCol: ColumnBuilderCallback<TradingOrderRow> = (c) => sharedTextCol(c, 'locate').header('Locate');

const limitPriceCol: ColumnBuilderCallback<TradingOrderRow> = (c) => sharedLimitPriceCol(c);

const orderTypeCol: ColumnBuilderCallback<TradingOrderRow> = (c) => sharedOrderTypeCol(c);

const orderCapacityCol: ColumnBuilderCallback<TradingOrderRow> = (c) => sharedTradingOrderCapacityCol(c);

const totalQuantityCol: ColumnBuilderCallback<TradingOrderRow> = (c) =>
  sharedQuantityCol(c, 'totalQuantity').colId('quantity').header('Total Quantity').shortHeader('Total Qty');

const leavesQuantityCol: ColumnBuilderCallback<TradingOrderRow> = (c) =>
  sharedQuantityCol(c, 'leavesQuantity')
    .colId('leavesQuantity')
    .header('Leaves Quantity')
    .shortHeader('Leaves Qty');

const executedQuantityCol: ColumnBuilderCallback<TradingOrderRow> = (c) =>
  sharedQuantityCol(c, 'executedQuantity')
    .colId('executedQuantity')
    .header('Executed Quantity')
    .shortHeader('Executed Qty')
    .cell((c) => c.renderer(PROGRESS_RENDERER));

const averagePriceCol: ColumnBuilderCallback<TradingOrderRow> = (c) =>
  sharedAveragePriceCol<TradingOrderRow>(c, 'averagePrice');

const settleCurrencyCol: ColumnBuilderCallback<TradingOrderRow> = (c) => sharedSettleCurrencyCol(c);

const venueCol: ColumnBuilderCallback<TradingOrderRow> = (c) =>
  sharedTextCol(c, 'venue').header(t('app.orders.orderMonitor.routeTo')).minWidth(90);

const investorAccountCol: ColumnBuilderCallback<TradingOrderRow> = (c) =>
  sharedInvestorAccountCol<TradingOrderRow>(c, 'tradingAccountName')
    .floatingFilter(true)
    .filter('agTextColumnFilter');

const settleTypeCol: ColumnBuilderCallback<TradingOrderRow> = (c) => sharedSettleTypeCol(c);

const settleDateCol: ColumnBuilderCallback<TradingOrderRow> = (c) => sharedSettleDateCol(c);

const expiryDateTimeCol: ColumnBuilderCallback<TradingOrderRow> = (c) => sharedExpiryDateTimeCol(c);

const timeInForceCol: ColumnBuilderCallback<TradingOrderRow> = (c) => sharedOrderTifCol(c);

const enteredByCol: ColumnBuilderCallback<TradingOrderRow> = (c) =>
  sharedTextCol(c, 'enteredByName').header('Entered By').width(180).floatingFilter(true).filter('agTextColumnFilter');

const statusCol: ColumnBuilderCallback<TradingOrderRow> = (c) =>
  sharedOrderStatusWithoutMapperCol(c)
    .filter('agSetColumnFilter')
    .filterParams<TradingOrderStatus>({
      values: Object.values(TradingOrderStatus),
      valueFormatter: ({ value }) => mapTradingOrderStatus(value)
    })
    .cell((c) => c.badge(CellBadgeClassEnum.Capital, (data) => mapTradingOrderStatus(data?.status)));

const updatedTimestampCol: ColumnBuilderCallback<TradingOrderRow> = (c) =>
  sharedUpdatedTimestampCol(c).sort('desc');

const createdTimestampCol: ColumnBuilderCallback<TradingOrderRow> = (c) =>
  sharedCreatedTimestampCol(c).sort('desc');

const orderVisibilityReasonCol: ColumnBuilderCallback<TradingOrderRow> = (c) =>
  c
    .field('orderVisibilityReason')
    .header(t('app.orders.orderMonitor.visibilityReason'))
    .shortHeader(t('app.orders.orderMonitor.visibilityReason', { ns: 'short' }))
    .width(130)
    .filter('agSetColumnFilter')
    .filterParams<VisibilityReason>({
      values: Object.values(VisibilityReason),
      valueFormatter: ({ value }) => mapOrderVisibilityReason(value)
    })
    .hide()
    .cell((c) =>
      c.badge(CellBadgeClassEnum.Capital, (data) => mapOrderVisibilityReason(data?.orderVisibilityReason))
    );

const orderTagsCol: ColumnBuilderCallback<TradingOrderRow> = (c) =>
    c
      .field('orderTagNames')
      .header(t('app.orders.orderMonitor.orderTags'))
      .shortHeader(t('app.orders.orderMonitor.orderTags', { ns: 'short' }))
      .hide()
      .width(110)
      .filter(false)
      .sortable(false)
      .cell((c) => c.valueFormatter((params) => params.data?.orderTagNames?.join(', ') || ''));

const ownerCol: ColumnBuilderCallback<TradingOrderRow> = (c) =>
  sharedTextCol(c, 'ownerName')
    .header(t('app.orders.orderMonitor.owner'))
    .shortHeader(t('app.orders.orderMonitor.owner', { ns: 'short' }))
    .width(180).floatingFilter(true).filter('agTextColumnFilter');

const ownerIdCol: ColumnBuilderCallback<TradingOrderRow> = (c) =>
  sharedOwnerIdCol<TradingOrderRow>(c, 'ownerID').floatingFilter(true).filter('agTextColumnFilter');

const representativeCodeCol: ColumnBuilderCallback<TradingOrderRow> = (c) =>
  sharedRepresentativeCodeCol<TradingOrderRow>(c).field('representativeCode');

const customerNotesCol: ColumnBuilderCallback<TradingOrderRow> = (c) => sharedCustomerNotesCol(c);

const tradeCurrencyCol: ColumnBuilderCallback<TradingOrderRow> = (c) => sharedTradeCurrencyCol(c);

const targetExchangeCol: ColumnBuilderCallback<TradingOrderRow> = (c) =>
  sharedTextCol(c, 'targetExchangeId')
    .header(t('app.orders.orderMonitor.targetExchange'))
    .shortHeader(t('app.orders.orderMonitor.targetExchange', { ns: 'short' }))

const categoryCol: ColumnBuilderCallback<TradingOrderRow> = (c) =>
  sharedOrderStatusWithoutMapperCol(c, 'category')
    .header(t('app.orders.orderMonitor.category'))
    .shortHeader(t('app.orders.orderMonitor.category', { ns: 'short' }))
    .filter('agSetColumnFilter')
    .filterParams<TradingOrderCategory>({
      values: Object.values(TradingOrderCategory),
      valueFormatter: ({ value }) => mapTradingOrderCategory(value)
    })
    .cell((c) => c.badge(CellBadgeClassEnum.Capital, (data) => mapTradingOrderCategory(data?.category)));

export const tradingOrderMonitorColumnLibrary: ColumnLibrary<TradingOrderRow> = {
  defaultColumn: defaultColWithFloatingFilter,
  columns: [
    idCol,
    ...instrumentCols<TradingOrderRow>({
      displayCode: (cb) =>
        cb
          .cell((c) => c.renderer('agGroupCellRenderer'))
          .filter(false)
          .sortable(false)
          .floatingFilter(true, {
            debounce: 25
          })
    }),
    sideCol,
    locateCol,
    limitPriceCol,
    orderTypeCol,
    orderCapacityCol,
    totalQuantityCol,
    leavesQuantityCol,
    executedQuantityCol,
    settleCurrencyCol,
    settleTypeCol,
    settleDateCol,
    investorAccountCol,
    venueCol,
    timeInForceCol,
    expiryDateTimeCol,
    enteredByCol,
    statusCol,
    averagePriceCol,
    createdTimestampCol,
    updatedTimestampCol,
    orderVisibilityReasonCol,
    ownerCol,
    ownerIdCol,
    orderTagsCol,
    tradeCurrencyCol,
    customerNotesCol,
    representativeCodeCol,
    targetExchangeCol,
    categoryCol
  ]
};

export const tradingOrderMarketData = (m: MarketDataBuilder<TradingOrderRow & { ticker?: string }>) =>
  m.level1((l) =>
    l
      .defaultField((c) => c.minWidth(90).width(100).hide())
      .fields({
        tickerId: 'instrumentDisplayCode', // the ticker field to "join" with
        fields: [
          (c) => c.field('bidPrice').show().level1MarketData('bidPrice', 'ticker').filter(false),
          (c) => c.field('askPrice').show().level1MarketData('askPrice', 'ticker').filter(false)
        ]
      })
  );

export const investorOrderTradingOrderMonitorColumnLibrary = {
  defaultColumn: defaultCol,
  columns: [
    idCol,
    ...instrumentCols<TradingOrderRow>({
      displayCode: (cb) => cb.cell((c) => c.renderer('agGroupCellRenderer'))
    }).map((col) => col),
    sideCol,
    locateCol,
    limitPriceCol,
    orderTypeCol,
    totalQuantityCol,
    leavesQuantityCol,
    executedQuantityCol,
    settleCurrencyCol,
    settleTypeCol,
    settleDateCol,
    investorAccountCol,
    venueCol,
    timeInForceCol,
    enteredByCol,
    statusCol,
    averagePriceCol,
    createdTimestampCol,
    updatedTimestampCol,
    orderVisibilityReasonCol
  ]
};
