import type { ActionContext, ActionDefFactory, ActionComponentConfig } from '@oms/frontend-vgrid';
import { type IoRepairRequestFragment, RepairCategory } from '@oms/generated/frontend';
import { PROCESS_ID } from '@valstro/workspace';
import { openInvestorOrderEntry } from '@app/generated/sdk';

const categoryTitleMap: Record<RepairCategory, string> = {
  [RepairCategory.IoCreate]: 'create',
  [RepairCategory.IoModify]: 'modify',
  [RepairCategory.IoCancel]: '',
  [RepairCategory.TradeCreate]: '',
  [RepairCategory.TradeModify]: ''
};

export const repairInvestorOrdersOnChange = async (
  ctx: ActionContext<IoRepairRequestFragment, ActionComponentConfig<IoRepairRequestFragment>>
) => {
  const { lifecycle, data } = ctx;
  const singleRowSelected = data.length === 1;
  const selectedRow = data[0];

  ctx.notify({ isDisabled: !(singleRowSelected && selectedRow) });

  if (lifecycle === 'change' && selectedRow?.id) {
    const titleAction = categoryTitleMap[selectedRow.category];

    await openInvestorOrderEntry(PROCESS_ID.LEADER, {
      title: `Repair ${titleAction} Investor Order`,
      form: {
        input: {
          entryType: 'repair',
          id: selectedRow.id
        },
        triggerValidationOnOpen: true
      }
    });
  }
};

export const repairInvestorOrdersAction: ActionDefFactory<IoRepairRequestFragment> = (builder) =>
  builder
    .name('repair_investor_order')
    .toolbar((t) =>
      t
        .component('action-button')
        .id('repair_investor_order_button')
        .location('HorizontalToolbarRight')
        .props({
          isDisabled: true,
          content: 'Repair'
        })
    )
    .menu((m) => m.name('Repair').visible(({ rowData }) => !!rowData))
    .onChange<ActionComponentConfig<IoRepairRequestFragment>>(repairInvestorOrdersOnChange);
