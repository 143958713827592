import { type AnyRecord } from '@valstro/workspace';
import { type AdditionalFormFieldUnion } from '@app/forms/form-builder/mappers/form.mappers';
import {
  type FieldContract,
  type IAdvancedSelectField,
  type ModifiedFieldForOptions
} from '@oms/frontend-foundation';
import { ADVANCED_SELECT_QUERY_ENUM } from '@app/generated/common';

export const createVenueField = <TOutputContract extends AnyRecord, TFieldKey extends keyof TOutputContract>(
  fc: FieldContract<TOutputContract, AdditionalFormFieldUnion>,
  key: TFieldKey,
  overrides?: ModifiedFieldForOptions<IAdvancedSelectField>
) =>
  fc.field(key, 'advanced-select').advancedSelectOptions({
    label: 'Venue',
    width: 250,
    autoSizeWidthToTrigger: false,
    autoSizeWidth: false,
    query: ADVANCED_SELECT_QUERY_ENUM.WATCH_All_VENUES_FOR_USER_QUERY,
    ...overrides
  });
