import { diff } from 'deep-object-diff';
import { type AnyRecord } from '../../../../../common/type.helpers';
import { isEmpty } from 'lodash';
import type { FormState } from 'final-form';
import { scan } from 'rxjs';

type ScanValuesStateOp<TFormFieldValues extends AnyRecord> = {
  currentValues: Partial<TFormFieldValues>;
  formValuesDiff: Partial<TFormFieldValues>;
  hasDiff: boolean;
  prevFormValues: Partial<TFormFieldValues>;
};

/**
 * Scans the values:
 *
 * @returns The values diff state
 */
export function scanValues<TFormFieldValues extends AnyRecord>() {
  return scan(
    (
      acc: ScanValuesStateOp<TFormFieldValues>,
      value: FormState<TFormFieldValues, Partial<TFormFieldValues>>
    ) => {
      const prevValues = acc.currentValues;
      const currentValues = value.values;
      const valuesDiff = getFormValuesDiff(prevValues, currentValues);
      const hasDiff = !isEmpty(valuesDiff);

      return {
        currentValues,
        hasDiff,
        formValuesDiff: valuesDiff,
        prevFormValues: prevValues
      };
    },
    {
      currentValues: {},
      prevFormValues: {},
      formValuesDiff: {},
      hasDiff: false
    } as ScanValuesStateOp<TFormFieldValues>
  );
}

type ScanActiveValuesStateOp<TFormFieldValues extends AnyRecord> = {
  activeTextElementName: string | null;
  prevTextActiveElementName: string | null;
  isFreeFormTextInput: boolean;
  blurFromFreeFormTextInput: boolean;
  currentValues: Partial<TFormFieldValues>;
  formValuesDiff: Partial<TFormFieldValues>;
  hasDiff: boolean;
  prevFormValues: Partial<TFormFieldValues>;
};

/**
 * Scans the active state of the form:
 * - activeTextElementName: The name of the currently focused text input element
 * - prevTextActiveElementName: The name of the previously focused text input element
 * - isFreeFormTextInput: Whether the currently focused input element is a free form text input
 * - blurFromFreeFormTextInput: Whether the focus moved from a free form text input to another input
 * - currentValues: The current form values
 *
 * @returns The active state of the form
 */
export function scanActiveValues<TFormFieldValues extends AnyRecord>() {
  return scan(
    (
      acc: ScanActiveValuesStateOp<TFormFieldValues>,
      value: FormState<TFormFieldValues, Partial<TFormFieldValues>>
    ) => {
      const prevValues = acc.currentValues;
      const currentValues = value.values;
      const valuesDiff = getFormValuesDiff(prevValues, currentValues);
      const hasDiff = !isEmpty(valuesDiff);

      const currentActiveTextElementName = getFocusedTextInputElementName();
      const previousActiveTextElementName = acc.activeTextElementName;

      const currentActiveAutocompleteElementName = getFocusedAutocompleteInputElementName();
      const autocompleteWasCleared =
        !!prevValues[currentActiveAutocompleteElementName ?? ''] &&
        !currentValues[currentActiveAutocompleteElementName ?? ''];

      const isFreeFormTextInput = currentActiveTextElementName !== null;
      const blurFromFreeFormTextInput =
        (previousActiveTextElementName !== null && !hasDiff) ||
        (currentActiveAutocompleteElementName !== null && !autocompleteWasCleared);

      return {
        activeTextElementName: currentActiveTextElementName,
        prevTextActiveElementName: previousActiveTextElementName,
        isFreeFormTextInput,
        blurFromFreeFormTextInput,
        currentValues,
        hasDiff,
        formValuesDiff: valuesDiff,
        prevFormValues: prevValues
      };
    },
    {
      activeTextElementName: null,
      prevTextActiveElementName: null,
      isFreeFormTextInput: false,
      blurFromFreeFormTextInput: false,
      currentValues: {},
      formValuesDiff: {},
      hasDiff: false
    } as ScanActiveValuesStateOp<TFormFieldValues>
  );
}

type ScanSanitizedStateOp<TFormFieldValues extends AnyRecord> = {
  currentValues: Partial<TFormFieldValues>;
  prevValues: Partial<TFormFieldValues>;
  // scanPrevValues are prevValues to be used in the next iteration
  // in contrast to prevValues containing the actual previous values
  // to report in the event. We differentiate so prevent endless loop
  // and to be able to give the actual previous value.
  scanPrevValues?: Partial<TFormFieldValues>;
  tempDiff: Partial<TFormFieldValues>;
  valuesDiff: Partial<TFormFieldValues>;
  hasValuesDiff: boolean;
};

/**
 * Scans the sanitized state of the form:
 * - currentValues: The current form values
 * - prevValues: The previous form values
 * - tempDiff: Used to store the valuesDiff when the focus moves from a free form text input to another input
 * - valuesDiff: The difference between the form values before and after the change
 * - hasValuesDiff: Used to determine whether there is a difference between the form values before and after the change
 *
 * Note: if hasValuesDiff: true, the onSanitizedValuesChanged callback will be triggered
 *
 * @returns The sanitized state of the form
 */
export function scanSanitized<TFormFieldValues extends AnyRecord>() {
  return scan(
    (acc: ScanSanitizedStateOp<TFormFieldValues>, value: ScanActiveValuesStateOp<TFormFieldValues>) => {
      const tempDiff = acc.tempDiff;
      const hasTempValues = !isEmpty(tempDiff);

      const prevValues = acc.scanPrevValues ?? acc.prevValues;
      const currentValues = value.currentValues;
      const valuesDiff = getFormValuesDiff(prevValues, currentValues);
      const hasValuesDiff = !isEmpty(valuesDiff);

      // If we've moved focus from a free form text input to another input AND we have temp values,
      // we should return a valuesDiff with the temp values & reset the tempDiff
      // Note, this will trigger the onValuesChanged callback
      if (value.blurFromFreeFormTextInput && hasTempValues) {
        return {
          currentValues,
          prevValues,
          scanPrevValues: currentValues,
          tempDiff: {},
          valuesDiff: tempDiff,
          hasValuesDiff: true
        };
      }

      // If we're focused on a free form text input, we should store the valuesDiff in tempDiff
      // Instead of returning the valuesDiff
      // Note, this will not trigger the onValuesChanged callback
      if (value.isFreeFormTextInput && hasValuesDiff) {
        return {
          currentValues,
          prevValues,
          tempDiff: valuesDiff,
          valuesDiff: {},
          hasValuesDiff: false
        };
      }

      // Otherwise, if there is an value diff, return it
      // Note, this will trigger the onValuesChanged callback
      if (hasValuesDiff) {
        return {
          currentValues,
          prevValues,
          scanPrevValues: currentValues,
          tempDiff: {},
          valuesDiff: valuesDiff,
          hasValuesDiff: hasValuesDiff
        };
      }

      // Otherwise, return the current values
      // Note, this will not trigger the onValuesChanged callback
      return {
        currentValues,
        prevValues,
        tempDiff: {},
        valuesDiff: {},
        hasValuesDiff: false
      };
    },
    {
      currentValues: {} as Partial<TFormFieldValues>,
      prevValues: {} as Partial<TFormFieldValues>,
      tempDiff: {} as Partial<TFormFieldValues>,
      valuesDiff: {} as Partial<TFormFieldValues>,
      hasValuesDiff: false
    } as ScanSanitizedStateOp<TFormFieldValues>
  );
}

/**
 * Helper function to get the difference between the form values before and after the change
 *
 * @param prevValues - Form values before the change
 * @param formValues - Form values after the change
 * @returns The difference between the form values before and after the change
 */
function getFormValuesDiff<TFormFieldValues extends AnyRecord>(
  prevValues: Partial<TFormFieldValues>,
  formValues: Partial<TFormFieldValues>
): Partial<TFormFieldValues> {
  const isBothEmpty = isEmpty(prevValues) && isEmpty(formValues);
  const isPrevEmpty = isEmpty(prevValues) && !isEmpty(formValues);

  return isBothEmpty
    ? ({} as Partial<TFormFieldValues>)
    : isPrevEmpty
    ? formValues
    : (diff(prevValues, formValues) as Partial<TFormFieldValues>);
}

/**
 * Helper that gets the currently focused input element name
 *
 * @returns string | null
 */
function getFocusedTextInputElementName(): string | null {
  const activeElement = document.activeElement;

  const isInputOrTextArea =
    activeElement instanceof HTMLInputElement || activeElement instanceof HTMLTextAreaElement;

  if (isInputOrTextArea) {
    return activeElement.getAttribute('name');
  }

  return null;
}

/**
 * Helper that gets the currently focused autocomplete input element name
 *
 * @returns string | null
 */
function getFocusedAutocompleteInputElementName(): string | null {
  const activeElement = document.activeElement;

  const isInputOrTextArea =
    activeElement instanceof HTMLInputElement || activeElement instanceof HTMLTextAreaElement;

  const isAutocomplete = isInputOrTextArea
    ? activeElement.getAttribute('aria-haspopup') === 'listbox'
    : false;

  if (isAutocomplete && isInputOrTextArea) {
    return activeElement.getAttribute('name');
  }

  return null;
}
