import { Observable } from 'rxjs';
import type { GetRowIdFunc, RowModelType } from '@ag-grid-community/core';
import type { Optional } from '@oms/shared/util-types';
import type { AnyRecord } from '@oms/frontend-foundation';
import type { Datasource } from '../models/datasource.model';

export class DatasourceBuilder<TData extends AnyRecord> {
  private _dataSourceConfig: Datasource<TData> = {} as Datasource<TData>;

  public rowId(cb: GetRowIdFunc<TData>): DatasourceBuilder<TData> {
    this._dataSourceConfig.rowId = cb;
    return this;
  }

  public source(data$: Datasource<TData>['data$']): DatasourceBuilder<TData> {
    this._dataSourceConfig.data$ = data$;
    return this;
  }

  public cacheBlockSize(size: number): DatasourceBuilder<TData> {
    this._dataSourceConfig.cacheBlockSize = size;
    return this;
  }

  public maxBlocksInCache(blocks: number): DatasourceBuilder<TData> {
    this._dataSourceConfig.maxBlocksInCache = blocks;
    return this;
  }

  public rowBuffer(buffer: number): DatasourceBuilder<TData> {
    this._dataSourceConfig.rowBuffer = buffer;
    return this;
  }

  public cacheOverflowSize(size: number): DatasourceBuilder<TData> {
    this._dataSourceConfig.cacheOverflowSize = size;
    return this;
  }

  public build(): Datasource<TData> {
    const { rowModelType, _dataSourceConfig: config } = this;
    const { data$, rowId } = config;

    // Bypass checking for row ID if server-side tree data as row ID conflicts
    if (!rowId) {
      throw this.error('A datasource requires a row id.');
    }

    if (!data$) {
      throw this.error('Please provide a datasource.');
    }

    if (rowModelType) this._dataSourceConfig.rowModelType = rowModelType;

    return this._dataSourceConfig;
  }

  // 👁️ Private ---------------------------------------------------- /

  private error(message: string) {
    return new Error(`[DatasourceBuilder] ${message}`);
  }

  private get rowModelType(): Optional<RowModelType> {
    const { data$ } = this._dataSourceConfig;

    if (data$ instanceof Observable || Array.isArray(data$)) {
      return 'clientSide';
    } else if (data$ && 'getRows' in data$) {
      return 'serverSide';
    }
  }
}
