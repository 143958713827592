import 'reflect-metadata';
import { COMMON_OBJECT_CATEGORY } from './app-config/registry.config';
import { COMPONENT_PROPS_TO_EXCLUDE_MAP, LAYOUT_PROPS_TO_EXCLUDE_MAP } from './generated/mappers';
import { FORM_MAPPERS } from './app.mapper.form';
import { createAppWorkspace } from './create-workspace';
import { snapshotsAndRecoveryPlugin } from './workspace/plugin-snapshots-recovery/snapshots-recovery.plugin';
import 'flexlayout-react/style/dark.css';
import './global.css';
import '../../../../dist/libs/frontend/foundation/style.css'; // Note: Might want to auto-copy this into the assets folder
import { rememberWindowPlugin } from './workspace/plugin-remember-window/remember-window.plugin';
import { container } from 'tsyringe';

const dynamicWidgetComponentsMap = import('./app.mapper.widget');

export const { App } = createAppWorkspace({
  dynamicWidgetComponentsMap,
  formMappers: FORM_MAPPERS,
  plugins: [
    snapshotsAndRecoveryPlugin({
      excludeWindowWidgetCategories: [COMMON_OBJECT_CATEGORY.SYSTEM, COMMON_OBJECT_CATEGORY.TRADING_DIALOG],
      excludePropsFromSnapshot: {
        ...COMPONENT_PROPS_TO_EXCLUDE_MAP,
        ...LAYOUT_PROPS_TO_EXCLUDE_MAP
      },
      excludeWindowWidgetTypes: [],
      container: container
    }),
    rememberWindowPlugin({
      excludeWindowWidgetCategories: [COMMON_OBJECT_CATEGORY.SYSTEM],
      container
    })
  ]
});

export default App;
