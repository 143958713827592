import {
  FormContract,
  FieldDefinition,
  type InferFormValuesFromFormContract
} from '@oms/frontend-foundation';
import * as field from './investor-order-entry.form-fields';
import type { InvestorOrderEntryFieldContract } from './investor-order-entry.form-common';
import { makeTIFSplitDisplayFields } from '@app/forms/common/fields/TIF-field/TIF-field.common';
import {
  createAdditionalAllFields,
  createEnrichedField
} from '@app/forms/form-builder/fields/additional-fields/additional-fields.common';
import { createMarketDataField } from '@app/forms/form-builder/fields/market-data-field/market-data-field.common';

export const investorOrderEntryContract = FormContract.create<InvestorOrderEntryFieldContract>()
  .fields({
    hiddenFormInfo: field.hiddenFormInfo,
    id: field.id,
    instrument: field.instrument,
    investorAccountId: field.investorAccountId,
    quantity: field.quantity,
    limitPrice: field.limitPrice,
    sideType: field.side,
    locate: field.locate,
    settleCurrency: field.settleCurrency,
    tradeCurrency: field.tradeCurrency,
    representativeCode: field.repCode,
    ...field.tifFields,
    settleType: field.settlement.settlementType,
    settleDate: field.settlement.settlementDate,
    customerNotes: field.customerNotes,
    orderTags: field.orderTags,
    receivedTimestamp: field.receivedTimestamp,
    rateType: field.commission.commissionType,
    commission: field.commission.commission,
    stopPrice: field.stopPrice,
    primaryBenchmark: field.primaryBenchmark,
    notionalAmount: field.notionalAmount,
    discretionaryType: field.discretionaryType,
    preAllocationAccount: field.preAllocationAccount,
    chargeSchedule: field.chargeSchedule,
    parentTradingOrderId: field.parentTradingOrderId,
    owner: field.ownerId,
    settleCounterpartyId: field.settleCounterparty,
    stoppedPrice: field.stoppedPrice,
    stoppedQuantity: field.stoppedQuantity,
    stoppedTime: field.stoppedTime
  })
  .schema((f) => {
    const commissionContainer = field.commission.commissionContainer.build();
    const settlementContainer = field.settlement.settlementContainer.build();
    const { gtdDate, gtdTime, tifDuration } = f;
    const tifContainer = makeTIFSplitDisplayFields('timeInForceGroup', f.timeInForce, {
      gtdDate,
      gtdTime,
      tifDuration
    });
    return {
      fields: [
        createMarketDataField('market-data', {
          instrumentFieldName: 'instrument',
          orderQuantityField: 'quantity',
          trackableFieldMap: {
            bid: 'limitPrice',
            ask: 'limitPrice'
          }
        }),
        f.id,
        FieldDefinition.runway('runway', [
          { ...f.investorAccountId, wrapperSx: { flexGrow: 1 } },
          { ...f.instrument, wrapperSx: { flexGrow: 1 } },
          f.sideType,
          f.quantity,
          f.limitPrice
        ]),
        createAdditionalAllFields({
          groups: ['Order', 'Details', 'Settle', 'Stopped fill'],
          fixedFields: [
            'primaryBenchmark',
            settlementContainer.name,
            'settleCurrency',
            tifContainer.name,
            commissionContainer.name,
            'locate'
          ],
          fields: [
            // Order
            [createEnrichedField(f.primaryBenchmark, { groupName: 'Order' })],
            [createEnrichedField(settlementContainer, { groupName: 'Order' })],
            [createEnrichedField(f.settleCurrency, { groupName: 'Order' })],
            [createEnrichedField(tifContainer, { groupName: 'Order', colSpan: 2 })],
            [createEnrichedField(commissionContainer, { groupName: 'Order' })],
            [createEnrichedField(f.locate, { groupName: 'Order' })],
            [createEnrichedField(f.notionalAmount, { groupName: 'Order' })],
            [createEnrichedField(f.discretionaryType, { groupName: 'Order' })],
            [createEnrichedField(f.stopPrice, { groupName: 'Order' })],
            [createEnrichedField(f.customerNotes, { groupName: 'Order' })],
            [createEnrichedField(f.orderTags, { groupName: 'Order', colSpan: 3 })],
            // Details
            [createEnrichedField(f.receivedTimestamp, { groupName: 'Details' })],
            [createEnrichedField(f.owner, { groupName: 'Details' })],
            [createEnrichedField(f.representativeCode, { groupName: 'Details' })],
            // Settle
            [createEnrichedField(f.preAllocationAccount, { groupName: 'Settle' })],
            [createEnrichedField(f.settleCounterpartyId, { groupName: 'Settle' })],
            [createEnrichedField(f.tradeCurrency, { groupName: 'Settle' })],
            // Stopped fill,
            [createEnrichedField(f.stoppedQuantity, { groupName: 'Stopped fill' })],
            [createEnrichedField(f.stoppedPrice, { groupName: 'Stopped fill' })],
            [createEnrichedField(f.stoppedTime, { groupName: 'Stopped fill' })]
          ],
          sx: {
            height: 'full'
          }
        }),
        {
          name: 'charge-schedule-section',
          component: 'section',
          fields: [[f.chargeSchedule]],
          sx: { width: 'full', marginTop: 1 },
          style: { minHeight: '3.9rem' }
        }
      ]
    };
  })
  .template('simple', { showSubmitButton: false });

export type InvestorOrderEntryContractType = typeof investorOrderEntryContract;

export type InvestorOrderEntryValues = InferFormValuesFromFormContract<InvestorOrderEntryContractType>;
