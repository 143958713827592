import {
  getGridPropsAndItems,
  getMostRecentCharges,
  gridPopoverChargeScale,
  gridPopoverChargeScaleProps,
  gridPopoverChargeSchedule,
  gridPopoverChargeScheduleProps,
  groupChargesByName
} from '@app/common/types/charges/charges.utils';
import {
  ChargeGroup,
  type CompositeChargeInfoFragment,
  type GetMatchingChargeSchedulesQuery,
  RateType
} from '@oms/generated/frontend';
import type { GridColValues } from '@oms/shared-frontend/ui-design-system';
import { APP_DISPLAY_FIELD_COMPONENT_TYPE, type AppDisplayGridProps } from '@oms/frontend-foundation';
import type { ResponsiveValue } from '@oms/shared-frontend/ui-design-system/css';

export const createChargeScheduleDisplayFromCompositeCharges = (
  compositeCharges: CompositeChargeInfoFragment[]
): AppDisplayGridProps | undefined => {
  if (!compositeCharges || compositeCharges.length === 0) return undefined;

  const chargeMap = groupChargesByName(compositeCharges);
  const mostRecentCharges = getMostRecentCharges(chargeMap).filter(
    (compositeCharge) => compositeCharge.chargeGroup === ChargeGroup.Fee
  );

  const components: AppDisplayGridProps['items'] = mostRecentCharges.flatMap((compositeCharge) => {
    const isScale = compositeCharge.rateType === RateType.Scale;
    const { gridProps, items } = getGridPropsAndItems({
      compositeCharge,
      isScale,
      showChargeAndScaleName: true,
      showChargeName: true
    });

    return [
      {
        component: {
          type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Popover,
          options: {
            trigger: 'click',
            width: 220
          },
          content: {
            gridProps,
            items
          },
          value: {
            component: {
              type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Link,
              text: compositeCharge.scheduleName
            }
          }
        },
        sx: {
          paddingLeft: 4
        }
      }
    ];
  });

  return {
    gridProps: {
      columns: (1 + components.length) as ResponsiveValue<GridColValues>
    },
    items: [
      {
        component: {
          type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Text,
          value: 'Fee schedule'
        }
      },
      ...components
    ],
    style: {
      display: 'flex'
    }
  };
};

export const createChargeScheduleDisplay = (
  schedules: GetMatchingChargeSchedulesQuery['getMatchingChargeSchedules']
): AppDisplayGridProps['items'] => {
  if (!schedules) return [];

  return schedules.flatMap((chargeSchedule) => {
    const isScale = chargeSchedule?.rateType === RateType.Scale;
    const gridProps = isScale ? gridPopoverChargeScaleProps : gridPopoverChargeScheduleProps;

    const items = isScale
      ? gridPopoverChargeScale({
          chargeName: chargeSchedule?.charge?.name,
          scaleName: chargeSchedule?.scaleSchedule?.name,
          basis: chargeSchedule?.scaleSchedule?.scale?.basis,
          rateType: chargeSchedule?.scaleSchedule?.scale?.rateType,
          rows: chargeSchedule?.scaleSchedule?.scale?.rows,
          showChargeAndScaleName: true
        })
      : gridPopoverChargeSchedule({
          chargeName: chargeSchedule?.charge?.name,
          rateType: chargeSchedule?.rateType,
          rateValue: chargeSchedule?.rateValue,
          min: chargeSchedule?.minimumChargeValue,
          max: chargeSchedule?.maximumChargeValue,
          showChargeName: true
        });

    return [
      {
        component: {
          type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Popover,
          options: {
            trigger: 'click',
            width: 220
          },
          content: {
            gridProps,
            items
          },
          value: {
            component: {
              type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Link,
              text: chargeSchedule?.name || ''
            }
          }
        },
        sx: {
          paddingLeft: 4
        }
      }
    ];
  });
};
