import { useMemo } from 'react';
import { Box, Text, Divider, type Sprinkles } from '@oms/shared-frontend/ui-design-system';
import {
  type VisibleInvestorOrderInfoWithAllocationsFragment,
  type CompositeChargeInfoFragment,
  ChargeGroup,
  RateType
} from '@oms/generated/frontend';
import {
  chargeGroupTotal,
  getGridPropsAndItems,
  getMostRecentCharges,
  groupChargesByName
} from '@app/common/types/charges/charges.utils';
import {
  AppDisplayGrid,
  APP_DISPLAY_FIELD_COMPONENT_TYPE,
  type AppDisplayGridProps
} from '@oms/frontend-foundation';

const getDisplayGridItems = (
  chargeGroup: ChargeGroup,
  compositeCharges: CompositeChargeInfoFragment[],
  order: VisibleInvestorOrderInfoWithAllocationsFragment,
  isToday: boolean
): AppDisplayGridProps['items'] => {
  if (!compositeCharges || compositeCharges.length === 0) return [];

  const chargeMap = groupChargesByName(compositeCharges);
  const mostRecentCharges = getMostRecentCharges(chargeMap);

  return mostRecentCharges.flatMap((compositeCharge) => {
    const chargeName = isToday
      ? `${compositeCharge.chargeName} today`
      : `${compositeCharge.chargeName} total`;
    const chargeSchedule = compositeCharge.figuration?.chargeSchedule;
    const isScale = chargeSchedule?.rateType === RateType.Scale;
    const { gridProps, items } = getGridPropsAndItems({ compositeCharge, isScale });

    return [
      {
        component: {
          type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Text,
          value: chargeName
        },
        testId: chargeName
      },
      {
        component: {
          type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Popover,
          options: {
            trigger: 'click',
            width: 220
          },
          content: {
            gridProps,
            items,
            testId: 'io-view-charges-summary-popover'
          },
          value: {
            component: {
              type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Link,
              text: compositeCharge.scheduleName || ''
            },
            testId: compositeCharge.scheduleName || ''
          }
        }
      },
      ...chargeGroupTotal({ chargeGroup, order, compositeChargeName: compositeCharge.chargeName, isToday })
    ];
  }) as AppDisplayGridProps['items'];
};

const gridProps: AppDisplayGridProps['gridProps'] = {
  columns: 3,
  rows: 1,
  columnGap: 10,
  rowGap: 3
};

const wrapperSx: Sprinkles = {
  padding: 5,
  display: 'flex',
  flexDirection: 'row',
  columnGap: 24
};

const boxSx: Sprinkles = {
  display: 'flex',
  width: 'half',
  flexDirection: 'column',
  rowGap: 3
};

export const ChargesSummary = ({
  investorOrder,
  isToday
}: {
  investorOrder: VisibleInvestorOrderInfoWithAllocationsFragment;
  isToday: boolean;
}) => {
  const feeData = investorOrder?.compositeCharges?.filter((charge) => charge.chargeGroup === 'FEE') ?? [];
  const marketChargesData =
    investorOrder?.compositeCharges?.filter((charge) => charge.chargeGroup === 'MARKET') ?? [];

  const displayGridFeeItems = useMemo(() => {
    return getDisplayGridItems(ChargeGroup.Fee, feeData, investorOrder, isToday);
  }, [feeData, investorOrder, isToday]);

  const displayGridMarketItems = useMemo(() => {
    return getDisplayGridItems(ChargeGroup.Market, marketChargesData, investorOrder, isToday);
  }, [marketChargesData, investorOrder, isToday]);

  return (
    <Box sx={wrapperSx} data-testid="io-view-charges-summary">
      <Box sx={boxSx}>
        <Text>Fees</Text>
        <AppDisplayGrid items={displayGridFeeItems} gridProps={gridProps} labelSize="small" />
      </Box>
      <Divider sx={{ height: 'auto' }} orientation="vertical" />
      <Box sx={boxSx}>
        <Text>Market Charges</Text>
        <AppDisplayGrid items={displayGridMarketItems} gridProps={gridProps} labelSize="small" />
      </Box>
    </Box>
  );
};
