import type MontageTargetState from '@app/data-access/services/trading/montage/utils/montage-target-state.class';
import type { GetVersionedSchemaType } from '@oms/frontend-schemas';
import type { LATEST_VERSIONED_SCHEMA } from './schema';

export type MontageTargetTradingOrder = {
  targetState?: MontageTargetState;
  // If you need to change the shape of this type, you should create a new versioned schema.
  // To accomplish this, you can start by running the following command & updating the schema / migrations as needed:
  // pnpm exec nx generate @oms/ui-gen:sub-schema-migration --key=TARGET_TRADING_ORDER --no-interactive
} & GetVersionedSchemaType<typeof LATEST_VERSIONED_SCHEMA>['data'];

export const MONTAGE_TARGET_TRADING_ORDER_ACTION_NAME = 'montage_target_trading_order';
