import { t } from 'i18next';
import {
  type ValidateDurationInputOptions,
  validateDurationInput
} from '../validate-duration-input/validate-duration-input.validator';
import type { MultiInputOption } from '@oms/frontend-foundation';

export const validateTifInput =
  (options: ValidateDurationInputOptions) => (value: MultiInputOption, allValues: Record<string, any>) => {
    if (!value) {
      return t('app.common.requiredField');
    }

    const subcomponent = value.subcomponent;

    if (subcomponent) {
      if (subcomponent.name === 'duration') {
        const result = validateDurationInput(options)(allValues['duration']);

        if (result) {
          return result;
        }
      }

      return allValues[subcomponent.name] ? null : t('app.common.requiredField');
    }
  };
