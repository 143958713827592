import { type Actor, COMMON_ACTOR_TYPE, Workspace } from '@valstro/workspace';
import type { FoundationWorkspaceMeta } from '@oms/frontend-foundation';
import type { OfflineDatabase } from '@app/data-access/offline/offline-database';
import type { COMMON_AUTH_WINDOW, CommonAuthWindowActorSchema } from '@app/common/auth/auth.contracts';
import { GetVersionedSchemaDataType } from '@oms/frontend-schemas';
import { LATEST_VERSIONED_SCHEMA } from './schemas/snapshots';
import type { GetActorDefFromAppSnapshotDefinition } from './schemas/snapshots/type.helpers';
import { FLEX_LAYOUT_ACTOR_TYPE } from '@valstro/workspace-react';

/**
 * Custom Workspace meta is a place to store specific data relevant to our application.
 */
export type WorkspaceMeta = {
  offlineDatabase: OfflineDatabase;
  authActor: Actor<CommonAuthWindowActorSchema>;
} & FoundationWorkspaceMeta;

/**
 * Extends default Workspace type with our applications meta type.
 */
export type AppWorkspace = Workspace<WorkspaceMeta>;

export type UnconstructedAppWorkspace = new (
  ...args: ConstructorParameters<typeof Workspace>
) => AppWorkspace;

export const AppWorkspace = Workspace as unknown as UnconstructedAppWorkspace;

/**
 * The application snapshot definition & actor definiton types.
 */
export type AppSnapshotDefinition = GetVersionedSchemaDataType<typeof LATEST_VERSIONED_SCHEMA>;

export type AppWindowActorDef = GetActorDefFromAppSnapshotDefinition<typeof COMMON_ACTOR_TYPE.WINDOW>;
export type AppWindowContext = AppWindowActorDef['context'];

export type AppWidgetActorDef = GetActorDefFromAppSnapshotDefinition<typeof COMMON_ACTOR_TYPE.WIDGET>;
export type AppWidgetContext = AppWidgetActorDef['context'];

export type AppFlexLayoutActorDef = GetActorDefFromAppSnapshotDefinition<typeof FLEX_LAYOUT_ACTOR_TYPE>;
export type AppFlexLayoutContext = AppFlexLayoutActorDef['context'];

export type AppAuthWindowActorDef = GetActorDefFromAppSnapshotDefinition<typeof COMMON_AUTH_WINDOW.TYPE>;
export type AppAuthWindowContext = AppAuthWindowActorDef['context'];
