import { map } from 'rxjs';
import type { AdvancedSelectQueryFn } from '@oms/frontend-foundation';
import type { ComboBoxItem } from '@oms/frontend-foundation';
import { TradeCounterpartyService } from '@app/data-access/services/reference-data/trade-counterparty/trade-counterparty.service';

export type TradeCounterpartyInfoFieldValue = ComboBoxItem;

export const tradeCounterpartyQuery: AdvancedSelectQueryFn = (container) => {
  const service = container.resolve(TradeCounterpartyService);

  return {
    query: () => {
      return service.watchAll$().pipe(
        map(({ results, isFetching, error }) => ({
          results: results?.map((r) => ({
            id: r.id ?? '',
            label: r.tradeCounterpartyCode ?? '',
            sublabel: r.tradeCounterpartyDescription ?? '',
            type: 'item',
            value: r.id ?? ''
          })),
          isFetching,
          error
        }))
      );
    },
    type: 'watchAll'
  };
};

export default tradeCounterpartyQuery;
