import { NotificationResourceTemplates, Priority, ResourceType } from '@oms/generated/frontend';
import type { OmsNotification } from './notifications.utils';

export const allAlertsMockData: OmsNotification[] = [
  {
    id: '00000000-0000-0000-0000-000000000001',
    content: 'BUY 100 MSFT-US for BLACKROCK', // Row 1's main text
    isRead: false,
    isSeen: false,
    priority: Priority.Top,
    resourceType: ResourceType.InvestorOrder,
    templateIdentifier: 'ionew',
    name: NotificationResourceTemplates.IoNew, // The NotificationCellRenderer uses this to translate to a human readable text in Row 2
    createdAt: '2024-09-10T15:16:51.722Z'
    // possible other fields:
    // version: 1,
    // dismissalType: DismissalType.Dismissible,
    // payloadFields: [],
    // commands: [],
    // sound: Sound.ValstroDefault,
    // inGridNotificationIcon: InGridNotificationIcon.Hazard,
    // inRowStyling: InRowStyling.Hazard
  } as OmsNotification,
  {
    id: '00000000-0000-0000-0000-000000000002',
    content: 'SELL 2000 TSLA-US $151.2 for Neuberger Berman',
    isRead: true,
    isSeen: false,
    priority: Priority.Top,
    resourceType: ResourceType.InvestorOrder,
    templateIdentifier: 'ioexecution',
    name: NotificationResourceTemplates.IoExecution,
    createdAt: '2024-09-09T16:17:52.354Z'
  } as OmsNotification
];
