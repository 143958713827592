import { createTIFFields } from '@app/forms/common/fields/TIF-field/TIF-field.common';
import { createLocateField } from '@app/forms/common/fields/locate-field/locate-field.common';
import { createQuantityField } from '@app/forms/common/fields/quantity-field/quantity-field.common';
import { makeCommonRequiredProps } from '@app/forms/common/fields/shared-field-props';
import { createSideField } from '@app/forms/common/fields/side-field/side-field.common';
import { createSymbolField } from '@app/forms/common/fields/symbol-field/symbol.field.common';
import { createVenueField } from '@app/forms/common/fields/venue-field/venue.field.common';
import { createRoutableUsersField } from './advanced-select-queries/routable-users.field';
import type { RouteOrderFieldContract } from '@app/widgets/trading/route-order/route-order.form-common';
import { FieldContract, type InferFormValueFromFieldDefinition } from '@oms/frontend-foundation';
import { DestinationType, TagTypeEnum, PriceOptions } from '@oms/generated/frontend';
import { type AdditionalFormFieldUnion } from '@app/forms/form-builder/mappers/form.mappers';
import {
  VALIDATE_ROUTE_ORDER_MINIMAL_QUANTITY,
  VALIDATE_ROUTE_ORDER_QUANTITY
} from './validators/route-order-quantity-field.validators';
import { ROUTE_ORDER_INVESTOR_ORDER_IS_SIDE_ENABLED } from './actions/route-order-side-field.action';
import { ROUTE_ORDER_INVESTOR_ORDER_IS_TIF_ENABLED } from './actions/route-order-tif-field.action';
import { ROUTE_ORDER_INVESTOR_ORDER_IS_SYMBOL_ENABLED } from './actions/route-order-symbol-field.action';
import { ROUTE_ORDER_VENUE_QUERY_TO_USE } from './actions/route-order-venue-field.action';
import { ROUTE_ORDER_TRADING_ORDER_IS_SETTLEMENT_ENABLED } from './actions/route-order-settlement.action';
import { createEnrichedField } from '@app/forms/form-builder/fields/additional-fields/additional-fields.common';
import { createCurrencyField } from '@app/forms/common/fields/currency-field/currency.field';
import { createSettlementFields } from '@app/forms/common/fields/settlement-field/settlement.field';
import { createCustomerNotesField } from '@app/forms/common/fields/customer-notes-field/customer-notes.field';
import { createOrderTagsField } from '@app/forms/common/fields/order-tags-field/order-tags.field';
import { ROUTE_ORDER_TRADER_ACTION } from './actions/route-order-trader-field.action';
import { ADVANCED_SELECT_QUERY_ENUM } from '@app/generated/common';
import { type FIXatdlStrategyValue } from './fixatdl-strategy-field/fixatdl-strategy-field.contracts';
import { ROUTE_ORDER_INVESTOR_ORDER_IS_LOCATE_ENABLED } from './actions/route-order-locate-field.action';
import { createVenueNotesField } from '@app/forms/common/fields/venue-notes-field/venue-notes.field';

import { createAccountField } from '@app/forms/common/fields/account-field/account.field.common';
import { ROUTE_ORDER_INVESTOR_ORDER_PRICE_ARE_SHORTCUTS_ENABLED } from './actions/route-order-price-field.action';
import { VALIDATE_ROUTE_ORDER_LIMIT_PRICE } from './validators/route-order-price-field.validators';
import type { ITextField } from '@oms/frontend-foundation';
import { createPriceAndOptionsField } from '@app/forms/common/fields/price-and-options-field/price-and-options-field.common';

const fc = FieldContract.create<RouteOrderFieldContract, AdditionalFormFieldUnion>();

// ----------- Hidden fields --------------------------------------
// ----------------------------------------------------------------
export const hiddenMode = fc.field('hiddenMode', 'hidden-field');
export const hiddenStrategyOptions = fc.field('hiddenStrategyOptions', 'hidden-field');

// ----------- Runway Fields --------------------------------------
// ----------------------------------------------------------------

export const instrument = createSymbolField(fc, 'instrument', {
  ...makeCommonRequiredProps({
    indicatorStyle: 'none'
  }),
  autoFocus: true,
  actions: {
    resolveProps: [ROUTE_ORDER_INVESTOR_ORDER_IS_SYMBOL_ENABLED]
  }
});

export const sideType = createSideField(fc, 'sideType', {
  ...makeCommonRequiredProps({
    indicatorStyle: 'none'
  }),
  actions: {
    resolveProps: [ROUTE_ORDER_INVESTOR_ORDER_IS_SIDE_ENABLED]
  }
});

export const matchedInvestorOrderIds = fc
  .field('matchedInvestorOrderIds', 'matched-investor-orders-field')
  .options({
    label: 'Investor Orders',
    condition: {
      when: 'hiddenMode.type',
      is: 'create'
    }
  });

export const quantity = createQuantityField(fc, 'quantity', {
  ...makeCommonRequiredProps({
    indicatorStyle: 'none',
    additionalValidators: [
      { type: VALIDATE_ROUTE_ORDER_QUANTITY },
      { type: VALIDATE_ROUTE_ORDER_MINIMAL_QUANTITY }
    ]
  })
});

export const limitPrice = createPriceAndOptionsField(fc, 'limitPrice', {
  initialValue: PriceOptions.Market,
  actions: {
    resolveProps: [ROUTE_ORDER_INVESTOR_ORDER_PRICE_ARE_SHORTCUTS_ENABLED]
  },
  validate: [{ type: VALIDATE_ROUTE_ORDER_LIMIT_PRICE }]
});

// ----------- All fields -----------------------------------------
// ----------------------------------------------------------------

export const showOnTOFormCondition = {
  or: [
    {
      when: 'hiddenMode.type',
      is: 'create'
    },
    {
      when: 'hiddenMode.type',
      is: 'modify'
    }
  ]
};

export const firmAccount = createAccountField(fc, 'firmAccount', {
  query: ADVANCED_SELECT_QUERY_ENUM.VISIBLE_FIRM_ACCOUNTS_WATCH_QUERY,
  actions: {
    resolveProps: [ROUTE_ORDER_INVESTOR_ORDER_IS_SYMBOL_ENABLED]
  }
});

export const venue = createVenueField(fc, 'venue', {
  ...makeCommonRequiredProps({
    indicatorStyle: 'none'
  }),
  query: ADVANCED_SELECT_QUERY_ENUM.WATCH_All_VENUES_FOR_USER_WITH_DESTINATION_TYPES_QUERY,
  actions: {
    resolveProps: [ROUTE_ORDER_VENUE_QUERY_TO_USE]
  },
  label: 'Route To'
});

export const { timeInForce, gtdTime, gtdDate, gtdTimestamp, tifDuration } = createTIFFields(
  fc,
  'timeInForce',
  {
    actions: {
      resolveProps: [ROUTE_ORDER_INVESTOR_ORDER_IS_TIF_ENABLED]
    }
  }
);

export const strategy = fc.field('strategy', 'fixatdl-strategy').advancedSelectOptions<FIXatdlStrategyValue>({
  validate: [{ type: 'validateRouteOrderStrategy' }]
});

export const locate = createLocateField(fc, 'locate', {
  actions: {
    resolveProps: [ROUTE_ORDER_INVESTOR_ORDER_IS_LOCATE_ENABLED]
  }
});

export const enrichedLocate = createEnrichedField(locate, {
  groupName: 'Order'
});

export const strategyPreset = fc.field('strategyPreset', 'text-field').options<ITextField>({
  label: 'Strategy preset',
  name: 'strategyPreset',
  isDisabled: true
});

export const display = fc.field('display', 'text-field').options<ITextField>({
  label: 'Display',
  name: 'display',
  isDisabled: true
});

// ----------- Route Specific -------------------------------------
// ----------------------------------------------------------------

export const trader = createRoutableUsersField(fc, 'trader', {
  actions: {
    resolveProps: [ROUTE_ORDER_TRADER_ACTION]
  }
});

export const enrichedTrader = createEnrichedField(trader, {
  groupName: 'Order',
  condition: { when: 'venue.value', is: DestinationType.Trader }
});

export const customerNotes = createCustomerNotesField(fc, 'customerNotes', {});

export const enrichedCustomerNotes = createEnrichedField(customerNotes, {
  groupName: 'Details'
});

export const venueNotes = createVenueNotesField(fc, 'venueNotes');

export const tradeCurrency = createCurrencyField(fc, 'tradeCurrency', {
  label: 'Trade Currency',
  forceIsDisabled: true,
  isDisabled: true
});

export const enrichedTradeCurrency = createEnrichedField(tradeCurrency, {
  groupName: 'Order',
  condition: {
    or: [
      {
        when: 'hiddenFormInfo.type',
        is: 'route'
      },
      {
        when: 'hiddenFormInfo.type',
        is: 'modify'
      }
    ]
  }
});

export const settlement = createSettlementFields(
  fc,
  'settlementType',
  'settlementDate',
  {
    actions: {
      resolveProps: [ROUTE_ORDER_TRADING_ORDER_IS_SETTLEMENT_ENABLED]
    }
  },
  {
    actions: {
      resolveProps: [ROUTE_ORDER_TRADING_ORDER_IS_SETTLEMENT_ENABLED]
    }
  }
);

export const enrichedSettlement = createEnrichedField(settlement.settlementContainer.build(), {
  groupName: 'Settle'
});

export const orderTags = createOrderTagsField(fc, 'orderTags', [TagTypeEnum.InvestorOrder], {});

export const enrichedOrderTags = createEnrichedField(orderTags, {
  groupName: 'Order',
  colSpan: 3
});

// ----------- Create Specific -------------------------------------
// ----------------------------------------------------------------
export const tradingOrderCategory = fc.field('tradingOrderCategory', 'hidden-field');

// ----------- Helpful types --------------------------------------
// ----------------------------------------------------------------

export type InstrumentFormValue = InferFormValueFromFieldDefinition<typeof instrument>;
export type TimeInForceFormValue = InferFormValueFromFieldDefinition<typeof timeInForce>;
export type VenueFormValue = InferFormValueFromFieldDefinition<typeof venue>;
export type SideTypeFormValue = InferFormValueFromFieldDefinition<typeof sideType>;
