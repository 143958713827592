import { FORM_SAVE_TYPE } from '@oms/frontend-foundation';
import { ExchangesService } from '@app/data-access/services/reference-data/exchanges/exchanges.service';
import { FormBuilder, FORM_EVENT_TYPE } from '@oms/frontend-foundation';
import type { ExchangeFormInput, ExchangeFormOutput } from './exchange.form-common';
import {
  exchangeFormContract,
  type ExchangeFormContractType,
  type ExchangeFormValues
} from './exchange.form-contract';
import { getIntervalFromDateString, getDateStringFromInterval } from '@app/forms/common/utils/date.utils';

export const exchangeFormBuilder = FormBuilder.create<ExchangeFormInput, ExchangeFormOutput>('exchange-form')
  .contract<ExchangeFormContractType>(exchangeFormContract)
  .type('exchange')
  .sanitizer((s) =>
    s
      .input(async function sanitize(input, ctx) {
        if (!input.id) return;
        const service = ctx.container.resolve(ExchangesService);
        const exchange = await service.getById(input.id);
        if (!exchange) return;

        const formValues: Partial<ExchangeFormValues> = {
          id: exchange.id,
          longName: exchange.longName ?? undefined,
          exchangeType: exchange.exchangeType ?? undefined,
          countryId: {
            id: exchange.countryId ?? ''
          },
          mic: {
            id: exchange.micCode ?? ''
          },
          micLevel: exchange.micLevel ?? undefined,
          startTime: getDateStringFromInterval(exchange.startTime),
          endTime: getDateStringFromInterval(exchange.endTime),
          partialStartTime: getDateStringFromInterval(exchange.partialStartTime),
          partialEndTime: getDateStringFromInterval(exchange.partialEndTime),
          timezone: {
            id: exchange.timezone ?? ''
          },
          expiryOffset: exchange.expiryOffset ?? undefined,
          extendedExpiryOffset: exchange.extendedExpiryOffset ?? undefined
        };

        return formValues;
      })
      .output(function sanitize(formValues) {
        if (!formValues.id) return;
        if (!formValues.longName) return;
        if (!formValues.countryId) return;
        const output: ExchangeFormOutput = {
          id: formValues.id,
          longName: formValues.longName,
          exchangeType: formValues.exchangeType,
          countryId: formValues.countryId.id,
          micCode: formValues.mic?.id,
          micLevel: formValues.micLevel,
          startTime: getIntervalFromDateString(formValues.startTime ?? ''),
          endTime: getIntervalFromDateString(formValues.endTime ?? ''),
          partialStartTime: getIntervalFromDateString(formValues.partialStartTime ?? ''),
          partialEndTime: getIntervalFromDateString(formValues.partialEndTime ?? ''),
          timezone: formValues.timezone?.id ?? '',
          expiryOffset:
            typeof formValues.expiryOffset === 'string'
              ? parseInt(formValues.expiryOffset)
              : formValues.expiryOffset,
          extendedExpiryOffset:
            typeof formValues.extendedExpiryOffset === 'string'
              ? parseInt(formValues.extendedExpiryOffset)
              : formValues.extendedExpiryOffset
        };
        return output;
      })
  )
  .change(async (event, ctx) => {
    switch (event.type) {
      case FORM_EVENT_TYPE.MOUNT:
        break;
      case FORM_EVENT_TYPE.UNMOUNT:
        break;
      case FORM_EVENT_TYPE.VALUES_CHANGED: {
        break;
      }
      case FORM_EVENT_TYPE.SANITIZED_VALUES_CHANGED: {
        break;
      }
      case FORM_EVENT_TYPE.SUBMIT: {
        const service = ctx.container.resolve(ExchangesService);
        const formOutput = event.payload.output;
        const formSaveType = event.meta.formSaveType;
        const method = formSaveType === FORM_SAVE_TYPE.CREATE ? 'create' : 'update';
        return await service[method](formOutput);
      }
      case FORM_EVENT_TYPE.SUBMIT_FINISHED_SUCCESS:
        break;
      case FORM_EVENT_TYPE.SUBMIT_FINISHED_ERRORS:
        break;
    }
  });

export type ExchangeFormBuilderType = typeof exchangeFormBuilder;

export default exchangeFormBuilder;
