import React, { useCallback, useEffect, useState } from 'react';
import type { FieldProps, ICommonField } from '../../types';
import { FieldWrapper } from '../field-wrapper/field-wrapper';
import { Autocomplete } from '../../../popovers/autocomplete/popover.autocomplete';
import { Box, Flex } from '@oms/shared-frontend/ui-design-system';
import { useAdvancedSelectQuery } from '../advanced-select/advanced-select-query.hook';
import { useEnhancedFormApi } from '../../builder/helpers/form-api/form-api';
import { type Validator, useFormApi, type Field } from '@data-driven-forms/react-form-renderer';
import type { FORM_COMPONENT_TYPE } from '../../contracts';
import * as styles from './multi-input.css';
import type { IAdvancedSelectField } from '../advanced-select/advanced-select.types';
import type { ComboBoxItem } from '../../../combo-box/combo-box.types';
import clsx from 'clsx';

export type MultiInputOption = ComboBoxItem<string> & { subcomponent?: Field };

export type IMultiInputField<TValidator = Validator> = ICommonField<
  typeof FORM_COMPONENT_TYPE.MULTI_INPUT,
  {},
  TValidator
> & {
  options: MultiInputOption[];
};

export const MultiInput: React.FC<FieldProps<IAdvancedSelectField<MultiInputOption>>> = (props) => {
  const { fieldWrapperProps, autoCompleteProps } = useAdvancedSelectQuery(props);
  const [open, setOpen] = useState(false);
  const [subcomponent, setSubcomponent] = useState<Field>();

  const { renderForm } = useFormApi();
  const formApi = useEnhancedFormApi();

  useEffect(() => {
    const sub = formApi.get$({ values: true, fields: [props.name] }).subscribe(({ values }) => {
      if (!values[props.name]) {
        setSubcomponent(undefined);
        setOpen(false);
      }
    });

    return () => {
      sub.unsubscribe();
    };
  }, [formApi, props.name]);

  const handleIconClick = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const handleChange = useCallback(
    (value: MultiInputOption[]) => {
      if (open) {
        setOpen(false);
      }

      const subcomp = value[0]?.subcomponent;

      if (subcomp) {
        formApi.change(subcomp.name, undefined);
        subcomp.hideFormControls = true;

        setSubcomponent(subcomp);
      } else {
        if (subcomponent) {
          formApi.change(subcomponent.name, undefined);
        }
        setSubcomponent(undefined);
      }

      formApi.change(props.name, value[0]);
    },
    [formApi, open, props.name, subcomponent]
  );

  return (
    <Box>
      <FieldWrapper {...{ ...fieldWrapperProps, ...(subcomponent?.label && { label: subcomponent.label }) }}>
        <Flex
          className={clsx([
            styles.wrapper,
            {
              [styles.wrapperNonPrimary]: !props.isPrimaryField
            }
          ])}
        >
          <Box
            sx={{ width: 'full', height: 'auto' }}
            className={clsx({ [styles.wrapperPrimary]: props.isPrimaryField })}
          >
            <Box
              className={clsx({
                [styles.parentComponentDisplay]: !subcomponent,
                [styles.subcomponentDisplay]: subcomponent
              })}
            >
              <Autocomplete
                {...autoCompleteProps}
                open={open}
                onChange={handleChange}
                style={{ border: 'none' }}
              />
            </Box>
            <Box className={styles.subcomponentWrapper} sx={{ marginTop: props.isPrimaryField ? 1 : '-2' }}>
              {subcomponent && renderForm([subcomponent])}
            </Box>
          </Box>
          <Flex direction="column" justify={'center'}>
            <Box
              className={props.isPrimaryField ? styles.icon.primaryField : styles.icon.nonPrimaryField}
              onClick={handleIconClick}
            ></Box>
          </Flex>
        </Flex>
      </FieldWrapper>
    </Box>
  );
};
