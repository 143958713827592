import type { SummaryGridField } from '@oms/frontend-foundation';
import type {
  StrategyParameter,
  VisibleInvestorOrderInfoWithAllocationsFragment,
  VisibleTradingOrderInfoWithAllocationsFragment
} from '@oms/generated/frontend';
import { getDurationInMMSS } from '@app/forms/common/validators/validate-duration-input/validate-duration-input.validator';
import { mapInstrument } from '../mappers/map-instrument';
import { mapUser } from '../mappers/map-user';
import { mapSettleType } from '../mappers/map-settle-type';
import { mapOrderTagsToString } from '../mappers/map-order-tags';
import { get } from 'lodash';
import { AtdlFormats } from '@valstro/fixatdl-core';

const ORDER_SUMMARY_FIELDS_MAP = {
  id: { label: 'Order ID' },
  averagePrice: { label: 'Average price' },
  captureTimestamp: { label: 'Capture timestamp', format: 'datetime' },
  clientComplianceId: { label: 'Client compliance ID' },
  clientOrderId: { label: 'Client order ID', format: 'text' },
  createdTimestamp: { label: 'Created timestamp', format: 'datetime' },
  updatedTime: { label: 'Updated time', format: 'datetime' },
  lastExecutionTime: { label: 'Last execution time', format: 'datetime' },
  customerNotes: { label: 'Customer notes' },
  venueNotes: { label: 'Venue notes' },
  displayQuantity: { label: 'Display quantity', format: 'quantity' },
  enteredBy: { label: 'Entered By', mapper: mapUser },
  'enteredBy.email': { label: 'Entered by' },
  executedQuantity: { label: 'Executed quantity', format: 'quantity' },
  expiryDate: { label: 'Expiry date', format: 'date' },
  expiryDateTime: { label: 'Expiry dateTime', format: 'datetime' },
  expiryTime: { label: 'Expiry time', format: 'time' },
  fixMessage: { label: 'Fix message' },
  instrument: { label: 'Instrument', mapper: mapInstrument },
  'instrument.id': { label: 'Instrument ID' },
  'instrument.mappings.displayCode': { label: 'Symbol' },
  investorAccount: { label: 'Investor account' },
  'investorAccount.id': { label: 'Account ID' },
  'investorAccount.name': { label: 'Account name' },
  'investorAccount.accountType': { label: 'Account type' },
  'tradingAccount.name': { label: 'Account name' },
  'tradingAccount.accountType': { label: 'Account type' },
  leavesQuantity: { label: 'Leaves quantity', format: 'quantity' },
  limitPrice: { label: 'Limit price' },
  locate: { label: 'Locate' },
  minQuantity: { label: 'Min quantity', format: 'quantity' },
  notionalAmount: { label: 'Notional amount' },
  openQuantity: { label: 'Open quantity', format: 'quantity' },
  orderCharges: { label: 'Order charges' },
  commissionRateType: { label: 'Commission rate type' },
  commissionRateValue: { label: 'Commission rate value' },
  commissionAmount: { label: 'Commission amount', format: 'price' },
  feesTotal: { label: 'Fees total', format: 'price' },
  marketChargesTotal: { label: 'Market charges total', format: 'price' },
  orderComments: { label: 'Order comments' },
  orderEntryType: { label: 'Order entry type' },
  orderTags: { label: 'Order tags', mapper: mapOrderTagsToString },
  orderType: { label: 'Order type' },
  owner: { label: 'Owner', mapper: mapUser },
  parentTradingOrderId: { label: 'Parent trading order ID' },
  quantity: { label: 'Quantity', format: 'quantity' },
  'representativeCode.code': { label: 'Representative code' },
  sendingDesk: { label: 'Sending desk' },
  settleCurrency: { label: 'Settle currency' },
  settleDate: { label: 'Settle date', format: 'date' },
  settleType: { label: 'Settle type', mapper: mapSettleType },
  sideType: { label: 'Side' },
  status: { label: 'Status' },
  stopPrice: { label: 'Stop price' },
  timeInForce: { label: 'Time in force' },
  tifDuration: { label: 'TIF Duration', mapper: getDurationInMMSS },
  tradeCurrency: { label: 'Trade currency' },
  transmittedTimestamp: { label: 'Transmitted timestamp', format: 'datetime' },
  updatedTimestamp: { label: 'Updated timestamp', format: 'datetime' },
  receivedTimestamp: { label: 'Received timestamp', format: 'datetime' },
  underlyingAccount: { label: 'Underlying account' },
  validatedTimestamp: { label: 'Validated timestamp', format: 'datetime' },
  workingQuantity: { label: 'Working quantity', format: 'quantity' },
  capacity: { label: 'Capacity' },
  venueStrategy: { label: 'Venue strategy' },
  destinationName: { label: 'Route to' },
  'targetExchange.id': { label: 'Target exchange' },
  workflow: { label: 'Workflow' },
  fixWorkflowId: { label: 'FIX workflow ID' },
  todayExecutedQuantity: { label: "Today's executed quantity" },
  todayAveragePrice: { label: "Today's average price" },
  category: { label: 'Category' }
} as const;

export type OrderSummaryFieldName = keyof typeof ORDER_SUMMARY_FIELDS_MAP;

const orderSummaryFields = (fieldNames: OrderSummaryFieldName[]): SummaryGridField[] => {
  return fieldNames.map<SummaryGridField>((fieldName) => ({
    fieldName,
    ...ORDER_SUMMARY_FIELDS_MAP[fieldName]
  }));
};

const orderSummaryValues = (
  fieldNames: OrderSummaryFieldName[],
  order:
    | Partial<VisibleInvestorOrderInfoWithAllocationsFragment>
    | Partial<VisibleTradingOrderInfoWithAllocationsFragment>
) => {
  return [
    fieldNames.reduce((acc, fieldName) => {
      // Using const on the field name definition ensures we get type protection when specifying
      // the fields as a user, but using explicit types here under the hood is way to complex to appease
      // typescript :/ so I'm treating it as an explicit any
      const fieldDef = ORDER_SUMMARY_FIELDS_MAP[fieldName] as Record<string, any>;
      const mapper = fieldDef.mapper;
      // Using lodash's get instead of reimplementing nested fields with . again
      const fieldValue = get(order, fieldName, undefined);
      if (fieldValue === null || fieldValue === undefined) {
        return { ...acc, [fieldName]: null };
      }
      return { ...acc, [fieldName]: (mapper ? mapper(fieldValue) : '') || String(fieldValue) };
    }, {} as Record<string, string>)
  ];
};

export const orderSummaryData = (
  label: string,
  fields: (keyof typeof ORDER_SUMMARY_FIELDS_MAP)[],
  order:
    | Partial<VisibleInvestorOrderInfoWithAllocationsFragment>
    | Partial<VisibleTradingOrderInfoWithAllocationsFragment>
) => {
  return {
    label,
    value: orderSummaryValues(fields, order),
    fields: orderSummaryFields(fields)
  };
};

export const orderStrategyParametersSummaryData = (
  label: string,
  order: VisibleTradingOrderInfoWithAllocationsFragment
) => {
  const strategy = order.strategy;
  const strategyName = strategy?.name || 'strategy-name';
  const fields: SummaryGridField[] = [
    {
      fieldName: strategyName,
      label: 'Strategy'
    }
  ];
  const data: Record<string, any> = { [strategyName]: strategy?.uirep || strategyName };

  (strategy?.parameters || []).forEach((param, index) => {
    const { name, label, value, uiRep } = param as StrategyParameter;
    const fieldValue = uiRep ?? AtdlFormats.coerceEmpty(value) ?? '-';

    const fieldName = name || `${strategyName}-${index}`;
    fields.push({
      fieldName,
      label: label || fieldName
    });
    data[fieldName] = fieldValue;
  }, [] as SummaryGridField[]);

  return { label, fields, value: [data] };
};
