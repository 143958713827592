import {
  FormContract,
  FieldDefinition,
  type InferFormValuesFromFormContract,
  FieldContract
} from '@oms/frontend-foundation';
import type { EnableQuoteFormSchemaInput } from './enable-quote.form-builder.config';
import { type AdditionalFormFieldUnion } from '@app/forms/form-builder/mappers/form.mappers';
import {
  makeInstrumentCommonProps,
  makeCommonRequiredProps
} from '@app/forms/common/fields/shared-field-props';
import { createSymbolField } from '@app/forms/common/fields/symbol-field/symbol.field.common';
import { yesNoOptions } from './enable-quote.form.util';

// Common
export type EnableQuoteFormInput = EnableQuoteFormSchemaInput;
export type EnableQuoteFormOutput = {
  instrument: string;
  mmStatus: boolean;
};

// Fields
const ofc = FieldContract.create<EnableQuoteFormOutput, AdditionalFormFieldUnion>();

export const instrumentField = createSymbolField(ofc, 'instrument', {
  ...makeInstrumentCommonProps({
    queryType: 'simple',
    label: 'Stock',
    isRequired: true
  }),
  wrapperSx: { flex: 1 }
});

const mmStatusField = ofc.field('mmStatus', 'advanced-select').advancedSelectOptions<boolean>({
  ...makeCommonRequiredProps(),
  label: 'MM Status',
  options: [...yesNoOptions],
  wrapperSx: { flex: 1 }
});

// Form contract
export const enableQuoteFormContract = FormContract.create<EnableQuoteFormOutput>()
  .fields({
    instrument: instrumentField,
    mmStatus: mmStatusField
  })
  .schema((f) => ({
    fields: [
      FieldDefinition.box('wrapper', [f.instrument, f.mmStatus], {
        sx: {
          display: 'flex',
          flexDirection: 'row',
          gap: 4
        }
      })
    ]
  }))
  .template('simple', {
    showCancelButton: true,
    submitLabel: 'Submit',
    formFieldsSx: { px: 4, pb: 2, marginTop: 4 }
  });

export type EnableQuoteFormContractType = typeof enableQuoteFormContract;

export type EnableQuoteFormValues = InferFormValuesFromFormContract<EnableQuoteFormContractType>;
