import { type Action } from '@app/actions/types/action.types';
import { type FORM_COMPONENT_TYPE } from '@app/forms/form-builder/common/form.contracts';
import { type FORM_ENUM } from '@app/generated/common';
import { FORM_MAP } from '@app/generated/mappers';
import { type Validator } from '@data-driven-forms/react-form-renderer';
import {
  type AnyFieldDefinition,
  type AnyRecord,
  type FormContract,
  FormRenderer,
  type FormRendererProps,
  useFieldApi,
  type ICommonField,
  FORM_SAVE_TYPE,
  type FormSaveType
} from '@oms/frontend-foundation';
import { type FieldProps } from '@oms/frontend-foundation';
import { useCallback, useMemo, memo, type FC } from 'react';
import { container } from 'tsyringe';
import isEqual from 'lodash/isEqual';
import type { Prettify } from '@oms/shared/util-types';
import type { ActionCommands } from '@app/actions/commands/command.registry.types';
import { ACTION_COMMAND_SCHEMA_REGISTRY } from '@app/actions/commands/command.registry';

export type ActionCommandValue = Prettify<
  Partial<Pick<Action, 'payload'>> & { form?: keyof typeof FORM_ENUM; commandId: ActionCommands }
>;

export interface IActionCommandField<TValidator = Validator>
  extends ICommonField<typeof FORM_COMPONENT_TYPE.ACTION_COMMAND, ActionCommandValue, TValidator> {}

export const ActionCommandField: FC<FieldProps<IActionCommandField>> = (props) => {
  const field = useFieldApi<IActionCommandField, ActionCommandValue>(props);
  const {
    input: { value, onChange }
  } = field;
  const { form, payload, commandId } = value;

  const formBuilderProps:
    | FormRendererProps<
        AnyRecord,
        AnyRecord,
        FormContract<AnyRecord, Record<string, AnyFieldDefinition>>,
        AnyRecord
      >
    | undefined = useMemo(() => {
    const formBuilder = form ? FORM_MAP[form] : undefined;

    if (!formBuilder) return undefined;

    const { schema, formBuilderId, template, formType, templateProps } = formBuilder.build();

    return {
      container: container,
      schema,
      formId: formBuilderId,
      formType: formType,
      template,
      templateProps: {
        ...templateProps,
        sx: { ...(templateProps?.sx || {}), position: 'relative' },
        as: 'div'
      }
    };
  }, [form]);

  const onValuesChanging: FormRendererProps['onValuesChanging'] = useCallback(
    (ctx: Parameters<NonNullable<FormRendererProps['onValuesChanging']>>[0]) => {
      const { formValues, prevFormValues } = ctx;
      const sameFormVals = isEqual(formValues, prevFormValues);

      if (sameFormVals) {
        return;
      }

      const matchingCommandSchema = ACTION_COMMAND_SCHEMA_REGISTRY[commandId];

      if (!matchingCommandSchema) {
        throw new Error(`No schema found for command: ${commandId}`);
      }

      const newValue: ActionCommandValue = {
        form,
        commandId,
        payload: formValues
      };

      onChange(newValue);
    },
    [form, commandId, onChange]
  );

  return formBuilderProps ? (
    <MemoFormRenderer
      {...formBuilderProps}
      initialValues={payload || {}}
      onValuesChanging={onValuesChanging}
      formSaveType={FORM_SAVE_TYPE.TEMPLATE as FormSaveType}
    />
  ) : null;
};

const MemoFormRenderer: FC<FormRendererProps> = memo(
  (props) => <FormRenderer {...props} />,
  (prev, curr) => prev.formType === curr.formType
);
