import {
  OrderType,
  type VisibleInvestorOrderInfoWithAllocationsFragment,
  type GetOrdersQueryVariables,
  VisibleInvestorOrdersOrderBy,
  OrderSide
} from '@oms/generated/frontend';
import { singleton, inject } from 'tsyringe';
import {
  type IServerSideSubscriptionService,
  ServerSideSubscriptionsObservable
} from '../server-side-subscriptions/server-side-subscriptions.service';
import {
  IInvestorOrderDataSource,
  InvestorOrderSubcriptionDatasource
} from './investor-orders.subscriptions.service';
import { getVisibleInvestorOrderFilter } from '@app/data-access/services/trading/data-access.trading.utils';
import type Decimal from 'decimal.js';
import { type MatchedInvestorOrdersPlacement } from '@app/forms/form-builder/fields/matched-investor-orders/matched-investor-orders-checkbox-group';
import { AuthService } from '../../system/auth/auth.service';

type CompatibleAndIncompatibleOrders = Record<string, VisibleInvestorOrderInfoWithAllocationsFragment[]>;

export type TradingOrderValues = {
  limitPrice?: number;
  instrumentId: string;
  side: OrderSide;
  orderIds?: string[];
};

type QueryInput = Pick<TradingOrderValues, 'instrumentId' | 'side'>;

type IMatchableInvestorOrderSubscription = IServerSideSubscriptionService<
  VisibleInvestorOrderInfoWithAllocationsFragment,
  GetOrdersQueryVariables
>;

@singleton()
export class MatchableInvestorOrdersSubscriptionService implements IMatchableInvestorOrderSubscription {
  constructor(
    @inject(InvestorOrderSubcriptionDatasource)
    private investorOrderSubscriptionsDatasource: IInvestorOrderDataSource,
    @inject(AuthService)
    private authService: AuthService
  ) {}

  public query$(variables?: GetOrdersQueryVariables) {
    return new ServerSideSubscriptionsObservable<VisibleInvestorOrderInfoWithAllocationsFragment>(
      this.investorOrderSubscriptionsDatasource,
      variables
    );
  }

  public queryMatchableInvestorOrders$({ instrumentId, side }: QueryInput) {
    return this.query$(this.getMappableOrdersQueryVariables({ instrumentId, side }));
  }

  private getMappableOrdersQueryVariables({
    side,
    instrumentId
  }: QueryInput): Parameters<MatchableInvestorOrdersSubscriptionService['query$']>[0] {
    if (!side) throw new Error('Cannot match IOs without a side');
    if (!instrumentId) throw new Error('Cannot match IOs without instrument id');

    const ownerId = this.authService.getUserId();
    if (!ownerId) throw new Error('Cannot match IOs without owner id');

    return {
      filter: getVisibleInvestorOrderFilter(instrumentId, side, ownerId),
      orderBy:
        side === OrderSide.Buy
          ? VisibleInvestorOrdersOrderBy.LimitPriceAsc
          : VisibleInvestorOrdersOrderBy.LimitPriceDesc
    };
  }

  private isOrderSellingSide(side: OrderSide): boolean {
    return [OrderSide.Sell].includes(side);
  }
  private isOrderBuyingSide(side: OrderSide): boolean {
    return [OrderSide.Buy].includes(side);
  }

  private isPriceMorePassive(orderSide: OrderSide, priceOne: Decimal, priceTwo: Decimal) {
    if (this.isOrderBuyingSide(orderSide) && priceOne.greaterThanOrEqualTo(priceTwo)) {
      return true;
    } else if (this.isOrderSellingSide(orderSide) && priceOne.lessThan(priceTwo)) {
      return true;
    }
    return false;
  }

  private isCompatibleOrder(
    io: Pick<
      VisibleInvestorOrderInfoWithAllocationsFragment,
      'limitPrice' | 'orderType' | 'side' | 'openQuantity'
    >,
    to: TradingOrderValues,
    placement: MatchedInvestorOrdersPlacement
  ): boolean {
    if (!io.openQuantity) return false;

    // market IOs are always compatible
    if (io.orderType === OrderType.Market) {
      return true;
    }

    if (io.side && io.orderType === OrderType.Limit) {
      if (placement === 'montage') {
        return Boolean(io.limitPrice);
      }

      if (placement === 'trading-order-entry' && to) {
        return true;
      }

      throw new Error('placement must be either "montage" or "trading-order-entry"');
    }

    return false;
  }

  public clientSideFilterCompatibleAndIncompatibleOrders(
    orders: VisibleInvestorOrderInfoWithAllocationsFragment[],
    toValues: TradingOrderValues,
    placement: MatchedInvestorOrdersPlacement
  ): CompatibleAndIncompatibleOrders {
    return orders.reduce<{
      compatibleOrders: VisibleInvestorOrderInfoWithAllocationsFragment[];
      incompatibleOrders: VisibleInvestorOrderInfoWithAllocationsFragment[];
    }>(
      (accum, order) => {
        if (this.isCompatibleOrder(order, toValues, placement)) {
          accum.compatibleOrders.push(order);

          accum.compatibleOrders.sort((a, b) => {
            if (a.orderType === OrderType.Market && b.orderType === OrderType.Limit) {
              return -1;
            }

            if (a.orderType === OrderType.Limit && b.orderType === OrderType.Market) {
              return 1;
            }

            return 0;
          });

          accum.compatibleOrders.sort((a, b) => {
            if (a.orderType === b.orderType) {
              if (a.validatedTimestamp < b.validatedTimestamp) {
                return -1;
              }
              if (b.validatedTimestamp < a.validatedTimestamp) {
                return 1;
              }
            }
            return 0;
          });
        } else {
          accum.incompatibleOrders.push(order);
        }
        return accum;
      },
      { compatibleOrders: [], incompatibleOrders: [] }
    );
  }
}
