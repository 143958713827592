import { t } from '@oms/codegen/translations';
import { type NotificationResourceTemplate, NotificationResourceTemplates } from '@oms/generated/frontend';

const MINUTES_PER_HOUR = 60;
const MINUTES_PER_DAY = 1440;

const justNowLabel = t('app.notifications.grid.elapsedTime.justNow');
const oneMinAgo = t('app.notifications.grid.elapsedTime.oneMinAgo');
const someMinsAgo = (minutes: number) => t('app.notifications.grid.elapsedTime.someMinAgo', { minutes });
const oneHourAgo = t('app.notifications.grid.elapsedTime.oneHourAgo');
const someHoursAgo = (hours: number) => t('app.notifications.grid.elapsedTime.someHoursAgo', { hours });
const oneDayAgo = t('app.notifications.grid.elapsedTime.oneDayAgo');
const someDaysAgo = (days: number) => t('app.notifications.grid.elapsedTime.someDaysAgo', { days });

export type OmsNotification<TMetadata extends Record<string, any> = Record<string, any>> = Omit<
  NotificationResourceTemplate,
  '__typename'
> & {
  resourceId?: string;
  isSeen: boolean;
  isRead: boolean;
  createdAt: string;
  templateIdentifier?: string;
  metadata?: TMetadata;
};

export const getElapsedTime = (createdAt: string) => {
  const pastTime = new Date(createdAt);
  const currentTime = new Date();
  const elapsedMinutes = Math.floor((currentTime.getTime() - pastTime.getTime()) / 60000);
  if (elapsedMinutes < 1) {
    return justNowLabel;
  } else if (elapsedMinutes < MINUTES_PER_HOUR) {
    return elapsedMinutes === 1 ? oneMinAgo : someMinsAgo(elapsedMinutes);
  } else if (elapsedMinutes > MINUTES_PER_HOUR && elapsedMinutes < MINUTES_PER_DAY) {
    const hoursAgo = Math.floor(elapsedMinutes / MINUTES_PER_HOUR);
    return hoursAgo === 1 ? oneHourAgo : someHoursAgo(hoursAgo);
  } else {
    const daysAgo = Math.floor(elapsedMinutes / MINUTES_PER_DAY);
    return daysAgo === 1 ? oneDayAgo : someDaysAgo(daysAgo);
  }
};

export const getDisplayTemplateName = (templateName: string) => {
  switch (templateName) {
    case NotificationResourceTemplates.IoExecution:
      return 'IO Execution';
    case NotificationResourceTemplates.IoNew:
      return 'New Order';
    case NotificationResourceTemplates.PendingModification:
      return 'Pending Modification';
    case NotificationResourceTemplates.FailedOrder:
      return 'Failed Order';
    case NotificationResourceTemplates.PwDismissible:
      return 'PW Dismissible';
    case NotificationResourceTemplates.PwPersist:
      return 'PW Persist';
    case NotificationResourceTemplates.ApproachingDailyAccountLimit:
      return 'Approaching Daily Account Limit';
    case NotificationResourceTemplates.PendingCancellation:
      return 'Pending Cancellation';
    case NotificationResourceTemplates.FailedOrderModification:
      return 'Failed Order Modification';
    case NotificationResourceTemplates.TradingOrderUnsolicitedModifyRejected:
      return 'Trading Order Unsolicited Modification Rejected';
    case NotificationResourceTemplates.TradingOrderUnsolicitedModifyAccepted:
      return 'Trading Order Unsolicited Modification Accepted';
    case NotificationResourceTemplates.TradingOrderUnsolicitedCancel:
      return 'Trading Order Unsolicited Cancel';
    default:
      return '';
  }
};
