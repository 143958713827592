import { openBulkRepairTrade, openTradeModify } from '@app/generated/sdk';
import type { ActionContext, ActionDefFactory, ActionComponentConfig } from '@oms/frontend-vgrid';
import { RepairCategory, type TradeRepairRequestFragment } from '@oms/generated/frontend';
import { PROCESS_ID } from '@valstro/workspace';
import { openMessageDialog } from '@app/common/dialog/dialog.common';
import { TradeModifyEntryType } from '@app/widgets/trading/trade-modify/trade-modify.form-common';

export const repairCreateTradeActionOnChange = (
  ctx: ActionContext<TradeRepairRequestFragment, ActionComponentConfig<TradeRepairRequestFragment>>
) => {
  const { lifecycle, data, workspace } = ctx;

  // Repairs can only apply to RepairCategory.TradeCreate and RepairCategory.TradeModify
  const repairableRows = data.filter(
    (selectedRow) =>
      selectedRow.category === RepairCategory.TradeCreate ||
      selectedRow.category === RepairCategory.TradeModify
  );
  // Collect the row's ids.
  const tradeRepairRequestIds = repairableRows.map((row) => row.id || '');

  ctx.notify({ isDisabled: repairableRows.length === 0 });

  if (lifecycle === 'change') {
    if (repairableRows.length === 0) {
      return;
    }

    if (repairableRows.length === 1) {
      // Only 1 row selected. "Regular" repair.
      const id = tradeRepairRequestIds[0];
      openTradeModify(PROCESS_ID.LEADER, {
        windowId: `trade-repair-${id}`,
        form: {
          formId: `trade-repair-form-${id}`,
          input: {
            id,
            entryType: TradeModifyEntryType.TradeRepair
          },
          triggerValidationOnOpen: true
        },
        title: 'Repair trade'
      }).catch((e) => {
        openMessageDialog(`Error: ${e}`, workspace).catch(console.error);
        console.error(e);
      });
    } else {
      // Multiple rows selected. "Bulk" repair.
      openBulkRepairTrade(PROCESS_ID.LEADER, {
        windowId: `trade-repair`,
        form: {
          formId: `trade-repair-form`,
          input: {
            tradeRepairRequestIds: tradeRepairRequestIds
          }
        },
        title: 'Repair trades'
      }).catch((e) => {
        openMessageDialog(`Error: ${e}`, workspace).catch(console.error);
        console.error(e);
      });
    }
  }
};

export const repairCreateTradeAction: ActionDefFactory<TradeRepairRequestFragment> = (builder) =>
  builder
    .name('repair_trade')
    .toolbar((t) =>
      t.component('action-button').id('repair_trade_button').location('HorizontalToolbarRight').props({
        isDisabled: true,
        content: 'Repair'
      })
    )
    .menu((m) => m.name('Repair').visible(({ rowData }) => !!rowData))
    .onChange<ActionComponentConfig<TradeRepairRequestFragment>>(repairCreateTradeActionOnChange);
