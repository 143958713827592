import {
  FormBuilder,
  type ComboBoxItem,
  FORM_EVENT_TYPE,
  FORM_RENDERER_EVENT_TYPE
} from '@oms/frontend-foundation';
import { type OrderFormInput, type CreateOrderMutationFormOutput } from './example.contracts';
import {
  orderFormContract,
  type OrderFormContractType,
  type OrderContractFormValues
} from './example.form-contract';
import { type ExampleTagEntity } from './example.form-field.tags.select-query';

export const orderFormBuilder = FormBuilder.create<OrderFormInput, CreateOrderMutationFormOutput>(
  'order-form'
)
  .contract<OrderFormContractType>(orderFormContract)
  .sanitizer((s) =>
    s
      .input(function sanitize(incomingValues) {
        if (!incomingValues.intrument) return;
        if (!incomingValues.quantity) return;
        if (!incomingValues.side) return;
        if (!incomingValues.tags) return;

        const formValues: OrderContractFormValues = {
          intrumentId: incomingValues.intrument
            ? {
                id: incomingValues.intrument.id
              }
            : undefined,
          quantity: incomingValues.quantity,
          side: incomingValues.side,
          notes: incomingValues.notes,
          tagIds: incomingValues.tags.map((t) => {
            const item: ComboBoxItem<ExampleTagEntity> = {
              type: 'item',
              id: t.id,
              label: t.name,
              value: t
            };

            return item;
          })
        };
        return formValues;
      })
      .output(function sanitize(formValues) {
        if (!formValues.intrumentId) return;
        if (!formValues.quantity) return;
        if (!formValues.side) return;
        if (!formValues.tagIds) return;
        const output: CreateOrderMutationFormOutput = {
          intrumentId: formValues.intrumentId.id,
          notes: formValues.notes || '',
          quantity: Number(formValues.quantity),
          side: formValues.side,
          tagIds: formValues.tagIds.map((t) => t.id)
        };
        return output;
      })
  )
  .change((event, ctx) => {
    switch (event.type) {
      case FORM_EVENT_TYPE.MOUNT:
        break;
      case FORM_EVENT_TYPE.UNMOUNT:
        break;
      case FORM_EVENT_TYPE.SUBMIT:
        ctx.notify({
          type: FORM_RENDERER_EVENT_TYPE.SET_FEEDBACK,
          payload: { feedback: [{ code: 'error', level: 'Error', message: 'Failed to submit form' }] }
        });
        break;
      case FORM_EVENT_TYPE.VALUES_CHANGED:
        break;
    }
  });

export type OrderFormBuilderType = typeof orderFormBuilder;

export default orderFormBuilder;
