import { wrapEach } from '@oms/shared/util';
import type { Named } from '@oms/shared/util-types';
import type {
  ConditionDefinition,
  Validator as DdfValidator,
  Validator
} from '@data-driven-forms/react-form-renderer';
import type { AnyRecord } from '../../common/type.helpers';
import type { CombinedFormFieldUnion, Field, IAnyField, IExtractedField, IFieldType } from '../types';
import type { IHeadingField } from '../components/heading/heading.type';
import type { IBoxField } from '../components/box/box.component';
import type { ISubmitButtonField } from '../components/submit-button/submit-button.component';
import type { IResetButtonField } from '../components/reset-button/reset-button.component';
import type { ISimpleGridField } from '../components/simple-grid/simple-grid.type';
import { FORM_COMPONENT_TYPE } from '../contracts';
import { type IRunwayField } from '../components/runway/runway.component';
import { type IAdvancedMultiSelectField } from '../components/advanced-select/advanced-multi-select.types';
import { type IAdvancedSelectField } from '../components/advanced-select/advanced-select.types';
import { type IFormBuilderFeedbackField } from '../components/form-builder-feedback/form-builder-feedback.component';

export type AnyFieldDefinition = FieldDefinition<any, any, any>;
export type AnyFieldDefinitions = Record<string, AnyFieldDefinition>;

/**
 * Infer the form value from a FieldDefinition instance
 * Note: The "form value" is the value in the dataType of the form that the form's state will hold
 */
export type InferFormValueFromFieldDefinition<T extends AnyFieldDefinition> = ReturnType<
  T['_getDerivedFormValue']
>;

/**
 * Helper type to manually type a field definition JUST using TS
 */
export type FieldDefinitionType<
  TOutputContract extends AnyRecord,
  K extends keyof TOutputContract,
  C extends IFieldType<CombinedFormFieldUnion<TOutputContract[K], DdfValidator, undefined>>
> = FieldDefinition<
  K,
  C,
  IExtractedField<CombinedFormFieldUnion<TOutputContract[K], DdfValidator, undefined>, C>
>;

/**
 * Helper type to manually type a field definition JUST using TS with custom fields
 */
export type ExtendedFieldDefinitionType<
  TCustomFields extends IAnyField,
  TOutputContract extends AnyRecord,
  K extends keyof TOutputContract,
  C extends IFieldType<CombinedFormFieldUnion<TOutputContract[K], DdfValidator, TCustomFields>>
> = FieldDefinition<
  K,
  C,
  IExtractedField<CombinedFormFieldUnion<TOutputContract[K], DdfValidator, TCustomFields>, C>
>;

// TODO: Note, this maybe a better approach to the above type AND other types with lots of generic arguments
// TODO: But would require a lot of refactoring. Leaving this here for now for future reference
// export type ExtendedFieldDefinitionTypeUsingObjectSyntax<
//   TOptions extends {
//     customFields: IAnyField;
//     outputContract: AnyRecord;
//     key: keyof TOptions['outputContract'];
//     component: IFieldType<
//       CombinedFormFieldUnion<
//         TOptions['outputContract'][TOptions['key']],
//         DdfValidator,
//         TOptions['customFields']
//       >
//     >;
//   },
//   TField extends IAnyField = IExtractedField<
//     CombinedFormFieldUnion<
//       TOptions['outputContract'][TOptions['key']],
//       DdfValidator,
//       TOptions['customFields']
//     >,
//     TOptions['component']
//   >
// > = FieldDefinition<TOptions['key'], TOptions['component'], TField>;

export type ModifiedFieldForOptions<T> = Partial<
  Omit<T, 'condition' | 'component'> & {
    condition?: ConditionDefinition | string;
  }
>;

/**
 * Field Definition Builder
 * Used to create a field definition that's strongly typed
 *
 * Note: This class is unlikely to be used directly. Instead, use FieldContract
 * @see FieldContract
 */
export class FieldDefinition<K, C, T extends IAnyField> {
  constructor(public name: K, public component: C, public fieldDef: T) {
    this.fieldDef = this._mergeFieldDef(fieldDef);
  }

  /**
   * Creates a new FieldDefinition by creating a new instance that holds the field definition type.
   *
   * @param fieldDef - Partial field definition
   * @returns A new FieldDefinition
   */
  public options<TCustom extends T>(fieldDef: ModifiedFieldForOptions<TCustom>) {
    const nextFieldDef = this._mergeFieldDef(fieldDef);
    return new FieldDefinition<K, C, T>(this.name, this.component, nextFieldDef);
  }

  /**
   * Creates a new FieldDefinition for Advanced Selects by creating a new instance that holds the field definition type.
   *
   * @param fieldDef - Partial field definition
   * @returns A new FieldDefinition
   */
  public advancedSelectOptions<
    TValue = unknown,
    TQueryProps extends AnyRecord = AnyRecord,
    TValidator = Validator
  >(fieldDef: ModifiedFieldForOptions<IAdvancedSelectField<TValue, TQueryProps, TValidator>>) {
    if (this.component === 'advanced-multi-select') {
      throw new Error(
        'Cannot call advancedSelectOptions on an advanced-multi-select field, please use `advancedSelectMultiOptions`'
      );
    }
    const nextFieldDef = this._mergeFieldDef(fieldDef);
    return new FieldDefinition<
      K,
      typeof FORM_COMPONENT_TYPE.ADVANCED_SELECT,
      IAdvancedSelectField<TValue, TQueryProps, TValidator>
    >(this.name, this.component as typeof FORM_COMPONENT_TYPE.ADVANCED_SELECT, nextFieldDef);
  }

  /**
   * Creates a new FieldDefinition for Multi Advanced Selects by creating a new instance that holds the field definition type.
   *
   * @param fieldDef - Partial field definition
   * @returns A new FieldDefinition
   */
  public advancedSelectMultiOptions<
    TValue = unknown,
    TQueryProps extends AnyRecord = AnyRecord,
    TValidator = Validator
  >(fieldDef: ModifiedFieldForOptions<IAdvancedMultiSelectField<TValue, TQueryProps, TValidator>>) {
    if (this.component === 'advanced-select') {
      throw new Error(
        'Cannot call advancedSelectMultiOptions on an advanced-select field, please use `advancedSelectOptions`'
      );
    }
    const nextFieldDef = this._mergeFieldDef(fieldDef);
    return new FieldDefinition<
      K,
      typeof FORM_COMPONENT_TYPE.ADVANCED_MULTI_SELECT,
      IAdvancedMultiSelectField<TValue, TQueryProps, TValidator>
    >(this.name, FORM_COMPONENT_TYPE.ADVANCED_MULTI_SELECT, nextFieldDef);
  }

  /**
   * Merges current component & name into the field definition
   *
   * @private
   * @param fieldDef - Partial field definition
   * @returns - A new FieldDefinition with the merged field definition
   */
  private _mergeFieldDef<TCustom>(fieldDef: TCustom) {
    return {
      ...this.fieldDef,
      ...fieldDef,
      name: this.name,
      component: this.component
    };
  }

  /**
   * Builds the actual field definition for DDF
   *
   * @returns - The actual ddf field definition
   */
  public build() {
    return this.fieldDef;
  }

  /* @internal */
  public _getDerivedFormValue(): Required<T>['initialValue'] {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return undefined as Required<T>['initialValue'];
  }

  // Static ---------- /

  public static heading(
    label: string,
    options?: Omit<IHeadingField, 'name' | 'component' | 'label'> & Partial<Named>
  ): IHeadingField {
    const {
      name = FieldDefinition.generateAutoFieldName(label, 'heading'),
      headingType = 'baseB',
      ...rest
    } = options ?? {};
    return {
      component: 'heading',
      name,
      label,
      headingType,
      ...rest
    };
  }

  public static box<V, TValidator = DdfValidator, TCustomFields extends IAnyField | undefined = undefined>(
    name: string,
    fields: Field<V, TValidator, TCustomFields>[],
    options?: Omit<IBoxField, 'name' | 'component' | 'fields'> & Partial<Named>
  ): IBoxField {
    const field = {
      component: 'box',
      fields: wrapEach(fields),
      name,
      ...options
    } as IBoxField;

    return field;
  }

  public static runway<V, TValidator = DdfValidator, TCustomFields extends IAnyField | undefined = undefined>(
    name: string,
    fields: Field<V, TValidator, TCustomFields>[],
    options?: Omit<IRunwayField, 'name' | 'component' | 'fields'> & Partial<Named>
  ): IRunwayField {
    const field = {
      component: 'runway',
      fields: wrapEach(fields),
      name,
      ...options
    } as IRunwayField;

    return field;
  }

  public static formFeedback(
    name: string,
    options?: Omit<IFormBuilderFeedbackField, 'name' | 'component'> & Partial<Named>
  ): IFormBuilderFeedbackField {
    return {
      component: 'form-builder-feedback',
      name,
      ...options
    };
  }

  public static submit(
    name: string,
    options?: Omit<ISubmitButtonField, 'name' | 'component'> & Partial<Named>
  ): ISubmitButtonField {
    return {
      component: 'submit-button',
      name,
      ...options
    };
  }

  public static reset(
    name: string,
    options?: Omit<IResetButtonField, 'name' | 'component'> & Partial<Named>
  ): IResetButtonField {
    return {
      component: 'reset-button',
      name,
      ...options
    };
  }

  public static simpleGrid<
    V,
    TValidator = DdfValidator,
    TCustomFields extends IAnyField | undefined = undefined
  >(
    name: string,
    columns: ISimpleGridField['columns'],
    fields: Field<V, TValidator, TCustomFields>[],
    options?: Omit<ISimpleGridField, 'name' | 'component' | 'fields' | 'columns'> & Partial<Named>
  ): ISimpleGridField {
    const field = {
      component: 'simple-grid',
      fields: wrapEach(fields),
      name,
      columns,
      ...options
    } as ISimpleGridField;

    return field;
  }

  private static generateAutoFieldName(label: string, descriptor?: string): string {
    return `${label}${descriptor ? ` ${descriptor}` : ''}`.toLowerCase().replace(/\s/g, '-');
  }
}
