import { componentTypes as componentTypesBase } from '@data-driven-forms/react-form-renderer';

export const FORM_COMPONENT_TYPE = {
  ...componentTypesBase,
  HEADING: 'heading',
  COLLAPSIBLE: 'collapsible',
  NUMBER_FORMAT: 'number-format',
  PRICE_INPUT: 'price-input',
  PRICE: 'price',
  SIMPLE_GRID: 'simple-grid',
  STACK: 'stack',
  TOGGLE_BUTTON: 'toggle-button',
  TOGGLE_GROUP_MULTI: 'toggle-group-multi',
  TOGGLE_GROUP: 'toggle-group',
  INPUT_MULTI: 'input-multi',
  SUBMIT_BUTTON: 'submit-button',
  RESET_BUTTON: 'reset-button',
  SUMMARY_FIELD: 'summary-field',
  CHECKBOX_GROUP: 'checkbox-group',
  CHECKBOX_BUTTON: 'checkbox-button',
  CHECKBOX_BUTTON_GROUP: 'checkbox-button-group',
  BOX: 'box',
  RULE_BUILDER: 'rule-builder',
  COMPONENT_MAPPER: 'component-mapper',
  SECTION: 'section',
  DISPLAY_FIELD: 'display-field',
  DISPLAY_GRID: 'display-grid',
  COMPONENT: 'component',
  ADVANCED_SELECT: 'advanced-select',
  ADVANCED_MULTI_SELECT: 'advanced-multi-select',
  MULTI_INPUT: 'multi-input',
  RUNWAY: 'runway',
  FORM_BUILDER_FEEDBACK: 'form-builder-feedback',
  NATIVE_DATE_PICKER: 'native-date-picker',
  NATIVE_TIME_PICKER: 'native-time-picker',
  HIDDEN_FIELD: 'hidden-field'
} as const;

export const FORM_VALIDATOR_TYPE = {
  largeNumericInputMinValue: 'largeNumericInputMinValue',
  largeNumericInputMaxValue: 'largeNumericInputMaxValue',
  maxLength: 'maxLength',
  validateParentAccount: 'validateParentAccount'
} as const;

export const FORM_ACTION_TYPE = {
  handleParentAccountField: 'handleParentAccountField',
  handleParentAccountTypeField: 'handleParentAccountTypeField'
} as const;
