import type { MigrationStrategies, MigrationStrategy } from 'rxdb-v15';
import type { AnyRecord } from '../sub-schemas/schemas.common';

export type RxDBMigrationItem<TDocOld extends AnyRecord = AnyRecord> = {
  version: number;
  description?: string;
  migration?: MigrationStrategy<TDocOld>;
};

export type AllRxDBMigrationItems = RxDBMigrationItem<any>[];

export function createRxDbCollectionMigration<TDocOld extends AnyRecord = AnyRecord>(
  options: RxDBMigrationItem<TDocOld>
): RxDBMigrationItem<TDocOld> {
  return options;
}

export function createRxDbCollectionMigrationStrategies(
  migrationItems: AllRxDBMigrationItems
): MigrationStrategies {
  return migrationItems.reduce((acc, migrationItem, index) => {
    if (migrationItem.version === 0) {
      return acc;
    }

    if (!migrationItem.migration) {
      throw new Error(`Migration strategy for version ${migrationItem.version} is missing`);
    }

    acc[index] = migrationItem.migration;
    return acc;
  }, {} as MigrationStrategies);
}

export function getLatestRxDBMigrationVersion(versions: AllRxDBMigrationItems): RxDBMigrationItem {
  const latestFirst = versions.sort((a, b) => b.version - a.version);
  if (!latestFirst.length) {
    throw new Error('No migration versions found');
  }
  return latestFirst[0];
}
