import { useVGrid, VGrid } from '@oms/frontend-vgrid';
import { useService } from '@oms/frontend-foundation';
import { tradingOrderMonitorColumnLibrary, tradingOrderMarketData } from './trading-order-monitor.columns';
import { useTradingOrderMonitorToolbarActions } from './toolbar-actions';
import { MarketDataEventHandler } from '@app/data-access/services/marketdata/event-handlers/market-data.event.handler';
import { OfflineGridEventHandler } from '@app/data-access/services/system/grids/offline-grid.event-handler';
import {
  TsTradingOrdersWithFilterDocument,
  type TradingOrderRow,
  type TsTradingOrdersWithFilterSubscription
} from './trading-order-monitor.contracts';
import { TableServerDatasourceService } from '@app/data-access/services/system/table-server/table-server.datasource';

export const TradingOrderMonitorWidgetComponent = () => {
  useTradingOrderMonitorToolbarActions();

  const datasourceService = useService(TableServerDatasourceService);

  const gridProps = useVGrid<TradingOrderRow>(
    'trading-order-monitor-table-server',
    (builder) =>
      builder
        .columnLibrary(tradingOrderMonitorColumnLibrary)
        .rowSelection((c) => c.multiple())
        .datasource((d) =>
          d
            .source(
              datasourceService.getSource<TradingOrderRow, TsTradingOrdersWithFilterSubscription>({
                query: TsTradingOrdersWithFilterDocument,
                getData: (r) => r.tsTradingOrdersWithFilter,
                includeMarketData: true,
                tickerColumnName: 'instrumentDisplayCode'
              })
            )
            .rowId((r) => r.data.id)
            .cacheBlockSize(100)
        )
        .marketData(tradingOrderMarketData)
        .injectEvents([MarketDataEventHandler, OfflineGridEventHandler])
        .sideBar(),
    [datasourceService]
  );

  return <VGrid {...gridProps} />;
};

export default TradingOrderMonitorWidgetComponent;
