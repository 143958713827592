import type { ColumnBuilderCallback, ColumnLibrary } from '@oms/frontend-vgrid';
// import { t } from '@oms/codegen/translations';
import { sharedDefaultCol, sharedIdCol } from '@app/common/grids/columns/generic-cols';
import { type OmsNotification } from './notifications.utils';
import { NotificationCellRenderer } from './notifications.cell-renderers';
import { type ColDef } from '@ag-grid-community/core';
import { Priority } from '@oms/generated/frontend';

// TODO: For all columns, apply translations.

type notificationsColumnBuilder = ColumnBuilderCallback<OmsNotification>;

const defaultCol: notificationsColumnBuilder = (c) => sharedDefaultCol(c).filter(false).sortable(true);

const contentCol: notificationsColumnBuilder = (c) =>
  c
    .field('content')
    .cell((c) =>
      c.renderer(NotificationCellRenderer).valueGetter((p) => ({
        // Making the value an object equal to the properties which need to trigger a re-render in the cell renderer
        content: p.data?.content,
        isRead: p.data?.isRead,
        isSeen: p.data?.isSeen
      }))
    )
    .width(800);

const priorityComparator: ColDef['comparator'] = (valueA: Priority, valueB: Priority) => {
  const order = [Priority.Top, Priority.High, Priority.Normal];
  return order.indexOf(valueA) - order.indexOf(valueB);
};

// Hide/unhide columns for dev
const hidden = true;
const idCol: notificationsColumnBuilder = (c) => sharedIdCol(c, 'id').hide(hidden);
const priorityCol: notificationsColumnBuilder = (c) =>
  c.field('priority').sortable(true).comparator(priorityComparator).resizable(false).sort('asc').hide(hidden);
const resourceTypeCol: notificationsColumnBuilder = (c) => c.field('resourceType').hide(hidden);
const templateIdentifierCol: notificationsColumnBuilder = (c) => c.field('templateIdentifier').hide(hidden);
const createdAtCol: notificationsColumnBuilder = (c) => c.field('createdAt').sort('desc').hide(hidden);

// TODO: Implement when we support visibility
// const visibilityCol: notificationsColumnBuilder = (c) => c.field('visibility').hide();

export const notificationsColumnLibrary: ColumnLibrary<OmsNotification> = {
  defaultColumn: defaultCol,
  columns: [idCol, resourceTypeCol, templateIdentifierCol, contentCol, priorityCol, createdAtCol]
};
