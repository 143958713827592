import {
  type FigurationInfoFragment,
  RateType,
  ScaleBasis,
  type VisibleInvestorOrderInfoWithAllocationsFragment,
  type ChargeGroup,
  type ChargesRollup,
  CriteriaField,
  CriteriaCombinator,
  CriteriaOperator,
  type ChargeScaleDetails,
  type Maybe,
  type ScaleRateType,
  type ChargesRollupInfoFragment,
  type ChargesRollupFragment,
  CompositeChargeInfoFragment
} from '@oms/generated/frontend';
import { APP_DISPLAY_FIELD_COMPONENT_TYPE, type AppDisplayGridProps } from '@oms/frontend-foundation';
import Decimal from 'decimal.js';
import {
  format,
  type GridColValues,
  MAX_COLUMN_SIZE,
  type Sprinkles,
  GridProps,
  type DisplayGridItemProps
} from '@oms/shared-frontend/ui-design-system';
import type { MatchCriteriaFormOutput, MatchCriteriaInput } from './charges.types';
import { t } from '@oms/codegen/translations';
import { mapRateType } from '@app/common/mappers/map-rate-type';
import { mapScaleBasis } from '@app/common/mappers/map-scale-basis';
import { mapScaleRateType } from '@app/common/mappers/map-scale-rate-type';
import { DateTime } from 'luxon';
import { groupBy } from 'ramda';
import { formatNumber } from '@oms/shared/util';

const COMMISSION = 'Commission';
interface TradingDayToChargesMap {
  [key: string]: ChargesRollupFragment[];
}

export const commissionRateType = (figurations: FigurationInfoFragment[] | undefined): string => {
  if (!figurations) return '';

  const commissionFiguration = figurations.find((figuration) => {
    if (figuration?.isManual) return figuration?.manualCharge?.name === COMMISSION;

    return figuration?.chargeSchedule?.charge?.name === COMMISSION;
  });
  if (!commissionFiguration) return '';
  const commissionRateType = commissionFiguration?.isManual
    ? commissionFiguration?.manualRateType
    : commissionFiguration?.chargeSchedule?.rateType;
  if (!commissionRateType) return '';

  return mapRateType(commissionRateType);
};

export const commissionRateValue = (
  figurations: FigurationInfoFragment[] | undefined,
  visibleInvestorOrder: VisibleInvestorOrderInfoWithAllocationsFragment | undefined
): string => {
  if (!figurations) return '';
  if (!visibleInvestorOrder) return '';

  const commissionFiguration = figurations.find((figuration) => {
    if (figuration?.isManual) return figuration?.manualCharge?.name === COMMISSION;

    return figuration?.chargeSchedule?.charge?.name === COMMISSION;
  });
  if (!commissionFiguration) return '';

  const commissionRateValue = commissionFiguration?.isManual
    ? commissionFiguration?.manualRateValue
    : commissionFiguration?.chargeSchedule?.rateType === RateType.Scale
    ? getRateValueFromScale(commissionFiguration, visibleInvestorOrder)
    : commissionFiguration?.chargeSchedule?.rateValue;
  if (!commissionRateValue) return '';

  return format('number', commissionRateValue ?? '');
};

const getRateValueFromScale = (
  figuration: FigurationInfoFragment,
  investorOrder: VisibleInvestorOrderInfoWithAllocationsFragment
): number | undefined | null => {
  const { chargeSchedule } = figuration;

  const rows = chargeSchedule?.scaleSchedule?.scale?.rows ?? [];
  const rowValueDec = getRowValueFromScale(figuration, investorOrder);

  for (const row of rows) {
    const toDec = new Decimal(row?.to ?? 0);
    const fromDec = new Decimal(row?.from ?? 0);

    const rateValue = row?.rateValue;

    if (toDec.equals(-1)) return rateValue; // LAST ROW
    if (rowValueDec.greaterThanOrEqualTo(fromDec) && rowValueDec.lessThanOrEqualTo(toDec)) return rateValue;
  }

  return undefined;
};

const getRowValueFromScale = (
  figuration: FigurationInfoFragment,
  investorOrder: VisibleInvestorOrderInfoWithAllocationsFragment
): Decimal => {
  const { executedQuantity, averagePrice } = investorOrder;
  const { chargeSchedule } = figuration;

  const quantityDec = new Decimal(executedQuantity ?? 0);
  const priceDec = new Decimal(averagePrice ?? 0);

  switch (chargeSchedule?.scaleSchedule?.scale?.basis) {
    case ScaleBasis.OrderValue: {
      return quantityDec.times(priceDec).toDecimalPlaces(2);
    }
    case ScaleBasis.Quantity: {
      return quantityDec.toDecimalPlaces(2);
    }
    default: {
      return new Decimal(0);
    }
  }
};

export const commissionAmount = (chargesRollup: ChargesRollupInfoFragment | undefined): string => {
  if (!chargesRollup) return '';

  const totalAmounts = chargesRollup?.total;
  const commissionChargeCalculation = totalAmounts?.find(
    (chargesRollup) => chargesRollup.chargeName === COMMISSION
  );

  if (!commissionChargeCalculation) return '';

  const amount = commissionChargeCalculation?.amount;
  if (!amount) return '';
  if (amount === 0) return '';

  return format('number', amount);
};

export const groupChargesByName = (
  compositeCharges: CompositeChargeInfoFragment[]
): Record<string, CompositeChargeInfoFragment[]> => {
  return groupBy((composite: CompositeChargeInfoFragment) => composite.chargeName, compositeCharges);
};

export const getMostRecentCharges = (
  chargeMap: Record<string, CompositeChargeInfoFragment[]>
): CompositeChargeInfoFragment[] => {
  return Object.values(chargeMap).reduce((acc, compositeChargesForCharge) => {
    const mostRecentCharge = compositeChargesForCharge.sort(({ date: dateA }, { date: dateB }) =>
      dateB && dateA ? DateTime.fromISO(dateB).toMillis() - DateTime.fromISO(dateA).toMillis() : Infinity
    )[0];
    acc.push(mostRecentCharge);
    return acc;
  }, []);
};

type GridPropsAndItems = {
  compositeCharge: CompositeChargeInfoFragment;
  isScale: boolean;
  showChargeAndScaleName?: boolean;
  showChargeName?: boolean;
};

export const getGridPropsAndItems = ({
  compositeCharge,
  isScale,
  showChargeAndScaleName,
  showChargeName
}: GridPropsAndItems): { gridProps: GridProps; items: DisplayGridItemProps[] } => {
  const gridProps = isScale ? gridPopoverChargeScaleProps : gridPopoverChargeScheduleProps;
  const chargeSchedule = compositeCharge.figuration?.chargeSchedule;

  const items = isScale
    ? gridPopoverChargeScale({
        chargeName: compositeCharge.scheduleName,
        scaleName: chargeSchedule?.scaleSchedule?.name,
        basis: chargeSchedule?.scaleSchedule?.scale?.basis,
        rateType: chargeSchedule?.scaleSchedule?.scale?.rateType,
        rows: chargeSchedule?.scaleSchedule?.scale?.rows,
        showChargeAndScaleName
      })
    : gridPopoverChargeSchedule({
        chargeName: compositeCharge.scheduleName,
        rateType: compositeCharge?.rateType,
        rateValue: compositeCharge?.rateValue,
        min: chargeSchedule?.minimumChargeValue,
        max: chargeSchedule?.maximumChargeValue,
        showChargeName
      });

  return { gridProps, items };
};

const commonRowStyle: Sprinkles = {
  borderBottomStyle: 'solid',
  borderBottomWidth: '1px',
  borderColor: 'border.subtle',
  paddingBottom: 3,
  paddingInline: 3,
  marginLeft: '-3',
  marginRight: '-3'
};

type ChargeGroupTotal = {
  chargeGroup: ChargeGroup;
  order: VisibleInvestorOrderInfoWithAllocationsFragment;
  compositeChargeName?: string;
  isToday?: boolean;
};

export const chargeGroupTotal = ({
  chargeGroup,
  order,
  compositeChargeName,
  isToday
}: ChargeGroupTotal): AppDisplayGridProps['items'] => {
  const chargesRollup = order?.chargesRollup;
  if (!chargesRollup) return [];

  const { today: todayCharges, total: totalCharges, daily: dailyCharges } = chargesRollup;

  // Filtering logic based on the chargeGroup and chargeName
  const filterCharges = (charges: ChargesRollup[]) =>
    charges.filter((calc) =>
      compositeChargeName
        ? calc?.chargeGroup === chargeGroup && calc.chargeName === compositeChargeName
        : calc?.chargeGroup === chargeGroup
    );

  // TODAY and TOTAL filtered charges
  const filteredTodayChargeGroup = filterCharges(todayCharges);
  const filteredTotalDailyChargeGroup = filterCharges(totalCharges);
  // Based on the the isToday flag show total for today vs daily total charges
  const filteredTotalChargeGroup = isToday ? filteredTodayChargeGroup : filteredTotalDailyChargeGroup;
  if (filteredTotalChargeGroup.length === 0 && !compositeChargeName) return [];

  const todayTradingDay = filteredTodayChargeGroup[0]?.tradingDay;
  const filteredDailyCharges = dailyCharges
    .sort((a, b) => DateTime.fromISO(b.tradingDay).toMillis() - DateTime.fromISO(a.tradingDay).toMillis())
    .reduce((acc, { tradingDay, charges }) => {
      // Skip charge if its tradingDay is today
      if (todayTradingDay === tradingDay) {
        return acc;
      }
      const filteredChargeGroup = filterCharges(charges);
      acc[tradingDay] = filteredChargeGroup;

      return acc;
    }, {} as TradingDayToChargesMap);

  const columns = filteredTotalChargeGroup.length + 1;

  const totalByChargeGroup =
    filteredTotalChargeGroup
      .map((calc) => calc?.amount)
      .reduce((prev, curr) => (prev ?? 0) + (curr ?? 0), 0) ?? 0;
  const total = totalByChargeGroup === 0 ? '' : formatNumber(totalByChargeGroup);

  const totalChargeByGroupComponent = {
    component: {
      type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Link,
      text: total
    }
  };

  if (columns > MAX_COLUMN_SIZE) {
    return [
      {
        component: {
          type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Popover,
          options: {
            trigger: 'click'
          },
          content: {
            gridProps: {
              rows: 1,
              columns: 1,
              rowGap: 0,
              columnGap: 0
            },
            items: [
              {
                component: {
                  type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Text,
                  value: 'Too many charges to display'
                }
              }
            ]
          },
          value: totalChargeByGroupComponent
        }
      }
    ];
  }

  const gridProps: AppDisplayGridProps['gridProps'] = {
    rows: 1,
    columns: columns as GridColValues,
    columnGap: 5,
    rowGap: 1
  };

  const chargeColumns: AppDisplayGridProps['items'] = filteredTotalChargeGroup.flatMap((calc) => [
    {
      label: calc.chargeName,
      layout: 'vertical',
      component: {
        type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Numeric,
        format: 'price',
        value: ''
      },
      sx: { ...commonRowStyle, padding: 0, marginBottom: 2 }
    }
  ]);

  const chargeItemsForTotal: AppDisplayGridProps['items'] = filteredTotalChargeGroup.flatMap((calc) => [
    {
      label: ' ',
      layout: 'vertical',
      component: {
        type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Numeric,
        format: 'price',
        value: calc?.amount || ''
      }
    }
  ]);

  const chargeItemsForToday: AppDisplayGridProps['items'] = filteredTodayChargeGroup.flatMap((calc) => [
    {
      component: {
        type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Numeric,
        format: 'price',
        value: calc?.amount || ''
      }
    }
  ]);

  const chargeItemsDaily = Object.keys(filteredDailyCharges).flatMap((tradingDay) => {
    const charges = filteredDailyCharges[tradingDay] ?? [];
    const tradingDayDateTime = DateTime.fromISO(tradingDay);

    return [
      {
        label: `${tradingDayDateTime.monthShort} ${tradingDayDateTime.day}`,
        width: '6rem',
        component: {
          type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Text,
          value: ''
        }
      },
      ...charges.map((calc) => ({
        component: {
          type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Numeric,
          format: 'price',
          value: calc?.amount || ''
        }
      }))
    ];
  });

  // Same day
  const todaysTradingDaySet = todayCharges.reduce<Set<string>>((acc, calc) => {
    acc.add(calc.tradingDay);
    return acc;
  }, new Set<string>());
  const dailyTradeDaySet = dailyCharges.reduce<Set<string>>((acc, calc) => {
    acc.add(calc.tradingDay);
    return acc;
  }, new Set<string>());

  const hideDailyCharges =
    (dailyTradeDaySet.size === 1 && todaysTradingDaySet.has(dailyCharges[0]?.tradingDay)) || isToday;
  const hideTodayCharges = todaysTradingDaySet.size === 0;

  const items: DisplayGridItemProps[] = [
    ...(compositeChargeName
      ? []
      : [
          {
            component: {
              type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Text,
              value: ''
            },
            sx: { ...commonRowStyle, padding: 0, marginBottom: 2 }
          },
          ...chargeColumns
        ]),
    ...(hideTodayCharges
      ? []
      : [
          {
            label: 'Today',
            width: '6rem',
            component: {
              type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Text,
              value: ''
            }
          },
          ...chargeItemsForToday
        ]),
    ...(hideDailyCharges ? [] : chargeItemsDaily),
    {
      component: {
        type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Text,
        value: ''
      },
      sx: { ...commonRowStyle, marginBottom: 2 },
      colSpan: columns
    },
    {
      label: 'Total',
      width: '6rem',
      component: {
        type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Text,
        value: ''
      }
    },
    ...chargeItemsForTotal
  ] as DisplayGridItemProps[];

  const popoverWidth = 110 + chargeColumns.length * 80;

  return [
    {
      component: {
        type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Popover,
        options: {
          trigger: 'click',
          width: popoverWidth
        },
        content: {
          gridProps,
          items: compositeChargeName && totalByChargeGroup === 0 ? [] : items
        },
        value:
          compositeChargeName && totalByChargeGroup === 0
            ? {
                component: {
                type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Text,
                  value: '-'
                }
              }
            : totalChargeByGroupComponent
      }
    }
  ];
};

export const toMatchCriteriaInput = (ruleBuilderOutput: MatchCriteriaFormOutput): MatchCriteriaInput => {
  const { id, priority, combinator, rules } = ruleBuilderOutput;
  const formattedCombinator = combinator === 'and' ? CriteriaCombinator.And : CriteriaCombinator.Or;

  return {
    id,
    priority,
    input: {
      combinator: formattedCombinator,
      criterias: rules.map((rule) => ({
        field: rule?.value?.value as CriteriaField,
        operator: CriteriaOperator.Equal,
        value: {
          label: undefined,
          id: rule?.value?.value
        }
      }))
    }
  };
};

export const criteriaFieldStringMap = () => ({
  [CriteriaField.Account]: t('app.charges.criteriaFields.account'),
  [CriteriaField.AccountType]: t('app.charges.criteriaFields.accountType'),
  [CriteriaField.AccountSubType]: t('app.charges.criteriaFields.accountSubType'),
  [CriteriaField.AccountCountryOfDomicile]: t('app.charges.criteriaFields.accountCountryOfDomicile'),
  [CriteriaField.AccountList]: t('app.charges.criteriaFields.accountList'),
  [CriteriaField.Exchange]: t('app.charges.criteriaFields.exchange'),
  [CriteriaField.Instrument]: t('app.charges.criteriaFields.instrument'),
  [CriteriaField.InstrumentCountryOfIncorporation]: t(
    'app.charges.criteriaFields.instrumentCountryOfIncorporation'
  ),
  [CriteriaField.InstrumentList]: t('app.charges.criteriaFields.instrumentList'),
  [CriteriaField.Region]: t('app.charges.criteriaFields.region'),
  [CriteriaField.SecurityType]: t('app.charges.criteriaFields.securityType'),
  [CriteriaField.SettleDateEffectiveOn]: t('app.charges.criteriaFields.settleDateEffectiveOn'),
  [CriteriaField.SettleDateEffectiveTill]: t('app.charges.criteriaFields.settleDateEffectiveTill'),
  [CriteriaField.SettlementCurrency]: t('app.charges.criteriaFields.settlementCurrency'),
  [CriteriaField.Side]: t('app.charges.criteriaFields.side'),
  [CriteriaField.TradeCurrency]: t('app.charges.criteriaFields.tradeCurrency'),
  [CriteriaField.TradeDateEffectiveOn]: t('app.charges.criteriaFields.tradeDateEffectiveOn'),
  [CriteriaField.TradeDateEffectiveTill]: t('app.charges.criteriaFields.tradeDateEffectiveTill')
});

export const criteriaFieldtoMapperId = (): Record<CriteriaField, string> => ({
  [CriteriaField.Account]: 'investorAccount',
  [CriteriaField.AccountType]: 'accountType',
  [CriteriaField.AccountSubType]: 'accountSubType',
  [CriteriaField.AccountCountryOfDomicile]: 'country',
  [CriteriaField.AccountList]: 'accountList',
  [CriteriaField.Exchange]: 'listingExchange',
  [CriteriaField.Instrument]: 'instrument',
  [CriteriaField.InstrumentCountryOfIncorporation]: 'countryOfIncorporation',
  [CriteriaField.InstrumentList]: 'instrumentList',
  [CriteriaField.Region]: 'region',
  [CriteriaField.SecurityType]: 'securityType',
  [CriteriaField.SettleDateEffectiveOn]: 'settleDateTill',
  [CriteriaField.SettleDateEffectiveTill]: 'settleDateOn',
  [CriteriaField.SettlementCurrency]: 'settleCurrency',
  [CriteriaField.Side]: 'side',
  [CriteriaField.TradeCurrency]: 'tradeCurrency',
  [CriteriaField.TradeDateEffectiveOn]: 'tradeDateOn',
  [CriteriaField.TradeDateEffectiveTill]: 'tradeDateTill'
});

export const gridPopoverChargeScaleProps: AppDisplayGridProps['gridProps'] = {
  columns: 2,
  rows: 2,
  columnGap: 0,
  rowGap: 1
};

type GridPopoverChargeScaleProps = {
  chargeName?: string;
  scaleName?: string;
  basis?: ScaleBasis | null;
  rateType?: ScaleRateType | null;
  rows?: Maybe<ChargeScaleDetails>[] | null;
  showChargeAndScaleName?: boolean;
};

export const gridPopoverChargeScale = ({
  chargeName,
  scaleName,
  basis,
  rateType,
  rows,
  showChargeAndScaleName
}: GridPopoverChargeScaleProps): DisplayGridItemProps[] => {
  const layout: DisplayGridItemProps[] = [
    {
      label: 'Charge',
      component: {
        type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Text,
        value: ''
      },
      sx: commonRowStyle
    },
    {
      component: {
        type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Text,
        value: chargeName ?? ''
      },
      sx: commonRowStyle
    },
    {
      label: 'Scale name',
      component: {
        type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Text,
        value: ''
      },
      labelMargin: 5,
      sx: { ...commonRowStyle, paddingTop: 3 }
    },
    {
      component: {
        type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Text,
        value: scaleName ?? ''
      },
      sx: { ...commonRowStyle, paddingTop: 3 }
    }
  ];
  if (!rows?.length) return layout;

  const detailRows: DisplayGridItemProps[] = rows.flatMap((row, index) => {
    const rowNumber = index + 1;
    if (!row) return [];
    const to = row.to === -1 ? '' : row.to;

    const isRowBorderVisible = rows.length > 1 && rowNumber !== rows.length;

    return [
      {
        label: `SCALE ${rowNumber}`,
        component: {
          type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Text,
          value: ''
        }
      },
      {
        component: {
          type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Text,
          value: ''
        }
      },
      {
        label: 'Range',
        component: {
          type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Text,
          value: ''
        }
      },
      {
        component: {
          type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Text,
          value: `${row.from}${to ? ` - ${to}` : ''}`
        }
      },
      {
        label: 'Value',
        component: {
          type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Text,
          value: ''
        }
      },
      {
        component: {
          type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Numeric,
          format: 'price-position-based-on-one',
          value: row.rateValue || row.rateValue === 0 ? row.rateValue : ''
        }
      },
      {
        label: 'Rate type',
        component: {
          type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Text,
          value: ''
        }
      },
      {
        component: {
          type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Text,
          value: rateType ? mapScaleRateType(rateType) : ''
        }
      },
      {
        label: 'Basis',
        component: {
          type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Text,
          value: ''
        },
        sx: { ...(isRowBorderVisible ? commonRowStyle : {}) }
      },
      {
        component: {
          type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Text,
          value: basis ? mapScaleBasis(basis) : ''
        },
        sx: { ...(isRowBorderVisible ? commonRowStyle : {}) }
      }
    ];
  });

  return [...(showChargeAndScaleName ? layout : []), ...detailRows];
};

export const gridPopoverChargeScheduleProps: AppDisplayGridProps['gridProps'] = {
  columns: 1,
  rows: 2,
  columnGap: 0,
  rowGap: 1
};

type GridPopoverChargeScheduleProps = {
  chargeName?: string;
  rateType?: RateType;
  rateValue?: number | null;
  min?: number | null;
  max?: number | null;
  showChargeName?: boolean;
};

export const gridPopoverChargeSchedule = ({
  chargeName,
  rateType,
  rateValue,
  min,
  max,
  showChargeName
}: GridPopoverChargeScheduleProps): DisplayGridItemProps[] => {
  const items: DisplayGridItemProps[] = [
    ...(showChargeName
      ? [
          {
            label: 'Charge',
            component: {
              type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Text,
              value: chargeName ?? ''
            }
          }
        ]
      : []),
    {
      label: 'Rate type',
      component: {
        type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Text,
        value: rateType ? mapRateType(rateType) : ''
      }
    },
    {
      label: 'Rate value',
      component: {
        type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Numeric,
        format: 'price-position-based-on-one',
        value: rateValue ?? ''
      }
    }
  ];

  if (min) {
    items.push({
      label: 'Min',
      component: {
        type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Numeric,
        format: 'price',
        value: min
      }
    });
  }

  if (max) {
    items.push({
      label: 'Max',
      component: {
        type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Numeric,
        format: 'price',
        value: max
      }
    });
  }

  return items;
};
