import type { ValidatorFunction } from '@data-driven-forms/react-form-renderer';
import type { Optional, Maybe } from '@oms/shared/util-types';

type DurationInputFormatType = 'mmss';

const DURATION_PATTERN_MAPPER: Record<DurationInputFormatType, RegExp> = {
  mmss: /^(\d+):([0-5]\d)$/
} as const;

export interface ValidateDurationInputOptions {
  formatType?: DurationInputFormatType;
}

type ValidatorDurationFn = (options?: ValidateDurationInputOptions) => ValidatorFunction;

// Validator --------------------------------------------------------------- /

export const validateDurationInput: ValidatorDurationFn = (options) => (value) => {
  if (typeof value !== 'string' || !value.length) return null;
  const { formatType = 'mmss' } = options ?? {};
  switch (formatType) {
    case 'mmss': {
      const pattern = DURATION_PATTERN_MAPPER[formatType];
      if (pattern.test(value)) {
        return null;
      } else {
        return 'Format in mm:ss';
      }
    }
  }
};

// Utilities --------------------------------------------------------------- /

const toNumber = (amount?: string, fallback = 0): number => {
  if (!amount) return fallback;
  const possibleNumber = Number.parseInt(amount, 10);
  return !Number.isNaN(possibleNumber) ? possibleNumber : fallback;
};

export const getDurationInSeconds = (
  value?: string,
  options?: ValidateDurationInputOptions
): Optional<number> => {
  const { formatType = 'mmss' } = options ?? {};
  switch (formatType) {
    case 'mmss': {
      if (!value) return undefined;
      const pattern = DURATION_PATTERN_MAPPER[formatType];
      const result = value.match(pattern);
      if (!result) return undefined;
      const [_, mm, ss] = result;
      const minutes = toNumber(mm);
      const seconds = toNumber(ss);
      return minutes * 60 + seconds;
    }
  }
};

export const getDurationInMMSS = (value: Maybe<number>): Optional<string> => {
  if (!value) return undefined;

  const minutes = Math.floor(value / 60);
  const remainingSeconds = value % 60;

  const mm = String(minutes).padStart(2, '0');
  const ss = String(remainingSeconds).padStart(2, '0');

  return `${mm}:${ss}`;
}
