import {
  type AgGridFilterModelBase,
  type AgGridDateFilter,
  type AgGridNumberFilter,
  type AgGridSetFilter,
  type AgGridTextFilter,
  agGridTextFilterSchema,
  agGridNumberFilterSchema,
  agGridDateFilterSchema,
  agGridSetFilterSchema,
  agGridFiltersModelSchema,
  agGridSortModelchema
} from './ag-grid.filters.schema';

// ------------ FILTER MODEL --------------------------------------------------------
// ----------------------------------------------------------------------------------

function Get(key: string): string {
  const [first, ...rest] = key.split('.');
  return `Get('${first}')${rest.map((k) => `.${k}`).join('')}`;
}

export function transformCondition(fieldKey: string, condition: AgGridFilterModelBase): string {
  switch (condition.filterType) {
    case 'text':
      return transformTextFilter(fieldKey, agGridTextFilterSchema.parse(condition));
    case 'number':
      return transformNumberFilter(fieldKey, agGridNumberFilterSchema.parse(condition));
    case 'date':
      return transformDateFilter(fieldKey, agGridDateFilterSchema.parse(condition));
    case 'set':
      return transformSetFilter(fieldKey, agGridSetFilterSchema.parse(condition));
    default:
      throw new Error('Unknown filter type');
  }
}

export function transformTextFilter(fieldKey: string, condition: AgGridTextFilter): string {
  switch (condition.type) {
    case 'equals':
      return `${Get(fieldKey)} == "${condition.filter}"`;
    case 'notEqual':
      return `${Get(fieldKey)} != "${condition.filter}"`;
    case 'contains':
      return `${Get(fieldKey)} contains "${condition.filter}"`;
    case 'notContains':
      return `not (${Get(fieldKey)} contains "${condition.filter}")`;
    case 'startsWith':
      return `${Get(fieldKey)} startsWith "${condition.filter}"`;
    case 'endsWith':
      return `${Get(fieldKey)} endsWith "${condition.filter}"`;
    case 'blank':
      return `${Get(fieldKey)} == nil`;
    case 'notBlank':
      return `${Get(fieldKey)} != nil`;
    default:
      throw new Error('Unknown text filter type');
  }
}

export function transformNumberFilter(fieldKey: string, condition: AgGridNumberFilter): string {
  switch (condition.type) {
    case 'equals':
      return `${Get(fieldKey)} == ${condition.filter}`;
    case 'notEqual':
      return `${Get(fieldKey)} != ${condition.filter}`;
    case 'lessThan':
      return `${Get(fieldKey)} < ${condition.filter}`;
    case 'lessThanOrEqual':
      return `${Get(fieldKey)} <= ${condition.filter}`;
    case 'greaterThan':
      return `${Get(fieldKey)} > ${condition.filter}`;
    case 'greaterThanOrEqual':
      return `${Get(fieldKey)} >= ${condition.filter}`;
    case 'inRange':
      return `(${Get(fieldKey)} >= ${condition.filter}) and (${Get(fieldKey)} <= ${condition.filterTo})`;
    case 'blank':
      return `${Get(fieldKey)} == nil`;
    case 'notBlank':
      return `${Get(fieldKey)} != nil`;
    default:
      throw new Error('Unknown number filter type');
  }
}

export function transformDateFilter(fieldKey: string, condition: AgGridDateFilter): string {
  const dateFrom = `date("${condition.dateFrom}")`;
  const dateTo = condition.dateTo ? `date("${condition.dateTo}")` : null;

  switch (condition.type) {
    case 'equals':
      return `${Get(fieldKey)} == ${dateFrom}`;
    case 'notEqual':
      return `${Get(fieldKey)} != ${dateFrom}`;
    case 'lessThan':
      return `${Get(fieldKey)} < ${dateFrom}`;
    case 'lessThanOrEqual':
      return `${Get(fieldKey)} <= ${dateFrom}`;
    case 'greaterThan':
      return `${Get(fieldKey)} > ${dateFrom}`;
    case 'greaterThanOrEqual':
      return `${Get(fieldKey)} >= ${dateFrom}`;
    case 'inRange':
      return `(${Get(fieldKey)} >= ${dateFrom}) and (${Get(fieldKey)} <= ${dateTo})`;
    case 'blank':
      return `${Get(fieldKey)} == nil`;
    case 'notBlank':
      return `${Get(fieldKey)} != nil`;
    default:
      throw new Error('Unknown date filter type');
  }
}

export function transformSetFilter(fieldKey: string, condition: AgGridSetFilter): string {
  if (condition.filterType !== 'set') {
    throw new Error('Invalid set filter type');
  }
  const v = condition?.values ?? [];
  const values = v.map((value) => `"${value}"`).join(', ');
  return `${Get(fieldKey)} in [${values}]`;
}

export function agFilterModelToTableServerFilterStr(unknownFilters: unknown): string {
  const filters = agGridFiltersModelSchema.parse(unknownFilters);
  const expressions = Object.entries(filters).map(([fieldKey, filterModel]) => {
    if (filterModel.conditions && filterModel.conditions.length > 0) {
      const conditions = filterModel.conditions.map((cond) => transformCondition(fieldKey, cond));
      const operator = filterModel.operator === 'OR' ? 'or' : 'and';
      return `(${conditions.join(` ${operator} `)})`;
    } else {
      return transformCondition(fieldKey, filterModel);
    }
  });
  const filter = `(${expressions.join(' and ')})`;
  if (filter === '()') {
    return '';
  }
  return filter;
}

// ------------ SORT MODEL ----------------------------------------------------------
// ----------------------------------------------------------------------------------

export function agSortModelToTableServerSortStr(unknownSort: unknown): string {
  const sortModel = agGridSortModelchema.parse(unknownSort);

  return sortModel
    .map((sort) => {
      const direction = sort.sort === 'asc' ? 'ascending' : 'descending';
      return `${direction}('${sort.colId}')`;
    })
    .join(' | ');
}
