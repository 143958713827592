import type { Field } from '@data-driven-forms/react-form-renderer';
import type { FormOptions, IAdvancedSelectField } from '@oms/frontend-foundation';
import { type RouteOrderFormValues } from '@app/widgets/trading/route-order/route-order.form-contract';
import type { UsersComboBoxValue } from '@app/forms/common/fields/user-field/users.watch-all.query';

export const ROUTE_ORDER_TRADER_ACTION = 'routeOrderTraderAction';

export const routeOrderTraderAction =
  () =>
  (
    _props: Field,
    _field: Field,
    formOptions: FormOptions
  ): Partial<IAdvancedSelectField<UsersComboBoxValue>> => {
    const formValues: Partial<RouteOrderFormValues> = formOptions.getState().values;
    const { hiddenMode } = formValues || {};
    const type = hiddenMode?.type || 'create';

    switch (type) {
      case 'modify':
        return {
          isDisabled: true,
          forceIsDisabled: true
        };
      default:
        return {};
    }
  };
