import type { VisibleTradingOrderInfoWithAllocationsFragment } from '@oms/generated/frontend';
import { FormSummary } from '@oms/frontend-foundation';
import React, { useMemo } from 'react';
import {
  type OrderSummaryFieldName,
  orderSummaryData,
  orderStrategyParametersSummaryData
} from '@app/common/summaries/order-fields';

const orderFields: OrderSummaryFieldName[] = [
  'quantity',
  'sideType',
  'locate',
  'id',
  'tradingAccount.name',
  'tradingAccount.accountType',
  'limitPrice',
  'orderType',
  'capacity',
  'timeInForce',
  'tifDuration',
  'expiryDateTime',
  'orderTags',
  'status',
  'createdTimestamp',
  'updatedTimestamp',
  'enteredBy.email',
  'owner',
  'executedQuantity',
  'averagePrice',
  'minQuantity',
  'displayQuantity',
  'destinationName',
  'targetExchange.id',
  'category',
  'instrument.mappings.displayCode',
  'fixMessage',
  'customerNotes',
  'venueNotes',
  'representativeCode.code'
];

const settleFields: OrderSummaryFieldName[] = ['tradeCurrency', 'settleCurrency', 'settleType', 'settleDate'];

export const TradingOrderDetailsSummary = React.memo(
  ({ tradingOrder }: { tradingOrder: VisibleTradingOrderInfoWithAllocationsFragment }) => {
    const data = useMemo(() => {
      const sections = [
        orderSummaryData('ORDER', orderFields, tradingOrder),
        orderSummaryData('SETTLE', settleFields, tradingOrder)
      ];
      if (tradingOrder.strategy) {
        sections.push(orderStrategyParametersSummaryData('STRATEGY PARAMETERS', tradingOrder));
      }
      return sections;
    }, [tradingOrder]);

    return <FormSummary labeledData={data} />;
  }
);
