import { inject, singleton } from 'tsyringe';
import { GQLResponse } from '@app/data-access/api/graphql/graphql-response';
import type { AwaitGQLResultType } from '@oms/frontend-foundation';
import {
  type OrderRouteCreateInput,
  type RequestOrderRouteMutation,
  type RequestOrderRouteMutationVariables,
  RequestOrderRouteDocument,
  type GetMatchingInvestorOrdersQuery,
  type GetMatchingInvestorOrdersQueryVariables,
  GetMatchingInvestorOrdersDocument,
  type GetMatchingInvestorOrdersInput,
  type GetAtdlForExecutionVenueQuery,
  type GetAtdlForExecutionVenueQueryVariables,
  GetAtdlForExecutionVenueDocument,
  type QueryGetAtdlForExecutionVenueArgs,
  type GetAtdlForExecutionVenueFilterByStrategyQuery,
  type GetAtdlForExecutionVenueFilterByStrategyQueryVariables,
  GetAtdlForExecutionVenueFilterByStrategyDocument,
  PriceOptions
} from '@oms/generated/frontend';

@singleton()
export class RouteOrderService {
  constructor(@inject(GQLResponse) private gqlResponse: GQLResponse) {}

  public routeInvestorOrder(
    input: OrderRouteCreateInput,
    options?: { dryRun?: boolean; priceOption?: PriceOptions }
  ): AwaitGQLResultType<RequestOrderRouteMutation> {
    const { dryRun = false, priceOption } = options || {};
    const mutation = this.gqlResponse.wrapMutate<
      RequestOrderRouteMutation,
      RequestOrderRouteMutationVariables
    >({
      mutation: RequestOrderRouteDocument,
      variables: {
        orderRoute: input,
        dryRun,
        priceOption
      }
    });

    return mutation.awaitAsyncResponse().exec();
  }

  public getMatchedInvestorOrders({ instrumentId, side, limitPrice }: GetMatchingInvestorOrdersInput) {
    const result = this.gqlResponse.wrapQuery<
      GetMatchingInvestorOrdersQuery,
      GetMatchingInvestorOrdersQueryVariables
    >({
      query: GetMatchingInvestorOrdersDocument,
      variables: {
        input: {
          instrumentId,
          side,
          limitPrice
        }
      },
      fetchPolicy: 'no-cache'
    });
    return result.exec();
  }

  public getAtdlForExecutionVenue({ executionVenueId, countryId }: QueryGetAtdlForExecutionVenueArgs) {
    const result = this.gqlResponse.wrapQuery<
      GetAtdlForExecutionVenueQuery,
      GetAtdlForExecutionVenueQueryVariables
    >({
      query: GetAtdlForExecutionVenueDocument,
      variables: {
        executionVenueId,
        countryId
      },
      fetchPolicy: 'no-cache'
    });
    return result.exec();
  }

  public getAtdlForExecutionVenueFilterByStrategy(executionVenueId: string, strategyName: string) {
    const result = this.gqlResponse.wrapQuery<
      GetAtdlForExecutionVenueFilterByStrategyQuery,
      GetAtdlForExecutionVenueFilterByStrategyQueryVariables
    >({
      query: GetAtdlForExecutionVenueFilterByStrategyDocument,
      variables: {
        executionVenueId,
        strategyName
      }
    });

    return result.exec();
  }
}
