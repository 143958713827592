import { type CrossTradeRequestResponseEnvelope } from '@oms/generated/frontend';
import type { CrossPrincipalFillOutput } from './cross-principal-fill.form-common';
import { type CrossPrincipalFormValues } from './cross-principal-fill.form-contract';
import { isEmpty, omit } from 'lodash';

export const enrichFieldValues = (
  response: CrossTradeRequestResponseEnvelope | null | undefined,
  output: CrossPrincipalFillOutput | null | undefined,
  currFormValues: CrossPrincipalFormValues
): Partial<CrossPrincipalFormValues> | null => {
  const enrichedData = response?.enrichedData;
  const emptyEnrichedData = Object.values(omit(enrichedData, ['__typename'])).every((value) => value === null);
  
  if (!enrichedData) return null;
  if (emptyEnrichedData) return null;

  const formValues: Partial<CrossPrincipalFormValues> = {};

  if ('quantity' in enrichedData) {
    if (enrichedData.quantity && output?.crossTrade?.quantity !== enrichedData.quantity) {
      formValues.quantity = enrichedData.quantity;
    }
  }
  if ('regNmsApplicability' in enrichedData) {
    if (
      enrichedData.regNmsApplicability &&
      formValues.regNmsApplicability !== enrichedData.regNmsApplicability
    ) {
      formValues.regNmsApplicability = enrichedData.regNmsApplicability;
    }
  }

  if (currFormValues.hiddenFormInfo) {
    formValues.hiddenFormInfo = {
      ...currFormValues.hiddenFormInfo,
      enrichedData: enrichedData
    };
  }

  return isEmpty(formValues) ? null : formValues;
};
