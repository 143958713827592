import { useMemo, useEffect, useRef } from 'react';
import { BehaviorSubject } from 'rxjs';
import { FormBuilderRenderer, useInterpretFormBuilder } from '@oms/frontend-foundation';
import type { MontageFiltersForm } from '@app/generated/sdk';
import { useSetMontageState } from '@app/data-access/offline/collections/montage/hooks/use-set-montage-state.hook';
import { type MontageLayout } from '@app/data-access/services/trading/montage/montage.types';
import { MontageService } from '@app/data-access/services/trading/montage/montage.service';
import { useScopedActorId } from '@app/data-access/offline/hooks/use-scoped-actor-id.hook';
import { Logger } from '@oms/shared/util';
import { useMontageLayout } from '../../use-montage-layout.hooks';
import { montageFiltersFormBuilder } from './montage-filters.form-builder';
import { useMontageInstrumentDetails } from '@app/data-access/services/trading/montage/hooks/use-montage-instrument-details.hook';

const logger = Logger.labeled('MontageFiltersFormWidget');

export const MontageFiltersFormWidget: typeof MontageFiltersForm = ({ input }) => {
  const setMontageState = useSetMontageState();
  const { layout } = useMontageLayout();
  const scopedActorId = useScopedActorId();
  const obsRef$ = useRef(new BehaviorSubject<MontageLayout>(layout));
  const instrumentDetails = useMontageInstrumentDetails();

  useEffect(() => {
    obsRef$.current.next(layout);
  }, [layout]);

  const builder = useMemo(
    () =>
      montageFiltersFormBuilder
        .clone()
        .effect(({ formApi, container }) => {
          const layoutSubscription = obsRef$.current.subscribe((layout) => {
            formApi.change('layout', layout);
          });

          const montageService = container.resolve(MontageService);
          const firmAccountSubscription = montageService
            .watchMontageFirmAccount$(scopedActorId)
            .subscribe((firmAccount) => {
              if (firmAccount) {
                formApi.change('investorAccount', {
                  id: firmAccount.id,
                  label: firmAccount.name,
                  description: firmAccount.description
                });
              } else {
                formApi.change('investorAccount', undefined);
              }
            });

          return () => {
            layoutSubscription.unsubscribe();
            firmAccountSubscription.unsubscribe();
          };
        })
        .change((event) => {
          switch (event.type) {
            case 'MOUNT': {
              logger.debug('Initializing Montage filters');
              setMontageState().catch(logger.error);
              break;
            }
            case 'VALUES_CHANGING': {
              const { prevFormValues, formValues } = event.payload;
              if (prevFormValues.instrumentTrackingEnabled !== formValues.instrumentTrackingEnabled) {
                logger.debug(
                  `Montage instrument tracking turned ${formValues.instrumentTrackingEnabled ? 'on' : 'off'}`
                );
                setMontageState({
                  instrumentTrackingEnabled: formValues.instrumentTrackingEnabled ?? false
                }).catch(logger.error);
              }
              if (prevFormValues.instrument?.id !== formValues.instrument?.id) {
                logger.debug(`Montage instrument changed to ${formValues.instrument?.id}`);
                setMontageState({
                  instrumentId: formValues.instrument?.id
                }).catch(logger.error);
              }
              if (prevFormValues.investorAccount?.id !== formValues.investorAccount?.id) {
                logger.debug(`Montage account changed to ${formValues.investorAccount?.id}`);
                setMontageState({
                  investorAccountId: formValues.investorAccount?.id
                }).catch(logger.error);
              }
              break;
            }
          }
        }),
    [setMontageState, scopedActorId]
  );

  const initialInput = useMemo(
    () => ({
      ...input,
      instrument: instrumentDetails?.mappings.displayCode ?? '',
      layout
    }),
    [input, layout, instrumentDetails?.mappings.displayCode]
  );

  const formProps = useInterpretFormBuilder(builder, initialInput);

  return <FormBuilderRenderer<typeof montageFiltersFormBuilder> {...formProps} />;
};
