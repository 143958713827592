import { RepresentativeCodesService } from '@app/data-access/services/reference-data/representative-codes/representative-codes.service';
import {
  type AdvancedSelectQueryFn,
  getAdvancedSelectQueryReturn,
  type DataSourceCommon,
  type ComboBoxItem
} from '@oms/frontend-foundation';
import { type RepresentativeCodeInfoFragment } from '@oms/generated/frontend';
import { type Observable } from 'rxjs';

export const watchAllRepCodesQuery: AdvancedSelectQueryFn = (container) => {
  const representativeCodesService = container.resolve(RepresentativeCodesService);

  return getAdvancedSelectQueryReturn<RepresentativeCodeInfoFragment>({
    queryFn: representativeCodesService.watchAll$.bind(representativeCodesService) as () => Observable<
      DataSourceCommon<RepresentativeCodeInfoFragment>
    >,
    resultMapper: (repCode) => ({
      id: repCode.id,
      label: repCode.code,
      sublabel: repCode.description,
      value: repCode.id,
      type: 'item'
    })
  });
};

export const createRepCodeComboboxItem = (
  repCode: Partial<RepresentativeCodeInfoFragment> & Pick<RepresentativeCodeInfoFragment, 'id' | 'code'>
): ComboBoxItem => {
  return {
    id: repCode.id,
    label: repCode.code,
    sublabel: repCode.description || '',
    value: repCode.id,
    type: 'item'
  };
};
