import { FieldContract, type InferFormValueFromFieldDefinition } from '@oms/frontend-foundation';
import type { CreateOrderMutationFormOutput } from './example.contracts';
import { type ExampleTagEntity } from './example.form-field.tags.select-query';
import { ADVANCED_SELECT_QUERY_ENUM } from '@app/generated/common';
import { type AdditionalFormFieldUnion } from '@app/forms/form-builder/mappers/form.mappers';

const ofc = FieldContract.create<CreateOrderMutationFormOutput, AdditionalFormFieldUnion>();

export const intrumentId = ofc.field('intrumentId', 'advanced-select').advancedSelectOptions({
  label: 'Symbol',
  query: ADVANCED_SELECT_QUERY_ENUM.EXAMPLE_INSTRUMENTS_WATCH_QUERY
});

export const quantity = ofc.field('quantity', 'number-format').options({
  label: 'Quantity'
});

export const side = ofc.field('side', 'select').options({
  label: 'Side',
  options: [
    { value: 'buy', label: 'Buy' },
    { value: 'sell', label: 'Sell' }
  ]
});

// 👇 Note: "example-field" is a custom app-specific field. It's available when you pass "AdditionalFormFieldUnion" to the "FieldContract" above
export const notes = ofc.field('notes', 'example-field').options({
  label: 'Notes'
});

export const tagIds = ofc
  .field('tagIds', 'advanced-multi-select')
  .advancedSelectMultiOptions<ExampleTagEntity>({
    label: 'Tags',
    query: ADVANCED_SELECT_QUERY_ENUM.EXAMPLE_TAGS_WATCH_QUERY
  });

// 👇 Note: You can use this handy type helper to get the "form value" type from a field contract
export type InstrumentFormValue = InferFormValueFromFieldDefinition<typeof intrumentId>;
export type QuantityFormValue = InferFormValueFromFieldDefinition<typeof quantity>;
export type SideFormValue = InferFormValueFromFieldDefinition<typeof side>;
export type NotesFormValue = InferFormValueFromFieldDefinition<typeof notes>;
